import { BuiltinCategories } from 'constants/BuiltinCategories';
import { RightBarTabs } from 'constants/RightBarTabs';
import Globals from 'Globals';
import { compact, groupBy, isEmpty, mapValues, maxBy, remove, size, sortBy } from 'lodash';
import { flow } from 'mobx';
import Category from "models/Category";
import Task from 'models/Task';
import TasksList from "models/TasksList";
import TreeNode from "models/TreeNode";
import Stores from 'stores/Stores';
import { deleteDrawing } from 'utils/DeleteUtils';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { formulaHasVariables } from 'utils/MeasurementFormatter';
import { onAddOrDeleteImage } from 'utils/ProjectUtils';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import { getSafe } from 'utils/Utils';
import uuidv4 from 'uuid/v4';
;

// TODO: Evaluate if caching from projects.json could be used
export const copyTasksFromListToProject = flow(function* (
  tasksList: TasksList,
  targetNode: TreeNode,
  targetStores: Stores = getSafe(() => targetNode.stores),
  targetCategory?: Category, // unused
  batchParam?: WriteBatch
) {
  const { listStores, defaultStores } = Globals;
  const { userInfoStore, treeNodesStore } = targetStores;
  const { tasksListsStore } = defaultStores;
  const batch = batchParam || firestoreBatch(targetStores);

  listStores.commonStore.selectedProjectId = tasksList.id;

  if (tasksListsStore.isCopyingList) {
    return;
  }

  tasksListsStore.isCopyingList = true;

  // don't know the source but tasks store doesnt load correctly when we just created the tasks list
  listStores.tasksStore.attemptLoadItems();

  yield waitOnStoresReady([
    listStores.treeNodesStore,
    listStores.tasksStore,
    listStores.measurementsStore,
    listStores.providingItemsStore,
    listStores.categoriesStore,
  ]);

  const selectedItems = Globals.listDraftStores.tasksStore.selectedItemsArray;

  const tasksListNodeCopy = listStores.treeNodesStore.rootNode.cloneDeep(
    batch,
    targetStores,
    uuidv4(),
    new Map(),
    isEmpty(selectedItems) ? null : selectedItems.map(task => task.id)
    // should eventually skip non master measurements to avoid overwriting them with bad value in tasks list
  );
  tasksListNodeCopy.isRootNode = false;

  // cleanup measurement values on insert
  [tasksListNodeCopy, ...tasksListNodeCopy.allDescendants].forEach(node => {
    node.ownMeasurementValuesArray.forEach(mv => {
      if (mv.measurement?.shouldSetToZeroWhenImportingTasksList && node === tasksListNodeCopy) {
        // this flag is to avoid LENGTH() and SURFACE() formula conflicting
        // not needed when inserting directly to sub nodes that are separate and won't conflict
        mv.formula = "0";
      } else if (formulaHasVariables(mv.formula) && mv.measurement && !mv.measurement.isOneTimeUse) {
        // always use the latest formula, not the one stored in taskslist
        mv.formula = mv.measurement.defaultFormula;
      }
    })
  });

  // for list root node, copy value to project node only if doesn't already exists
  const measurementValues = tasksListNodeCopy.ownMeasurementValuesArray
    .filter(measurementValue => !targetNode.measurementValues.has(measurementValue.measurementId));

  targetStores.treeNodesStore.applyMeasurementValues(
    targetNode,
    measurementValues,
    true,
    batch
  );

  if (targetCategory) {
    tasksListNodeCopy.tasks.forEach(task => {
      task.category = targetCategory
    });
    targetStores.tasksStore.batchAddEditItems(tasksListNodeCopy.tasks, batch);
  }

  // fix once we allow drawing elements tasks
  const tasksListTasksByCateg = mapValues(groupBy(tasksListNodeCopy.ownTasks, 'categoryId'), tasks => sortBy(tasks, 'index'));
  const targetNodeMaxTasksIndexByCateg = mapValues(groupBy(targetNode.ownTasks, 'categoryId'), tasks => maxBy(tasks, 'index').index);

  Object.keys(tasksListTasksByCateg).forEach(categId => {
    tasksListTasksByCateg[categId].forEach((task, taskIndex) => {
      task.index = (targetNodeMaxTasksIndexByCateg[categId] || 0) + taskIndex + 1;
    })
  })

  targetStores.tasksStore.addEditItems(tasksListNodeCopy.tasks, true, ['index'], batch);

  targetNode.ownTasksIds = sortBy(targetNode.ownTasksIds.concat(tasksListNodeCopy.ownTasksIds), 'index');

  // create drawing node to add sub elements from list to project
  if (!isEmpty(tasksListNodeCopy.childDrawingNode?.children)) {
    if (!targetNode.childDrawingNode) {
      targetNode.childrenIds.push(tasksListNodeCopy.childDrawingNode.id);
    } else {
      // convenience: if target node already has a drawing, move it to first child of tasklist drawing
      const targetChildNodesIdsToMove = targetNode.childDrawingNode.children
        .filter(childNode => isEmpty(childNode.measurementValues) && !isEmpty(childNode.shapes))
        .map(n => n.id);

      // ok to just call remove without actually deleting nodes, because they are relocated
      remove(targetNode.childDrawingNode.childrenIds, nodeId => targetChildNodesIdsToMove.includes(nodeId));

      // also remove existing drawing children of the same name unless they already have shapes drawn
      const nodesToDelete = targetNode.childDrawingNode.children.filter(node => {
        return (
          isEmpty(node.shapes) && (
            isEmpty(node.ownMeasurementValues) || 
            tasksListNodeCopy.childDrawingNode.children.map(c => c.name).includes(node.name)
          )
        );
      });

      nodesToDelete.forEach(nodeToDelete => {
        treeNodesStore.deleteNodeAndDescendants(nodeToDelete, true, batch);
      })

      targetNode.childDrawingNode.childrenIds.push(...tasksListNodeCopy.childDrawingNode.childrenIds);
      targetNode.childDrawingNode.children[0].childrenIds.push(...targetChildNodesIdsToMove);
    }
  }

  // copy image
  if (tasksList.imageUrl && !targetNode.imageUrl) {
    targetNode.imageUrls = [...targetNode.imageUrls, tasksList.imageUrl];
    targetNode.thumbUrls = [...targetNode.thumbUrls, tasksList.thumbUrl || tasksList.imageUrl];

    if (targetNode.imageUrl === tasksList.imageUrl) {
      targetNode.shouldSkipImageInReport = true;
    }

    onAddOrDeleteImage(listStores);
  }

  // copy notes
  if (tasksList.description2 && !targetNode.description2 && userInfoStore.user?.shouldCopyNotesFromTasksLists) {
    targetNode.description2 = tasksList.description2;
  }

  const targetNodes = compact([targetNode, targetNode.childDrawingNode, ...(targetNode.childDrawingNode?.descendants || [])]);
  targetStores.treeNodesStore.batchAddEditItems(targetNodes, batch);

  // remove tasksList nodes that were cloned to projects store and no longer needed
  targetStores.treeNodesStore.deleteItems(
    compact([
      tasksListNodeCopy.id,
      // we use the list childDrawingNode only if the targetNode needed it, otherwise delete
      targetNode.childDrawingNode?.id !== tasksListNodeCopy.childDrawingNode?.id && tasksListNodeCopy.childDrawingNode?.id
    ]),
    true,
    batch
  );

  if (!batchParam) {
    // optimistic, show before commited to db
    /*yield*/ batch.commit();
    trackInteraction(TrackedInteractions.ApplyTasksLists);
  }

  tasksListsStore.isCopyingList = false;
  defaultStores.dragAndDropStore.dragObject = null;
});



// NEED TO COMBINE WITH SAVING TASKS LISTS DETAILS DIALOG
// because of how we want to save non master items
// stores should be target stores because will do droptable to overwrite existing taskslist if provided as argument
// target tasks list is used when creating lists in batch from a project
export const copyTasksFromProjectToList = flow(function* (
  stores: Stores,
  sourceNode: TreeNode = stores.treeNodesStore.selectedTreeNode,
  tasks: Task[] = stores.tasksStore.selectedItemsArray,
  targetTasksList: TasksList = undefined,
  batchParam?: WriteBatch
) {
  const { tasksStore: projectTasksStore, treeNodesStore: projectTreeNodesStore, tasksListsStore, settingsStore } = stores;
  const { listStores } = Globals;

  tasksListsStore.searchQuery = '';

  const batch = batchParam || firestoreBatch(stores);

  let shouldWaitOnTasksListLoad = !!targetTasksList || !isEmpty(sourceNode.childDrawingNode?.children);
  if (!targetTasksList) {
    targetTasksList = new TasksList(stores)
    targetTasksList.name = i18n.t('New list')
  }

  if (targetTasksList.categoryId === 'General') {
    targetTasksList.category = tasksListsStore.categoryFilter;
  }
  if (targetTasksList.subcategoryId === 'General') {
    targetTasksList.subcategory = tasksListsStore.subcategoryFilter;
  }

  const { commonStore, treeNodesStore, tasksStore, measurementsStore } = listStores;
  commonStore.selectedProjectId = targetTasksList.id;

  if (shouldWaitOnTasksListLoad) {
    yield waitOnStoresReady([treeNodesStore, tasksStore]);
    treeNodesStore.dropTable(batch);
    tasksStore.dropTable(batch);
  }

  // shallow clone first to avoid cloning project subtree
  let nodeCopy = sourceNode.cloneWithNewId();
  nodeCopy.childrenIds = nodeCopy.childDrawingNode ? [nodeCopy.childDrawingNode.id] : [];
  nodeCopy.ownTasks = tasks;
  nodeCopy.ownShapesIds.clear();
  nodeCopy.isRootNode = true;

  // copy image
  if (nodeCopy.imageUrl) {
    targetTasksList.imageUrls = [...nodeCopy.imageUrls];
    targetTasksList.thumbUrls = [...nodeCopy.thumbUrls];
  }

  nodeCopy = nodeCopy.cloneDeep(
    batch,
    listStores,
    undefined,
    undefined,
    undefined,
    //should actually skip master measurements, but function takes care of giving new ids and updating tasks and node references
    //so just ignoring master measurements when copying back instead
    false,
    // skip providingItems to be able to save only non master items
    true
  );

  // copy image
  if (nodeCopy.imageUrl) {
    targetTasksList.imageUrls = [...nodeCopy.imageUrls];
    targetTasksList.thumbUrls = [...nodeCopy.thumbUrls];
  }

  targetTasksList.isMultiCategs = Object.keys(listStores.tasksStore.itemsByCateg).length > 1;
  targetTasksList.index = size(tasksListsStore.itemsByCateg[BuiltinCategories.General]);

  deleteDrawing(nodeCopy.childDrawingNode, treeNodesStore, false, batch);

  const nodesCopies = [nodeCopy, ...nodeCopy.allDescendants];

  tasksListsStore.batchAddEditItem(targetTasksList, batch);
  treeNodesStore.batchAddEditItems(nodesCopies, batch);

  // not usable now because using a separate providing items store currently means reloading the full list of item again (about 3-4 seconds each time)
  /*const nonMasterProvidingItems = uniqBy(compact(tasks.filter(t => !t.providingItem?.cascadeOrders?.has(0)).map(t => t.providingItem)), i => i.id);
  listStores.providingItemsStore.addEditItems(nonMasterProvidingItems, true, null, batch);
  const nonMasterCategories = uniqBy(compact(tasks.filter(t => !t.category?.cascadeOrders?.has(0)).map(t => t.category)), c => c.id);
  listStores.categoriesStore.addEditItems(nonMasterCategories, true, null, batch);*/

  projectTasksStore.isSelectingWithCheckboxes = false;
  projectTasksStore.selectedItems.clear();

  if (!batchParam) {
    yield batch.commit();

    const approxDelay = settingsStore.settings.selectedRightBarTab === RightBarTabs.LISTS ? 0 : 500;
    settingsStore.settings.selectedRightBarTab = RightBarTabs.LISTS;
    tasksListsStore.tasksListBeingEdited = targetTasksList;

    // HACK.. we don't have the ref yet if the tab is about to be changed. It will take a few seconds.
    // Because the autosizer is a child of a display: none component, its height is 0 and children don't render
    // Proper way to fix would be to move autosizer higher up, but complicated if moving out of GroupedList component
    setTimeout(() => tasksListsStore.listComponentRef?.scrollToItem?.(targetTasksList.index), approxDelay);
  }
})