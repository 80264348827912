import { memoize } from 'lodash';
import { computed } from 'mobx';
import TreeNode from 'models/TreeNode';
import BackgroundImagesStore from 'stores/BackgroundImagesStore';
import CategoriesStore from 'stores/CategoriesStore';
import ClientsStore from 'stores/ClientsStore';
import CommonStore from 'stores/CommonStore';
import DialogsStore from 'stores/DialogsStore';
import DrawToolsStore from 'stores/DrawToolsStore';
import MeasurementsStore from 'stores/MeasurementsStore';
import ProvidingItemsStore from 'stores/ProvidingItemsStore';
import ReportsStore from 'stores/ReportsStore';
import ShapesStore from 'stores/ShapesStore';
import SnapStore from 'stores/SnapStore';
import Stores from 'stores/Stores';
import TasksStore from 'stores/TasksStore';
import TreeNodesStore from 'stores/TreeNodesStore';
import UndoStore from 'stores/UndoStore';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { waitOnStoresReady } from 'utils/StoreUtil';

class GlobalsClass {
  _defaultStores: Stores;
  _listStores: Stores;
  _listDraftStores: Stores;
  _externalProjectStores: Stores;
  _externalProjectStores2: Stores;
  _drawingStores: Stores;

  // causes glitch when going in and out of projects, should just create a separate store instead of the memoize thing
  getTemporaryStores = memoize((stores: Stores = this.defaultStores) => {
    const newStores = Object.assign({}, stores);
    newStores.measurementsStore = new MeasurementsStore(newStores, stores.measurementsStore);

    return newStores;
  });

  get defaultStores() {
    if (!this._defaultStores) {
      this._defaultStores = new Stores();
    }

    this._defaultStores.name = 'defaultStores';
    return this._defaultStores;
  }

  get listStores() {
    if (!this._listStores) {
      // use same stores as project
      this._listStores = Object.assign({}, this.defaultStores);

      // except for these stores
      this._listStores.dialogsStore = new DialogsStore(this._listStores);
      this._listStores.commonStore = new CommonStore(this._listStores);

      // With tasks list, data is stored at the user/master level (different than with regular projects)
      this._listStores.tasksStore = new TasksStore(this._listStores);
      this._listStores.treeNodesStore = new TreeNodesStore(this._listStores);

      // Needed for "quantity" type measurements, and for activeMeasurements (but this last part could be changed)
      // This store will not check measurements in current project, only in User and in the tasks list itself 
      // (which is the project collection in that context, not the actual project being edited)
      this._listStores.measurementsStore = new MeasurementsStore(this._listStores);

      // categories for selectedItems and non master categs
      this._listStores.categoriesStore = new CategoriesStore(this._listStores, this.defaultStores.categoriesStore)
      // full category or providing items store cause too much slowdown when copying list to project
      // need to figure out how to prefill store with already loaded data and listen without causing a download
      //this._listStores.categoriesStore = new CategoriesStore(this._listStores);
      //this._listStores.providingItemsStore = new ProvidingItemsStore(this._listStores);

      // notice there is no dedicated ShapesStore, we don't need to copy because it's impossible to modify in TaskList edit view
    }

    this._listStores.name = 'listStores';
    return this._listStores;
  }

  get listDraftStores() {
    if (!this._listDraftStores) {
      // use same stores as list stores
      this._listDraftStores = Object.assign({}, this.listStores);

      // except for these stores
      this._listDraftStores.commonStore = new CommonStore(this._listDraftStores);

      // duplicate
      this._listDraftStores.treeNodesStore = new TreeNodesStore(this._listDraftStores);
      this._listDraftStores.tasksStore = new TasksStore(this._listDraftStores);

      // Needed for "quantity" type measurements, and for activeMeasurements (but this last part could be changed)
      this._listDraftStores.measurementsStore = new MeasurementsStore(this._listDraftStores);
    }

    this._listDraftStores.name = 'listDraftStores';
    return this._listDraftStores;
  }

  get drawingStores() {
    if (!this._drawingStores) {
      // use same stores as project
      this._drawingStores = Object.assign({}, this.defaultStores);

      // except for these stores


      this._drawingStores.dialogsStore = new DialogsStore(this._drawingStores);
      this._drawingStores.commonStore = new CommonStore(this._drawingStores);
      this._drawingStores.shapesStore = new ShapesStore(this._drawingStores);
      this._drawingStores.treeNodesStore = new TreeNodesStore(this._drawingStores);
      this._drawingStores.undoStore = new UndoStore(this._drawingStores);
      // needs to save its own measurement active inactive... however should have a better way to load master measurements
      this._drawingStores.measurementsStore = new MeasurementsStore(this._drawingStores, this.defaultStores.measurementsStore);
      this._drawingStores.categoriesStore = new CategoriesStore(this._drawingStores, this.defaultStores.categoriesStore);
      this._drawingStores.drawToolsStore = new DrawToolsStore(this._drawingStores);
      this._drawingStores.snapStore = new SnapStore(this._drawingStores);
    }

    this._drawingStores.name = 'drawingStores';
    return this._drawingStores;
  }

  get externalProjectStores() {
    if (!this._externalProjectStores) {
      // use same stores as default stores
      this._externalProjectStores = Object.assign({}, this.defaultStores);

      // except for these stores
      this._externalProjectStores.commonStore = new CommonStore(this._externalProjectStores);
      // only because we need project specific categs, but not efficient at all to reload master and user categs
      this._externalProjectStores.categoriesStore = new CategoriesStore(this._externalProjectStores);

      // need to load after categories
      this._externalProjectStores.shapesStore = new ShapesStore(this._externalProjectStores);
      this._externalProjectStores.treeNodesStore = new TreeNodesStore(this._externalProjectStores);
      this._externalProjectStores.tasksStore = new TasksStore(this._externalProjectStores);
      this._externalProjectStores.measurementsStore = new MeasurementsStore(this._externalProjectStores);
      this._externalProjectStores.providingItemsStore = new ProvidingItemsStore(this._externalProjectStores);
      this._externalProjectStores.clientsStore = new ClientsStore(this._externalProjectStores);
      this._externalProjectStores.reportsStore = new ReportsStore(this._externalProjectStores);
      this._externalProjectStores.backgroundImagesStore = new BackgroundImagesStore(this._externalProjectStores);

    }

    this._externalProjectStores.name = 'externalProjectStores';
    return this._externalProjectStores;
  }

  // useful when duplicating projects
  get externalProjectStores2() {
    if (!this._externalProjectStores2) {
      // use same stores as default stores
      this._externalProjectStores2 = Object.assign({}, this.defaultStores);

      // except for these stores

      this._externalProjectStores2.commonStore = new CommonStore(this._externalProjectStores2);

      // only because we need project specific categs, but not efficient at all to reload master and user categs
      this._externalProjectStores2.categoriesStore = new CategoriesStore(this._externalProjectStores2);

      // need to load after categories
      this._externalProjectStores2.shapesStore = new ShapesStore(this._externalProjectStores2);
      this._externalProjectStores2.treeNodesStore = new TreeNodesStore(this._externalProjectStores2);
      this._externalProjectStores2.tasksStore = new TasksStore(this._externalProjectStores2);
      this._externalProjectStores2.measurementsStore = new MeasurementsStore(this._externalProjectStores2);
      this._externalProjectStores2.providingItemsStore = new ProvidingItemsStore(this._externalProjectStores2);
      this._externalProjectStores2.clientsStore = new ClientsStore(this._externalProjectStores2);
      this._externalProjectStores2.reportsStore = new ReportsStore(this._externalProjectStores2);
      this._externalProjectStores2.backgroundImagesStore = new BackgroundImagesStore(this._externalProjectStores2);

    }
    this._externalProjectStores2.name = 'externalProjectStores2';
    return this._externalProjectStores2;
  }

  @computed get projectSelectedTreeNode(): TreeNode {
    return this.defaultStores.treeNodesStore.selectedTreeNode;
  }

  // needs to be in project view
  ___DEBUG_clearNonMasterMeasurements2 = async (confirm = 'no') => {
    if (confirm !== 'yes') {
      return;
    }
    const tasksLists = this.defaultStores.tasksListsStore.items;
    const projects = this.defaultStores.projectsStore.items;
    const projectOrTasksListIds = [
      ...tasksLists.map(item => item.id),
      // doesnt work anymore, would need to know draft ids (ie listDocuments)
      ...tasksLists.map(item => item.id + '_draft'),
      ...projects.map(item => item.id)
    ]
    // using for because i think foreach doesn't work well with await
    for (let i = 0; i < projectOrTasksListIds.length; i++) {
      const id = projectOrTasksListIds[i];
      const batch = firestoreBatch(this.defaultStores);

      this.listStores.commonStore.selectedProjectId = id;
      await waitOnStoresReady([this.listStores.measurementsStore]);

      this.listStores.measurementsStore.deleteItems(
        this.listStores.measurementsStore.allItems
          .filter(measurement => !measurement.isOneTimeUse && measurement.cascadeOrder === 3)
          .map(measurement => measurement.id),
        true,
        batch,
        true
      );

      this.listStores.measurementsStore.emptyTrash(batch, true);

      await batch.commit();
    }
  }


  DEBUG_cleanup() {
    this.defaultStores.providingItemsStore.cachedMasterData = {}
    this.defaultStores.measurementsStore.cachedMasterData = {}

    this.defaultStores.providingItemsStore.itemsMap.clear();
    this.defaultStores.providingItemsStore.userCollectionItemsMap.clear();
    this.defaultStores.merchantsStore.itemsMap.clear();
    this.defaultStores.measurementsStore.itemsMap.clear();
    this.defaultStores.tasksListsStore.itemsMap.clear();
    this.defaultStores.shapesStore.itemsMap.clear();
    this.defaultStores.treeNodesStore.itemsMap.clear();
    this.defaultStores.undoStore.undoDataPoints = [];

    this._listStores = null;
    this._listStores = null;
    this._externalProjectStores = null;
    this._externalProjectStores2 = null;
    this._drawingStores = null;

    this.defaultStores.commonStore._isBigUpdateOngoing = true;

    this.defaultStores.providingItemsStore.searchIndex.invertedIndex = {};
    this.defaultStores.measurementsStore.searchIndex.invertedIndex = {};
    this.defaultStores.tasksListsStore.searchIndex.invertedIndex = {};
    this.defaultStores.tasksStore.searchIndex.invertedIndex = {};
    this.defaultStores.projectsStore.searchIndex.invertedIndex = {};
    this.defaultStores.categoriesStore.searchIndex.invertedIndex = {};

    this.defaultStores.providingItemsStore.searchIndex = null;
    this.defaultStores.measurementsStore.searchIndex = null;
    this.defaultStores.tasksListsStore.searchIndex = null;
    this.defaultStores.tasksStore.searchIndex = null;
    this.defaultStores.projectsStore.searchIndex = null;
    this.defaultStores.categoriesStore.searchIndex = null;
    

    this.defaultStores.dbCacheStore.cache.clear();
  }
};


const Globals = new GlobalsClass();

export default Globals;

window.globals = Globals;

// dev
window.treeNodesStore = () => Globals._defaultStores.treeNodesStore;

