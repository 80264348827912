// Only to prepare the dialog, the actual content should be done in MaterialDetailsForm
import { Checkbox } from '@material-ui/core';
import { IDialogProps } from 'constants/CommonProps';
import { compact, isEmpty } from 'lodash';
import { action } from 'mobx';
import * as React from 'react';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { MopIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';

const styles = require('./CleanupProjectDialog.module.scss');
const colors = require('../../../Colors.scss');

export default class CleanupProjectDialog extends ObserverComponent<IDialogProps> {
  state = {
    shouldDeleteDrawings: false,
    shouldDeletePhotos: false,
    shouldDeleteNotes: false,
    shouldSetAllManualQuantitiesToZero: false,
    shouldCleanupUnusedMeasurements: false,
  }

  onConfirm = action(() => {
    const { treeNodesStore, tasksStore } = this.context;

    const {
      shouldDeleteDrawings,
      shouldDeletePhotos,
      shouldDeleteNotes,
      shouldSetAllManualQuantitiesToZero,
      shouldCleanupUnusedMeasurements,
    } = this.state;


    const batch = firestoreBatch(this.context);
    const projectNodes = [treeNodesStore.rootNode, ...treeNodesStore.rootNode.nonDrawingDescendants];

    if (shouldDeleteDrawings) {
      projectNodes.forEach(node => {
        if (!node.drawingRoot) {
          return;
        }

        const nodesWithoutMeasurements = node.drawingRoot.descendants.filter(d => isEmpty(compact(d.measurementValuesArray)));

        if (isEmpty(nodesWithoutMeasurements)) {
          treeNodesStore.deleteDescendants(node.drawingRoot, true, batch);
        } else {
          nodesWithoutMeasurements.forEach(node => treeNodesStore.deleteNodeAndDescendants(node, true, batch));
        }

        node.drawingRoot.backgroundImage = null;
        treeNodesStore.addEditItem(node.drawingRoot, true, ['backgroundImageId'], batch);
      })
    }

    if (shouldSetAllManualQuantitiesToZero) {
      const { measurementsStore, treeNodesStore } = this.context;
      projectNodes.forEach(node => {
        node.oneTimeUseMeasurementValuesArray.forEach(mv => mv.formula = '0');
        treeNodesStore.addEditItem(node, true, undefined, batch);
      });
    }

    if (shouldCleanupUnusedMeasurements) {
      const { measurementsStore, treeNodesStore } = this.context;
      projectNodes.forEach(node => {
        treeNodesStore.removeUnneededMeasurements(node, undefined, batch);
      });
    }

    return batch.commit();
  })

  _render() {
    const { settingsStore, userInfoStore } = this.context;
    const { open, dialogId } = this.props;
    const {
      shouldDeleteDrawings,
      shouldDeletePhotos,
      shouldDeleteNotes,
      shouldSetAllManualQuantitiesToZero,
      shouldCleanupUnusedMeasurements,
    } = this.state;

    const isDisabled = Object.keys(this.state).every(stateKey => !this.state[stateKey]);

    return (
      <ConfirmDialog
        dialogId={dialogId}
        open={open}
        title={i18n.t('Project Cleanup')}
        onConfirm={this.onConfirm}
        // onClose={this.onClose}
        yesLabel={<div className={styles.actionButton}><MopIcon />&nbsp;{i18n.t('Cleanup')}</div>}
        noLabel={i18n.t('Cancel')}
        isActionButtonDisabled={isDisabled}
        actionButtonColor={colors.red}
        content={
          <div className={styles.root}>
            {/*<div className={styles.header}>{i18n.t('Warning: cleanup')}</div>*/}
            <div className={styles.formRow} onClick={() => { this.setState({ shouldDeleteDrawings: !shouldDeleteDrawings }); }}>
              <Checkbox checked={shouldDeleteDrawings} />
              <span>{i18n.t('Delete reference drawings')}</span>
            </div>

            <div className={styles.formRow} onClick={() => { this.setState({ shouldSetAllManualQuantitiesToZero: !shouldSetAllManualQuantitiesToZero }); }}>
              <Checkbox checked={shouldSetAllManualQuantitiesToZero} />
              <span>{i18n.t('Set all manual quantities to Zero (including manually entered Prices).')}</span>
            </div>

            <div className={styles.formRow} onClick={() => { this.setState({ shouldCleanupUnusedMeasurements: !shouldCleanupUnusedMeasurements }); }}>
              <Checkbox checked={shouldCleanupUnusedMeasurements} />
              <span>{i18n.t('Remove unneeded measurements')}</span>
            </div>
          </div>
        }
      />
    );
  }
}