

import { DRAWING_SCALE } from 'constants/Constants';
import { computed, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { list, object, serializable } from 'serializr';
import { getPathString, getPathStringReversed, getSurfaceSquarePixels, getSurfaceSquarePixelsWithSlope } from 'utils/ShapeUtil';
import Point from './Point';

// extending other than modelbase doesn't seem to work in serializr
export default class SimpleSurface /*extends SimpleShape */ {
  @observable @serializable(list(object(Point))) points: Point[] = [];

  getSurfaceWithSlope = computedFn((slopeAngle: number, drawingProjection: number) => {
    return getSurfaceSquarePixelsWithSlope(this.points, slopeAngle, drawingProjection) / Math.pow(DRAWING_SCALE, 2);
  })

  @computed get surfaceSquarePixels(): number {
    return getSurfaceSquarePixels(this.points);
  }

  @computed get surface(): number {
    return this.surfaceSquarePixels / Math.pow(DRAWING_SCALE, 2)
  }

  @computed get perimeter(): number {
    if (this.points.length <2) {
      return 0;
    }

    let result = 0;
    for (let i=1; i<this.points.length; i++) {
      result+= this.points[i].distance(this.points[i-1], DRAWING_SCALE);
    }

    return result;
  }

  @computed
  get pathString(): string {
    return getPathString(this.points);
  }

  @computed
  get pathStringReversed(): string {
    return getPathStringReversed(this.points);
  }
}