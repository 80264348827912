import Clipper from '@doodle3d/clipper-js';
import { DrawingProjections } from 'constants/DrawingProjections';
import { first, isEmpty, uniq } from 'lodash';
import Point from 'models/Point';
import { Rectangle } from 'models/Rectangle';
import { getDrawingProjectionValue } from './MeasurementUtil';

export function getPathString(points: Point[]) {
  points = points.filter(point => point.x !== undefined && point.y !== undefined);

  if (isEmpty(points)) {
    return '';
  }

  return 'M' + points.join('L') + 'Z';
}

export function getPathStringReversed(points: Point[]) {
  points = points.filter(point => point.x !== undefined && point.y !== undefined);

  if (isEmpty(points)) {
    return '';
  }

  return 'M' + points.slice(0).reverse().join('L') + 'Z';
}


export function getSurfaceSquarePixels(points: Point[]) {
  let surface = 0;         // Accumulates area in the loop
  let j = points.length - 1;  // The last vertex is the 'previous' one to the first

  for (let i = 0; i < points.length; i++) {
    surface = surface + (points[j].x + points[i].x) * (points[j].y - points[i].y);
    j = i;  //j is previous vertex to i
  }
  return Math.abs(surface / 2);
}

export function getSurfaceSquarePixelsWithSlope(points: Point[], slopeRatio: number, drawingProjection: number) {
  let slopeAngle = Math.atan(slopeRatio);

  // turns out slopedirection doesn't matter for surfaces, should simplify the algorithm
  const slopeDirection = 0;

  if (
    drawingProjection !== getDrawingProjectionValue(DrawingProjections.Plan) &&
    slopeAngle // avoids infinite lengths
  ) {
    slopeAngle = (Math.PI / 2) - slopeAngle;
  }

  const pointsWithSlope = [first(points)];

  for (let i = 1; i < points.length; i++) {
    // https://matthew-brett.github.io/teaching/rotation_2d.html
    // change coordinates to match slope direction
    // x2=cosβx1−sinβy1   y2=sinβx1+cosβy1
    const endPt = points[i];
    const startPt = points[i - 1];

    const x1 = endPt.x - startPt.x;
    const y1 = endPt.y - startPt.y;

    // apply slope THEN ROTATE BACK
    const x2 = Math.cos(slopeDirection) * x1 - Math.sin(slopeDirection) * y1;
    const y2 = Math.sin(slopeDirection) * x1 + Math.cos(slopeDirection) * y1;

    const x2WithSlope = x2 / Math.cos(slopeAngle);
    const y2WithSlope = y2;

    const x3 = Math.cos(-slopeDirection) * x2WithSlope - Math.sin(-slopeDirection) * y2WithSlope;
    const y3 = Math.sin(-slopeDirection) * x2WithSlope + Math.cos(-slopeDirection) * y2WithSlope;

    pointsWithSlope.push(new Point(
      pointsWithSlope[i - 1].x + x3,
      pointsWithSlope[i - 1].y + y3
    ));
  }

  return getSurfaceSquarePixels(pointsWithSlope);
}

export function pointInPolygon(point:Point, polygonPoints: Point[]) {
  let odd = false;
  for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; i++) {
      if (((polygonPoints[i].y > point.y) !== (polygonPoints[j][1] > point.y)) 
          && (point.x < ((polygonPoints[j][0] - polygonPoints[i].x) * (point.y - polygonPoints[i].y) / (polygonPoints[j][1] - polygonPoints[i].y) + polygonPoints[i].x))) {
          odd = !odd;
      }
      j = i;
  }
  return odd;
};

export function getBoundingBox(points: Point[], canBeOpening = true): Rectangle {
  const surfaceClipper = new Clipper([points.map(point => ({ X: point.x, Y: point.y }))]);

  const boundingBox = surfaceClipper.shapeBounds();

  const rectangle = new Rectangle(undefined, new Point(boundingBox.left, boundingBox.top), new Point(boundingBox.right, boundingBox.bottom));

  // special hack for windows, if drawn from plan view, will always return the value in width,
  // never in height (which is only visible in elevantion view)à
  if (canBeOpening && (Math.abs(rectangle.width) <= 0.00000001 || Math.abs(rectangle.height) <= 0.00000001)) {
    return new Rectangle(undefined, new Point(0,0), new Point(Math.max(rectangle.width, rectangle.height), 0));
  }

  return rectangle;
}

export function isRectangle(points: Point[]): boolean {
  // not a real algorithm
  return points.length === 4 && uniq(points.map(pt => [pt.x, pt.y]).flat()).length <= 4;
}


export function getScaleToFit(
  maxScale = 9999,
  svgBBox,
  containerWidth,
  containerHeight,
) {
  if (!svgBBox) {
    return;
  }

  return Math.min(
    maxScale,
    Math.min(
      containerWidth / ((svgBBox.width || 800) + 100), // not sure about the unscaled +100
      containerHeight / ((svgBBox.height || 600) + 100)
    )
  );
}

export function getViewBoxFromParentSvg(svgElement: SVGSVGElement) {
  return svgElement?.ownerSVGElement?.viewBox?.baseVal || new DOMRect();
}