import Globals from 'Globals';
import { Tree } from 'antd';
import classnames from 'classnames';
import { DrawToolType } from 'constants/DrawToolType';
import { defer, isEmpty, remove, throttle } from 'lodash';
import { action, reaction } from 'mobx';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { renderNode } from 'utils/TreeUtil';
import { getSafe } from 'utils/Utils';
import { TriangleDownIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';
import './WorksheetsTree.scss';

const styles = require('./WorksheetsTree.module.scss');

interface WorksheetsTreeProps {
  readonly?: boolean
}

interface WorksheetsTreeState {
  isDragging: boolean,
}

export default class WorksheetsTree extends ObserverComponent<WorksheetsTreeProps, WorksheetsTreeState> {
  dragClue;
  dragOverCnt = 0;
  isDragDrop = false;
  SEPARATOR = '_';

  treeRef;

  //state = { tree, expand: { ids: [], idField: 'text' }, select: { ids: [], idField: 'text' } };

  state = {
    isDragging: false,
  }

  allowDrop = ({ dropNode, dropPosition }) => {
    const dropAsChild = dropPosition === 0;

    const retval = (!dropAsChild || isEmpty(dropNode.ownShapes)) && (dropAsChild || !dropNode.isRootNode);
    return retval;
  }

  onDragEnter = (event) => {

    return;

    this.dragOverCnt++;

    const domNode = ReactDOM.findDOMNode(this);
    const boundingRect = domNode.getBoundingClientRect();
    this.dragClue.show(event.pageY - boundingRect.top - domNode.parentNode.scrollTop + 10, event.pageX, event.item.text, this.getClueClassName(event));
  }

  onItemDragEnd = ({ node, dragNodesKeys, dropToGap, dropPosition }) => {
    this.setState({ isDragging: false })

    const { treeNodesStore } = this.context;
    const dropNode = treeNodesStore.getItem(node.id);
    const dragNode = treeNodesStore.getItem(dragNodesKeys[0]);

    const dropAsChild = !dropToGap;

    const dragParent = dragNode.parent;
    const dropParent = dropAsChild ? dropNode : dropNode.parent;

    if (dropAsChild) {
      dropParent.isExpanded = true;
    }

    remove(dragParent.childrenIds, nodeId => nodeId === dragNode.id);

    const dropNodeIndex = dropAsChild ? 0 : dropParent.childrenIds.findIndex(nodeId => nodeId === dropNode.id);
    dropParent.childrenIds.splice(dropNodeIndex + (dropAsChild ? 0 : 1), 0, dragNode.id);

    treeNodesStore.addEditItems([dragParent, dropParent], true, ['childrenIds']);
  }


  @action
  onClick = (event, node: TreeNode) => {
    const { drawToolsStore, treeNodesStore, tasksStore, commonStore } = this.context;

    if (commonStore.isBigUpdateOngoing) {
      commonStore.isBigUpdateOngoing = false;
    }

    // todo prevent tree from creating an object copy
    node = treeNodesStore.getItem(node.id);

    const isDrawingTree = this.context === Globals.drawingStores;

    if (event.nativeEvent.key !== 'Enter' && !getSafe(() => event.nativeEvent.target.className.includes('WorksheetsTreeNode'))) {
      return;
    }

    if (drawToolsStore.selectedTool !== DrawToolType.Select) {
      drawToolsStore.reset(!!drawToolsStore.lineBeingAdded, false);
    };

    if (
      false // multiselect disabled for now
      // &&event.metaKey || event.shiftKey || event.ctrlKey
    ) {
      if (node.isSelected) {
        treeNodesStore.selectedTreeNodes = treeNodesStore.selectedTreeNodes.filter(selectedNode => selectedNode.id !== node.id);
      } else {
        treeNodesStore.selectedTreeNodes = treeNodesStore.selectedTreeNodes.concat(node);
      }
    } else if (node.isSelected && !node.readonly) {
      treeNodesStore.treeNodeBeingRenamed = node;
    } else {
      node.isSelected = true; // maybe isSelected2 too
      treeNodesStore.treeNodeBeingRenamed = null;
      treeNodesStore.selectedTreeNodes = [node];
      if (
        node.hasNonDrawingRootChildren &&
        (!isDrawingTree || node.children.some(child => !isEmpty(child.measurementValuesArray)))
      ) {
        node.isExpanded = true;

        if (this.treeRef && node.children[0]) {
          setTimeout(() => {
            try {
              const domTree = ReactDOM.findDOMNode(this.treeRef);
              domTree.querySelector('#node_' + node.children[0].id)?.scrollIntoViewIfNeeded?.();
            } catch (e) { }
          }, 200)
        }
      }
      tasksStore.selectedItems.clear();
      tasksStore.isSelectingWithCheckboxes = false;
    }
  }

  onExpand = (unused, { node: { id }, expanded }) => {
    const { treeNodesStore } = this.context;
    const nodeToChange = treeNodesStore.getItem(id);

    nodeToChange.isExpanded = expanded;

    if (expanded && this.treeRef && nodeToChange.children[0]) {
      defer(() => {
        try {
          const domTree = ReactDOM.findDOMNode(this.treeRef);
          domTree.querySelector('#node_' + nodeToChange.children[0].id)?.scrollIntoViewIfNeeded?.();
        } catch (e) { }
      })
    }
  }

  // big bandaid for ipad click triggering twice for no reason
  onExpandThrottled = throttle(this.onExpand, 0, { trailing: false });
  touchDeviceReactionUnsubscribe = reaction(() => Globals.defaultStores.settingsStore.isTouchDevice, (value) => {
    if (value) {
      this.onExpandThrottled = throttle(this.onExpand, 500, { trailing: false });
    }
  })

  onDragStart = ({ event }) => {
    const { treeNodesStore } = this.context;
    if (treeNodesStore.treeNodeBeingRenamed) {
      event.preventDefault();
      return;
    }

    this.setState({ isDragging: true });
    try {
      event.dataTransfer.setDragImage(event.currentTarget.querySelector('.nodeText'), 0, 0)
    } catch (e) { }
  }

  onDragEnd = ({ event }) => {
    this.setState({ isDragging: false });
  }

  scrollTo = throttle((target) => {
    if (!this.treeRef) {
      return;
    }

    const domTree = ReactDOM.findDOMNode(this.treeRef) as Element;
    const { bottom: currentBottom, top: currentTop } = target.getBoundingClientRect();
    const { bottom: boxBottom, top: boxTop } = domTree
      .getElementsByClassName('ant-tree-list-holder')[0]
      .getBoundingClientRect();

    if (currentTop > boxBottom - 48) {
      domTree.getElementsByClassName('ant-tree-list-holder')[0].scrollTo(0,
        domTree.getElementsByClassName('ant-tree-list-holder')[0].scrollTop + 10
      );
    }

    if (boxTop + 48 > currentBottom) {
      domTree.getElementsByClassName('ant-tree-list-holder')[0].scrollTo(0,
        domTree.getElementsByClassName('ant-tree-list-holder')[0].scrollTop - 10
      );
    }
  }, 25)

  onDragOver = (info) => {
    if (info.event.target) {
      this.scrollTo(info.event.target);
    }
  };

  componentWillUnmount(): void {
    this.touchDeviceReactionUnsubscribe?.();
  }

  _render() {
    const { readonly } = this.props;
    const { isDragging } = this.state;
    const { treeNodesStore, settingsStore } = this.context;
    const rootNode = treeNodesStore.rootNode;

    // need to listen to all nodes
    // causes unnecessary renders
    const allNodesIds = treeNodesStore.allNodes.map(node => [node.id, node.isExpanded, node.isExpanded2]);
    //const { selectedTreeNodeIds } = treeNodesStore;

    const isDrawingTree = this.context == Globals.drawingStores;

    return rootNode && (
      <div className={styles.root}>
        <Tree
          tabIndex={0}
          ref={ref => {
            this.treeRef = ref;
            treeNodesStore.treeComponentRef = ref;
          }}
          className={classnames('draggable-tree', {
            [styles.isDrawingTree]: isDrawingTree,
            isDragging,
          })}
          draggable
          showLine
          motion={settingsStore.isTouchDevice ? 0 : undefined}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragOver}
          onDrop={this.onItemDragEnd}
          allowDrop={this.allowDrop}
          onClick={this.onClick}
          treeData={[rootNode]}
          fieldNames={{ title: 'name', key: 'id', children: 'items' }}
          titleRender={renderNode(false)}
          switcherIcon={<TriangleDownIcon />}
          selectedKeys={[]}
          expandedKeys={treeNodesStore.allVisibleNodes.filter(n => n.hasNonDrawingRootChildren && n.isExpanded).map(n => n.id)}
          onExpand={this.onExpandThrottled}
          //height={height}
          // should activate, but treenode avatar should be image (possibly svg) not super complex shape preview
          // also should delay render buttons to have smooth scroll
          // when implementing, make sure to check Windows scrollbars not hiding contents at different browser zoom levels
          virtual={false}
        />
      </div>
    );
  }
}
