import { DbLocationType } from 'constants/DbLocationType';
import { groupBy } from 'lodash';
import { computed, observable } from 'mobx';
import BackgroundImage from 'models/BackgroundImage';
import { modelSortFunction } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';

export default class BackgroundImagesStore extends SearchableFirebaseStore<BackgroundImage>   {
  storeKey = 'backgroundImages';
  
  dbLocationsTypes = new Set([
    DbLocationType.MasterProject,
    DbLocationType.Project
  ]);

  hasCategories = false;

  fieldsToIndex = ['parentDocumentName'];
  searchDelay = 100;

  @observable titleGenerationErrorMiliseconds = 0;

  @computed get itemsByDocumentName() {
    return groupBy(this.searchedItems.sort(modelSortFunction), 'parentDocumentName');
  }
}
