import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CopyIcon from '@material-ui/icons/FileCopy';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import ReloadIcon from '@material-ui/icons/Undo';
import ObserverComponent from 'components/common/ObserverComponent';
import { MeasurementType } from 'constants/MeasurementType';
import { compact, values } from 'lodash';
import Adjustment from 'models/Adjustment';
import Measurement from 'models/Measurement';
import Task from 'models/Task';
import React from 'react';
import { duplicateMeasurement, showMeasurementDialog } from 'utils/MeasurementUtil';
import i18n from 'utils/i18n';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';

const styles = require('./MeasurementPopupMenu.module.scss');

interface MeasurementPopupMenuProps {
  // generic variable name item, because same interface could be used for another type of popup menu
  item: Measurement,
  onFactoryReset?: () => void,
  onUserItemReset?: () => void,
  className?: string,
  dialogId?: string,
  task?: Task,
}

export default class MeasurementPopupMenu extends ObserverComponent<MeasurementPopupMenuProps> {
  removeMeasurementFromNode = (measurement: Measurement, dialogId = '') => () => {
    const { treeNodesStore, dialogsStore } = measurement.stores;
    treeNodesStore.toggleNodeMeasurements([measurement], false);

    if (dialogId) {
      dialogsStore.hideDialog(dialogId);
    }
  }
  removeMeasurementFromTask = (task: Task, dialogId = '') => () => {
    const { tasksStore, dialogsStore } = this.context;
    task.adjustment = new Adjustment(this.context);
    task.measurement = null;

    tasksStore.addEditItem(task, true, ['measurementId', 'adjustment']);

    if (dialogId) {
      dialogsStore.hideDialog(dialogId);
    }
  }

  onConfirmDelete = (measurement: Measurement) => () => {
    const { dialogsStore, measurementsStore } = this.context;
    const { dialogId } = this.props;

    measurementsStore.deleteItem(measurement.id);
    dialogsStore.hideDialog(dialogId);
  }

  _render() {
    const { item: measurement, className, dialogId, onFactoryReset, onUserItemReset, task } = this.props;
    const { treeNodesStore, measurementsStore, tasksStore } = this.context;
    const { selectedTreeNode } = treeNodesStore;

    const menuItems = compact([
      onFactoryReset && { icon: <ReloadIcon />, text: i18n.t('Reset to factory settings'), handler: onFactoryReset },
      onUserItemReset && { icon: <ReloadIcon />, text: i18n.t('Reset to latest change'), handler: onUserItemReset },
      {
        icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: () => {
          const measurementCopy = duplicateMeasurement(measurement, this.context, dialogId)()?.[0];
          if (task) {
            task.measurement = measurementCopy;
            tasksStore.addEditItem(task, true, ['measurementId']);
          }

          showMeasurementDialog(measurementCopy, selectedTreeNode, task);
        }
      },
      measurementsStore.getItem(measurement.id) && (
        { icon: <RemoveIcon />, text: i18n.t('Remove from "{{-nodeName}}"', { nodeName: selectedTreeNode.name }), handler: this.removeMeasurementFromNode(measurement, dialogId) }
      ),
      task && (
        { icon: <RemoveIcon />, text: i18n.t('Remove from task', { nodeName: selectedTreeNode.name }), handler: this.removeMeasurementFromTask(task, dialogId) }
      ),
      (
        !values(MeasurementType).includes(measurement.id) &&
        measurementsStore.getItem(measurement.id) &&
        (!measurement.isReadonly || measurement.isDeletedInUserAccount)
      ) && (
        { icon: <DeleteForeverIcon />, text: i18n.t('Delete forever'), handler: this.onConfirmDelete(measurement), danger: true }
      ),
    ]);

    return <MenuPopupButton className={className} menuItems={menuItems} />
  }
}