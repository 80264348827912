
import { FilledInput, FormControl, IconButton, InputLabel, ListItemAvatar, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { BuiltinCategories, BuiltinMeasurementCategories, BuiltinTaskCategories } from 'constants/BuiltinCategories';
import { CategoryType } from 'constants/CategoryType';
import { IEditComponentProps } from 'constants/CommonProps';
import { compact, deburr, find, isEmpty, remove, sortBy, uniqBy } from 'lodash';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import * as React from 'react';
import { DragDropContext, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import AnimateReorder from 'react-flip-move';
import { DraggableTypes } from 'stores/DragAndDropStore';
import { getNonEmptyCategoriesForCategoryTypes, getStoreForCategoryType, showCategoryEditDialog } from 'utils/CategoryUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import CategoryMoveDialog from '../CategoryMoveDialog/CategoryMoveDialog';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Editable from '../Editable/Editable';
import OrderableItem from '../OrderableItem/OrderableItem';
import OrderableItemsContainer from '../OrderableItemsContainer/OrderableItemsContainer';
import SearchToolbar from '../SearchToolbar/SearchToolbar';

const styles = require('./CategoriesListEditDetailsForm.module.scss');
const colors = require('../../../Colors.scss');

interface CategoriesListEditDetailsFormProps {
  initialCategoryType: CategoryType,
  initialParentCategory?: Category,
}

interface CategoriesListEditDetailsFormState {
  categoryType: CategoryType,
  parentCategory: Category,
}

export default class CategoriesListEditDetailsForm extends ObserverComponent<IEditComponentProps & CategoriesListEditDetailsFormProps, CategoriesListEditDetailsFormState> {
  state = {
    // undefined means not set yet
    // null means all category types or all parent categories
    // a bit confusing because normally null means General category
    categoryType: undefined,
    parentCategory: undefined,
  }

  scrollContainer;

  get parentCategory(): Category {
    const { initialParentCategory } = this.props;
    const { parentCategory } = this.state;
    return parentCategory === undefined
      ? initialParentCategory
      : parentCategory;
  }

  get categories() {
    const { categoriesStore } = this.context;
    const { initialParentCategory, models } = this.props;

    const categories = (models.slice(1) as Category[])
      // hacked search
      .filter(category => (!categoriesStore.debouncedSearchQuery || deburr(category.name.toLowerCase()).includes(deburr(categoriesStore.debouncedSearchQuery.toLowerCase()))))

    let allCategories: Category[] = [];
    // THIS DOESNT WORK because we have to sort by index to allow drag reorder
    let nonEmptyCategories: Category[] = [];

    if (initialParentCategory) {
      // subcategories view
      allCategories = this.parentCategory
        ? this.parentCategory.children
        : categories.filter(category => category.parentCategory);

      allCategories = allCategories.filter(subcateg => (
        !this.categoryType ||
        subcateg.categoryTypes.includes(this.categoryType)
      ));

      // we don't have info on categoryType to be able to tell which subcategory is empty
      // but not needed right now, because will be contained in allCategories
      nonEmptyCategories = [];
    } else {
      // categories view
      allCategories = this.categoryType
        ? categoriesStore.getRootCategoriesByType(this.categoryType)
        : categoriesStore.rootCategories;

      nonEmptyCategories = this.categoryType
        ? getNonEmptyCategoriesForCategoryTypes([this.categoryType], this.context)
        : []; // already included in all categories when no categorytype specified
    }

    return sortBy(
      uniqBy([
        ...nonEmptyCategories,
        ...allCategories,
      ].filter(category => (
        category.id !== BuiltinTaskCategories.NewCategory &&
        category.id !== '4362ff96-6015-4f28-898e-cc1bc41fbd80' && // mesures sur dessin... still in use?
        category.id !== '2577b9f9-a311-4af9-8704-c762915d4bb3' &&
        // not using the real search index
        (!categoriesStore.debouncedSearchQuery || deburr(category.name.toLowerCase()).includes(deburr(categoriesStore.debouncedSearchQuery.toLowerCase())))
      )),
        'id'
      ),
      'index');
  }

  get categoryType() {
    const { initialCategoryType } = this.props;
    const { categoryType } = this.state;

    return categoryType === undefined
      ? initialCategoryType
      : categoryType;
  }

  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source, draggableId } = result;
    const { categoriesStore } = this.context;
    const category = find(this.categories, category => category.id === draggableId);

    if (!destination || !category || result.type !== DraggableTypes.Categories) {
      return;
    }

    const previousItem = getSafe(() => this.categories[
      destination.index < source.index
        ? destination.index - 1
        : destination.index
    ]);
    if (this.parentCategory) {
      const { childrenIds } = this.parentCategory;
      remove(childrenIds, id => id === category.id || id === BuiltinCategories.General);
      childrenIds.splice(getSafe(() => childrenIds.indexOf(previousItem.id) + 1) || 0, 0, category.id);

      categoriesStore.addEditItem(this.parentCategory, true, ['childrenIds']);
    }

    categoriesStore.reorderItem(
      category,
      previousItem,
      this.categories,
    );
  }

  onConfirmDeleteCategory = (category: Category, isRealDelete: boolean) => {
    const { parentCategory } = category;
    const { categoriesStore, userInfoStore } = this.context;
    // we delete by type first, then if there are no type left, delete completely from db
    if (isRealDelete) {
      if (parentCategory) {
        remove(parentCategory.children, subcategory => subcategory.id === category.id)
        categoriesStore.addEditItem(parentCategory);
      }
      categoriesStore.deleteItems([category.id, ...category.childrenIds]);
    } else {
      remove(category.categoryTypes, categoryType => categoryType === this.categoryType);
      categoriesStore.addEditItem(category);
    }
  }

  askToDeleteCategory = (category: Category, isRealDelete: boolean) => {
    const { dialogsStore } = this.context;

    if (!category) {
      return;
    }
    const newDialog = new Dialog(this.context);

    const relatedItems = this.getRelatedItems(category);

    if (isEmpty(relatedItems)) {
      // delete categ is ok because not used anywhere
      newDialog.dialogComponent = ({ open }) => (
        <ConfirmDialog
          open={open}
          dialogId={newDialog.id}
          onConfirm={() => this.onConfirmDeleteCategory(category, isRealDelete)}
          title={i18n.t('Do you really want to delete category "{{- categoryName}}"?', { categoryName: i18n.t(category.name) })}
          actionButtonColor={colors.red}
        />
      );
    } else {
      newDialog.dialogComponent = ({ open }) => (
        <CategoryMoveDialog
          title={i18n.t('Where should {{itemsCount}} items currently in "{{- categoryName}}" be moved to?', { itemsCount: relatedItems.length, categoryName: i18n.t(category.name) })}
          open={open}
          dialogId={newDialog.id}
          models={relatedItems}
          onConfirm={() => this.onConfirmDeleteCategory(category, isRealDelete)}
          yesLabel={i18n.t('Move items and delete "{{- categoryName}}"', { categoryName: i18n.t(category.name) })}
          actionButtonColor={colors.red}
        />
      )
    }

    dialogsStore.showDialog(newDialog);
  }

  getRelatedItems(category: Category, checkAllStores = false) {
    const { providingItemsStore, measurementsStore, tasksStore, tasksListsStore } = this.context;
    const stores = checkAllStores
      ? [providingItemsStore, measurementsStore, tasksStore, tasksListsStore]
      : compact([getStoreForCategoryType(this.categoryType, this.context)]);

    return stores.map(
      store => store.items
        .filter(item => (item.subcategoryId === category.id || item.categoryId === category.id))
    ).flat();
  }

  _render() {
    const { categoriesStore, userInfoStore } = this.context;
    const { initialParentCategory } = this.props;
    const { parentCategory } = this.state;

    const isMasterUser = userInfoStore.userEmail === 'master';

    const canReorder = !!this.categoryType || !!categoriesStore.debouncedSearchQuery;

    // when reordering root categories, we show all categs not just the ones
    // for the current categoryType.
    return (
      <div className={styles.root}>
        {initialParentCategory && (
          <CategoriesCombobox
            allowShowEveryCategory
            category={this.parentCategory}
            onChange={(category: Category) => {
              this.setState({ parentCategory: category || null })
            }}
          />
        )}
        <FormControl className={styles.categorySelect} margin="normal">
          <InputLabel>{i18n.t('Category Type')}</InputLabel>
          <Select
            value={this.categoryType || 'All'}
            onChange={({ target: { value } }) => { this.setState({ categoryType: value === 'All' ? null : value as CategoryType }); }}
            input={<FilledInput />}
          >
            <MenuItem value={'All'}>{i18n.t('All category types')}</MenuItem>
            {Object.values(CategoryType).map(categoryType => (
              <MenuItem key={categoryType} value={categoryType}>{i18n.t(categoryType)}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <SearchToolbar
          store={categoriesStore}
          onSearchChange={() => {
            if (this.scrollContainer && this.scrollContainer.scrollTop !== 0) {
              this.scrollContainer.scrollTop = 0;
            }
          }}
        />

        <div ref={ref => this.scrollContainer = ref} className={styles.scrollContainer}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <OrderableItemsContainer
              className={styles.orderableContainer}
              id={DraggableTypes.Categories}
              type={DraggableTypes.Categories}
              isDropDisabled={canReorder}
            >
              <AnimateReorder disableAllAnimations={!canReorder} typeName={null} enterAnimation="none" leaveAnimation="none" appearAnimation="none">
                {compact([
                  ...this.categories
                    .map((category, index) => {
                      const categoryName = category.name;

                      const isRealDelete = (
                        userInfoStore.userEmail === 'master' ||
                        // regular user can only delete non builtin category
                        !category.cascadeOrders.has(0)
                      ) && (
                          !this.categoryType ||
                          category.categoryTypes.length === 1
                        );

                      const allRelatedItems = this.getRelatedItems(category, true);

                      return (
                        <OrderableItem
                          className={classnames(styles.category, {
                            //  [styles.faded]: !this.parentCategory && !category.categoryTypes.includes(categoryType)
                          })}
                          id={category.id}
                          key={category.id}
                          index={index}
                        >{
                            (dragHandle: JSX.Element) => (<>
                              <span style={canReorder ? { display: 'none' } : {}}>{dragHandle}</span>
                              <ListItemAvatar>
                                <AvatarImageWithFallback model={category} />
                              </ListItemAvatar>
                              <Editable
                                isEditable={category.id !== BuiltinCategories.General}
                                editLabel={i18n.t('{{categoryOrSubcategory}} name', { categoryOrSubcategory: (category.parentCategory ? i18n.t('subcategory') : i18n.t('category')) })}
                                model={category}
                                propertyName="name"
                                store={categoriesStore}
                                className={styles.categoryName}
                                classNameEditMode={styles.categoryNameEdit}
                                InputClassName={styles.categoryNameInput}
                                onSave={newModel => category.name = newModel.name}
                                onDetailsClick={() => showCategoryEditDialog(category, this.context)}
                                displayComponent={
                                  <>
                                    <div className={isEmpty(allRelatedItems) ? '' : styles.hasItems}>{categoryName}</div>
                                    {category.id !== BuiltinMeasurementCategories.ReferenceMeasurements && (
                                      <IconButton
                                        className={styles.deleteButton}
                                        onClick={(event) => {
                                          this.askToDeleteCategory(category, isRealDelete);
                                          event.preventDefault();
                                        }}
                                      >
                                        {isRealDelete ? <DeleteForeverIcon /> : <DeleteIcon />}
                                      </IconButton>
                                    )}
                                  </>
                                }
                              />
                              {/*<div>{category.index}</div>*/}
                            </>)
                          }
                        </OrderableItem>
                      );
                    }),
                ])}
              </AnimateReorder>
            </OrderableItemsContainer>
          </DragDropContext>
        </div>
      </div >
    )
  }
}