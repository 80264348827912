import { AccordionDetails, ExpansionPanel, ExpansionPanelSummary, FilledInput, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import PriceIcon from '@material-ui/icons/AttachMoney';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import CopyIcon from '@material-ui/icons/FileCopy';
import ArrowLeftIcon from '@material-ui/icons/KeyboardBackspace';
import IdIcon from '@material-ui/icons/Label';
import UrlIcon from '@material-ui/icons/Language';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import OpenLinkIcon from '@material-ui/icons/OpenInNew';
import ReloadIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import OptionIcon from '@material-ui/icons/Settings';
import classnames from 'classnames';
import MaterialDetailsQuantityTable from 'components/common/MaterialDetailsQuantityTable/MaterialDetailsQuantityTable';
import { CategoryType } from 'constants/CategoryType';
import { IEditComponentProps } from 'constants/CommonProps';
import { ProvidingItemQuantityBehaviour, ProvidingItemSubtype, ProvidingItemTaskBehaviour } from 'constants/ProvidingItemConstants';
import { RoundingMethod } from 'constants/RoundingMethod';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { compact, debounce, isEmpty, set as lset, memoize, range } from 'lodash';
import { computed } from 'mobx';
import Category from 'models/Category';
import Dialog from 'models/Dialog';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import * as React from 'react';
import { roundPrice } from 'utils/CurrencyUtil';
import { InputNumberFormatBase, InputNumberFormatCurrency, formatCurrency } from 'utils/NumberFormatter';
import { formatUnit } from 'utils/UnitFormatter';
import { getSafe, round } from 'utils/Utils';
import i18n from 'utils/i18n';
import * as uuidv4 from 'uuid/v4';
import CategoriesCombobox from '../CategoriesCombobox/CategoriesCombobox';
import { Count123Icon, HelmetIcon, WoodStackIcon } from '../Icons';
import ImageBox from '../ImageBox/ImageBox';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import MenuPopupButton from '../MenuPopupButton/MenuPopupButton';
import ObserverComponent from '../ObserverComponent';
import ProvidingItemEditDialog from '../ProvidingItemEditDialog/ProvidingItemEditDialog';
import TextField from '../TextField/TextField';

const styles = require('./ProvidingItemDetailsForm.module.scss');

enum TextFields {
  None = '',
  PriceFormula = 'priceFormula',
  Price = 'price',
  Rate = 'labourRate',
  Duration = 'labourDuration'
};

interface IProvidingItemDetailsState {
  isFirstFieldLocked: boolean,
  focusedField: TextFields,
  isReloading: boolean,
  isAdvancedOptionsExpanded: boolean,
}

interface IProvidingItemDetailsFormProps {
  parentTask: Task,
  onFactoryReset: () => void,
  onUserItemReset?: () => void,
  onUserItemPush?: () => void,
}

const getRateInputFormatter = memoize(
  unit => InputNumberFormatBase('$/' + formatUnit(unit), 2),
  unit => unit
);

const getDurationInputFormatter = memoize(
  unit => InputNumberFormatBase(formatUnit(unit), 6),
  unit => unit
);

export default class ProvidingItemDetailsForm extends ObserverComponent<IProvidingItemDetailsFormProps & IEditComponentProps, IProvidingItemDetailsState> {
  state = {
    isFirstFieldLocked: false,
    focusedField: TextFields.None,
    isReloading: false,
    isAdvancedOptionsExpanded: false,
  }

  get providingItem(): ProvidingItem {
    return this.props.models[0] as ProvidingItem;
  }

  @computed get isMaterial(): boolean {
    return this.providingItem.subtype === ProvidingItemSubtype.Material;
  }

  get isLabour(): boolean {
    return !this.isMaterial;
  }

  get timeUnit(): Unit {
    return getSafe(() => this.providingItem.providedQuantities.get(UnitType.Time).unit) || Unit.Hour;
  }

  toggleFieldLock = (event) => {
    this.setState({ isFirstFieldLocked: !this.state.isFirstFieldLocked });
    event.stopPropagation();
    event.preventDefault();
  }

  getOtherFields = (textField: TextFields) => (
    Object.values(TextFields).filter(field => field && field !== textField)
  );

  handleFocus = (focusedField: TextFields) => event => {
    const { isFirstFieldLocked } = this.state;
    let shouldToggleLock = false;



    // special case, when creating new item and entering rate, should lock duration to 1
    // so price can be set to the same as rate
    if (
      focusedField === TextFields.Rate &&
      !this.providingItem.labourDuration &&
      !this.providingItem.price
    ) {
      shouldToggleLock = this.isFieldLocked(TextFields.Price, focusedField);
      // also try to keep a field that is 0 unlocked
    } else if (this.getOtherFields(focusedField).some(
      otherField => this.isFieldLocked(otherField, focusedField) && !this.providingItem[otherField]
    )) {
      shouldToggleLock = true;
    }

    this.setState({
      focusedField,
      ...(
        shouldToggleLock
          ? { isFirstFieldLocked: !isFirstFieldLocked }
          : {}
      )
    });
  }

  handleBlur = () => {
    this.setState({
      focusedField: TextFields.None
    });
  }

  isFieldLocked = (textField: TextFields, focusedField = this.state.focusedField) => {
    const { isFirstFieldLocked } = this.state;
    if (!focusedField) {
      return false;
    }

    // must be a simpler way
    switch (focusedField) {
      case TextFields.Price:
        return isFirstFieldLocked
          ? textField === TextFields.Rate
          : textField === TextFields.Duration;

      case TextFields.Rate:
        return isFirstFieldLocked
          ? textField === TextFields.Price
          : textField === TextFields.Duration

      case TextFields.Duration:
        return isFirstFieldLocked
          ? textField === TextFields.Price
          : textField === TextFields.Rate;
    }
  }

  handleChange = changedField => event => {
    let { value } = event.target;
    const isUserEvent = event.isUserEvent || event.nativeEvent;

    switch (changedField) {
      case TextFields.Price:
        value = value || 0;
        break;

      case TextFields.Rate:
        value = this.timeUnit === Unit.Minute ? parseFloat(value) / 60 : parseFloat(value);
        break;

      case TextFields.Duration:
        value = parseFloat(value);
        break;
    }

    if (isUserEvent && value && this.providingItem.isLabour) {
      switch (changedField) {
        case TextFields.Price:
          if (this.isFieldLocked(TextFields.Rate)) {
            this.providingItem.labourDuration = round(value / this.providingItem.labourRate, 6);
          } else {
            this.providingItem.labourRate = roundPrice(value / this.providingItem.labourDuration);
          }
          break;

        case TextFields.Rate:
          if (this.isFieldLocked(TextFields.Duration)) {
            if (!this.providingItem.price && !this.providingItem.labourDuration) {
              this.providingItem.labourDuration = 1;
            }
            this.providingItem.price = roundPrice(value * this.providingItem.labourDuration);
          } else {
            this.providingItem.labourDuration = round(this.providingItem.price / value, 6);
          }
          break;

        case TextFields.Duration:
          if (this.isFieldLocked(TextFields.Price)) {
            this.providingItem.labourRate = roundPrice(this.providingItem.price / value);
          } else {
            this.providingItem.price = roundPrice(value * this.providingItem.labourRate);
          }
          break;
      }
    }

    if ([TextFields.Price, TextFields.Rate, TextFields.Duration].includes(changedField)) {
      this.providingItem.priceUpdatedMiliseconds = (new Date()).getTime();
    }

    lset(this.providingItem, changedField, value);
  };

  onCategoryChange = (category: Category) => {
    this.providingItem.category = category;
    this.providingItem.subcategory = null;
  }

  onSubcategoryChange = (category: Category) => {
    this.providingItem.subcategory = category;
  }

  reopenDialog = () => {
    const { dialogsStore, providingItemsStore } = this.context;
    const { dialogId } = this.props;
    dialogsStore.hideDialog(dialogId);
    const newDialog = new Dialog(this.context);
    newDialog.dialogComponent = ({ open }) => (
      <ProvidingItemEditDialog open={open} dialogId={newDialog.id} providingItem={providingItemsStore.getItem(this.providingItem.id)} />
    )
    dialogsStore.showDialog(newDialog);
  }

  reloadItemFromMerchant = async () => {
    const { providingItemsStore } = this.context;

    this.setState({ isReloading: true });
    try {
      await providingItemsStore.reloadItem(this.providingItem);
    } finally {
      this.setState({ isReloading: false });
    }
  }

  reloadPriceFromMerchant = async () => {
    const { providingItemsStore } = this.context;

    this.setState({ isReloading: true });
    await providingItemsStore.reloadPricing(this.providingItem);
    this.setState({ isReloading: false });

    this.reopenDialog();
  }

  duplicateItem = () => {
    // already working on a model copy
    const item = this.providingItem;
    item.id = uuidv4();
    item.name = item.name + ' ' + i18n.t('(Copy)');

    document.getElementById('material_name').focus();
  }

  removeFromTask = () => {
    const { tasksStore, dialogsStore } = this.context;
    const { parentTask, dialogId } = this.props;
    dialogsStore.hideDialog(dialogId);

    parentTask.providingItem = null;
    tasksStore.addEditItem(parentTask);
  }

  toggleIsMaterial = () => {
    this.providingItem.subtype = this.isMaterial ? ProvidingItemSubtype.Labour : ProvidingItemSubtype.Material;
  }

  debouncedReloadFromMerchant = debounce(this.reloadItemFromMerchant, 1000);

  _render() {
    const { dialogId, onFactoryReset, onUserItemReset, onUserItemPush } = this.props;
    const { settingsStore, providingItemsStore, dialogsStore, userInfoStore } = this.context;
    const item = this.providingItem;
    const { focusedField, isReloading, isAdvancedOptionsExpanded } = this.state;
    const { settings } = settingsStore;

    if (!item) {
      return null;
    }

    // doesn't always exists (eg. creating new)
    const unmodifiedItem = providingItemsStore.getItem(item.id);

    const { canEditProvidingItemsIds } = userInfoStore.user;
    const { shouldAutoSyncProvidingItems } = userInfoStore.nonImpersonatedUser;

    const shouldAllowWasteForLabour = userInfoStore.user.shouldAllowWasteForLabour || settings.shouldAllowWasteForLabour;

    let itemNameLabel = item.subtype === ProvidingItemSubtype.Material
      ? i18n.t('New item name (Material)')
      : i18n.t('New item name (Labour and fees)');
    if (item.name) {
      itemNameLabel = i18n.t('Name');
    }

    const menuItems = compact([
      this.providingItem.merchant && { icon: <ReloadIcon />, text: i18n.t('Update price from merchant'), handler: this.reloadPriceFromMerchant },
      this.providingItem.merchant && { icon: <ReloadIcon />, text: i18n.t('Reload complete item from merchant'), handler: this.reloadItemFromMerchant },
      onFactoryReset && { icon: <ReloadIcon />, text: i18n.t('Reset to factory settings'), handler: onFactoryReset },
      onUserItemReset && shouldAutoSyncProvidingItems && { icon: <ReloadIcon />, text: i18n.t('Reset to latest change'), handler: onUserItemReset },
      onUserItemReset && !shouldAutoSyncProvidingItems && { icon: <ArrowLeftIcon />, text: i18n.t('Reset from "My Items"'), handler: onUserItemReset },
      onUserItemPush && { icon: <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />, text: i18n.t('Save to "My Items"'), handler: onUserItemPush },
      this.isMaterial && { icon: <HelmetIcon />, text: i18n.t('Move to Labour & Fees'), handler: this.toggleIsMaterial },
      !this.isMaterial && { icon: <WoodStackIcon />, text: i18n.t('Move to Material'), handler: this.toggleIsMaterial },
      { icon: <CopyIcon />, text: i18n.t('Duplicate'), handler: this.duplicateItem },
      //{ icon: <LinkIcon />, text: i18n.t('Link to another item'), handler: this.linkItem },
      { icon: <RemoveIcon />, text: i18n.t('Remove from task'), handler: this.removeFromTask },
      {
        icon: <DeleteIcon />,
        text: i18n.t('Delete'),
        handler: () => {
          providingItemsStore.deleteItem(this.providingItem.id);
          dialogsStore.hideDialog(dialogId);
        },
        danger: true
      },
    ]);

    return (
      <div className={classnames(styles.root, { [styles.isMaterial]: this.isMaterial })}>
        {isReloading && <LoadingOverlay />}

        <div className={styles.title} id="draggable-dialog-title">
          <TextField
            id="material_name"
            label={itemNameLabel}
            className={classnames(styles.nameField, { [styles.isEmptyTitle]: !item.name })}
            value={item.name}
            onChange={this.handleChange('name')}
            shouldFocusOnMount={!item.name}
          />

          <CategoriesCombobox
            className={styles.categoryCombo}
            categoryTypes={item.subtype === ProvidingItemSubtype.Material
              ? [CategoryType.Material]
              : [CategoryType.Labour]
            }
            category={item.category}
            onChange={this.onCategoryChange}
          />

          <CategoriesCombobox
            className={styles.categoryCombo}
            parentCategory={item.category}
            category={item.subcategory}
            onChange={this.onSubcategoryChange}
          />

          {!isEmpty(menuItems) && <MenuPopupButton menuItems={menuItems} />}
        </div>

        <div className={styles.content}>
          <div className={styles.column}>
            <ImageBox
              model={item}
              store={providingItemsStore}
              className={styles.imageBox}
              imageHeight={(item.imageUrl && !settingsStore.isVerticalPhone) ? 345 : 100}
            />

            {canEditProvidingItemsIds && (
              <div className={styles.formRow}>
                <IdIcon className={styles.formIcon} />
                <TextField
                  label="ID"
                  className={styles.idField}
                  value={item.id}
                  onChange={this.handleChange('id')}
                />
              </div>
            )}

            {/* debug 

              <div className={styles.formRow}>
                <IdIcon className={styles.formIcon} />
                <TextField
                  label="Price update date"
                  className={styles.priceUpdatedField}
                  value={item.priceUpdatedMiliseconds}
                //onChange={this.handleChange('id')}
                />
              </div>
            */}

            <div className={styles.formRow}>
              <DescriptionIcon className={styles.formIcon} />
              <TextField
                label="Description"
                multiline
                rowsMax="3"
                className={styles.descriptionField}
                value={item.meta.description}
                onChange={this.handleChange('meta.description')}
              />
            </div>

            <div className={styles.formRow}>
              <UrlIcon className={styles.formIcon} />
              <TextField
                shouldSelectAllOnFocus
                label={i18n.t('Link')}
                value={item.url}
                onChange={event => {
                  this.handleChange('url')(event);
                  this.debouncedReloadFromMerchant();
                }}
              />
              {getSafe(() => item.url.includes('http')) && (
                <a className={styles.openMerchantUrlLink} href={item.url} target="_blank" rel="noopener noreferrer">
                  <IconButton>
                    <OpenLinkIcon />
                  </IconButton>
                </a>
              )}
            </div>

          </div>

          <div className={styles.column + ' ' + styles.column2}>
            <div className={styles.formRow}>
              <PriceIcon className={styles.formIcon + ' ' + styles.priceIcon} />
              <div className={styles.priceAndHourlyRate}>
                <div className={classnames(styles.priceAndUpdatedDate, {[styles.isDynamicPrice]: item.isDynamicPrice})}>
                  <TextField
                    shouldSelectAllOnFocus
                    onFocus={this.handleFocus(TextFields.Price)}
                    onBlur={this.handleBlur}
                    label={<span dangerouslySetInnerHTML={{ __html: i18n.t('Price (<b>at cost</b>)') }} ></span>}
                    className={styles.textField + ' ' + styles.textFieldPrice}
                    value={item.isDynamicPrice ? item.priceFormula : item.price}
                    onChange={this.handleChange(item.isDynamicPrice ? 'priceFormula' : 'price')}
                    inputProps={{
                      size: 23,
                    }}
                    multiline={item.isDynamicPrice}
                    InputProps={{
                      inputComponent: item.isDynamicPrice ? undefined : InputNumberFormatCurrency,
                      endAdornment: (
                        <IconButton
                          hidden={!item.labourDuration || !item.labourRate || !focusedField}
                          className={styles.lockIcon}
                          onMouseDown={this.toggleFieldLock}
                        >
                          {this.isFieldLocked(TextFields.Price) ? <LockIcon /> : <LockOpenIcon />}
                        </IconButton>
                      )
                    }}
                  />
                  {/*<div className={styles.updatedDate}>{i18n.t('* Price updated on: ')} {item.priceUpdated.format(i18n.t('MMM DD YYYY'))}</div>*/}
                </div>

                {this.isLabour && (
                  <div className={styles.hourlyRateAndTimeNeeded}>
                    <div> = </div>
                    <TextField
                      shouldSelectAllOnFocus
                      onFocus={this.handleFocus(TextFields.Rate)}
                      onBlur={this.handleBlur}
                      label={i18n.t('Rate')}
                      className={styles.textField}
                      value={this.timeUnit === Unit.Minute ? item.labourRate * 60 : item.labourRate}
                      onChange={this.handleChange('labourRate')}
                      inputProps={{
                        size: 23,
                      }}
                      InputProps={{
                        inputComponent: getRateInputFormatter(this.timeUnit === Unit.Minute ? Unit.Hour : this.timeUnit),
                        endAdornment: (
                          <IconButton
                            hidden={!item.labourDuration || !item.labourRate || !focusedField}
                            className={styles.lockIcon}
                            onMouseDown={this.toggleFieldLock}
                          >
                            {this.isFieldLocked(TextFields.Rate) ? <LockIcon /> : <LockOpenIcon />}
                          </IconButton>
                        )
                      }}
                    />
                    <div> x </div>
                    <TextField
                      shouldSelectAllOnFocus
                      onFocus={this.handleFocus(TextFields.Duration)}
                      onBlur={this.handleBlur}
                      label={i18n.t('Time needed')}
                      className={styles.textField}
                      value={item.labourDuration}
                      onChange={this.handleChange('labourDuration')}
                      inputProps={{
                        size: 23,
                      }}
                      InputProps={{
                        inputComponent: getDurationInputFormatter(this.timeUnit),
                        endAdornment: (
                          <IconButton
                            hidden={!item.labourDuration || !item.labourRate || !focusedField}
                            className={styles.lockIcon}
                            onMouseDown={this.toggleFieldLock}
                          >
                            {this.isFieldLocked(TextFields.Duration) ? <LockIcon /> : <LockOpenIcon />}
                          </IconButton>
                        )
                      }}
                    />
                  </div>
                )}

              </div>
            </div>

            <div className={styles.formRow}>
              <Count123Icon className={styles.formIcon} />

              <MaterialDetailsQuantityTable material={item} />
            </div>

            <div className={styles.formRow}>
              <OptionIcon className={styles.formIcon} />
              <div className={styles.column}>
                {(this.isMaterial || shouldAllowWasteForLabour) && (
                  <FormControl className={styles.textField} margin="normal">
                    <InputLabel>{i18n.t('Waste Percentage')}</InputLabel>
                    <Select
                      value={item.wastePercent}
                      onChange={this.handleChange('wastePercent')}
                      input={<FilledInput />}
                    >
                      <MenuItem value={0}>{i18n.t('None')}</MenuItem>
                      {range(1, 26).map(
                        value => <MenuItem key={value} value={value}>{value} %</MenuItem>
                      )}
                      {/*<MenuItem value={null}><em>Waste settings...</em></MenuItem>  TODO!!!*/}
                    </Select>
                  </FormControl>
                )}

                <FormControl className={styles.textField} margin="normal">
                  <InputLabel>{i18n.t("Rounding Method")}</InputLabel>
                  <Select
                    value={item._canBeRounded ? item._roundingMethod : RoundingMethod.DontRound}
                    onChange={this.handleChange('roundingMethod')}
                    input={<FilledInput />}
                  >
                    <MenuItem value={RoundingMethod.Unspecified}>
                      {i18n.t("{{roundingMethod}} (Method selected in settings)", {
                        roundingMethod: (this.isMaterial || settings.shouldRoundLabour)
                          ? i18n.t(settings.defaultRoundingMethod)
                          : i18n.t(RoundingMethod.DontRound)
                      })}
                    </MenuItem>
                    <MenuItem value={RoundingMethod.RoundByProject}>{i18n.t("Always round quantity for project")}</MenuItem>
                    <MenuItem value={RoundingMethod.RoundByTask}>{i18n.t("Always round quantity of each task")}</MenuItem>
                    <MenuItem value={RoundingMethod.DontRound}>{i18n.t('Do not round')}</MenuItem>
                  </Select>
                </FormControl>

                {!!unmodifiedItem?.minimumPrice && (
                  <TextField
                    label={i18n.t('Minimum price (applied using rounding method)')}
                    className={styles.textField}
                    value={item.minimumPrice}
                    onChange={this.handleChange('minimumPrice')}
                    inputProps={{
                      size: 23,
                    }}
                    InputProps={{
                      inputComponent: InputNumberFormatCurrency,
                    }}
                  />
                )}

                {!isAdvancedOptionsExpanded && (
                  <span
                    className={styles.enterQuantityLink}
                    onClick={() => this.setState({ isAdvancedOptionsExpanded: !isAdvancedOptionsExpanded })}
                  >
                    {i18n.t('Advanced options')}
                  </span>
                )}

                <ExpansionPanel className={styles.expansionPanel} expanded={isAdvancedOptionsExpanded}>
                  <ExpansionPanelSummary />
                  <AccordionDetails className={styles.expansionPanelContent}>
                    {!unmodifiedItem?.minimumPrice && (
                      <TextField
                        label={i18n.t('Minimum price (applied using rounding method)')}
                        className={styles.textField}
                        value={item.minimumPrice}
                        onChange={this.handleChange('minimumPrice')}
                        inputProps={{
                          size: 23,
                        }}
                        InputProps={{
                          inputComponent: InputNumberFormatCurrency,
                        }}
                      />
                    )}

                    {item.merchant && (
                      <FormControl className={styles.textField} margin="normal">
                        <InputLabel>{i18n.t('Merchant Price Adjustment')}</InputLabel>
                        <Select
                          disabled={item.isLabour && item.labourRate !== parseFloat(item.priceFormula)}
                          value={item.priceAdjustmentPercent}
                          renderValue={priceAdjustmentPercent => priceAdjustmentPercent
                            ? (<>
                              <span>{priceAdjustmentPercent > 0 ? '+' : '-'}{priceAdjustmentPercent} %</span>
                              <span className={styles.priceAdjustmentText}>({i18n.t('Price after adjustment')}: {formatCurrency(item.priceWithPriceAdjustment)})</span>
                            </>)
                            : i18n.t('No Price Adjustment')
                          }
                          onChange={this.handleChange('priceAdjustmentPercent')}
                          input={<FilledInput />}
                        >
                          {range(-30, 0).map(
                            value => <MenuItem key={value} value={value}>{value} %</MenuItem>
                          )}
                          <MenuItem value={0}>{i18n.t('No Price Adjustment')}</MenuItem>
                          {range(1, 31).map(
                            value => <MenuItem key={value} value={value}>+{value} %</MenuItem>
                          )}
                          {/*<MenuItem value={null}><em>Waste settings...</em></MenuItem>  TODO!!!*/}
                        </Select>
                      </FormControl>
                    )}

                    <FormControl className={styles.textField} margin="normal">
                      <InputLabel>{i18n.t("Fees behaviour")}</InputLabel>
                      <Select
                        value={item.isExemptFromAllFees}
                        onChange={this.handleChange('isExemptFromAllFees')}
                        input={<FilledInput />}
                      >
                        <MenuItem value={false}>{i18n.t('Apply fees (normal behaviour)')}</MenuItem>
                        <MenuItem value={true}>{i18n.t('Never apply fees to this item')}</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl className={styles.textField} margin="normal">
                      <InputLabel>{i18n.t("Quantity display in report")}</InputLabel>
                      <Select
                        value={item.reportQuantityBehaviour}
                        onChange={this.handleChange('reportQuantityBehaviour')}
                        input={<FilledInput />}
                      >
                        <MenuItem value={ProvidingItemQuantityBehaviour.Default}>{i18n.t('Show quantities (follow report options)')}</MenuItem>
                        <MenuItem value={ProvidingItemQuantityBehaviour.AlwaysHide}>{i18n.t('Never show quantities')}</MenuItem>
                        <MenuItem value={ProvidingItemQuantityBehaviour.AlwaysShow}>{i18n.t('Always show quantities')}</MenuItem>
                      </Select>
                    </FormControl>


                    <FormControl className={styles.textField} margin="normal">
                      <InputLabel>{i18n.t("Task display in report")}</InputLabel>
                      <Select
                        value={item.reportTaskBehaviour}
                        onChange={this.handleChange('reportTaskBehaviour')}
                        input={<FilledInput />}
                      >
                        <MenuItem value={ProvidingItemTaskBehaviour.Default}>{i18n.t('Show tasks with this item (follow report options)')}</MenuItem>
                        <MenuItem value={ProvidingItemTaskBehaviour.AlwaysHide}>{i18n.t('Hide tasks in report when possible')}</MenuItem>
                        <MenuItem value={ProvidingItemTaskBehaviour.AlwaysShow}>{i18n.t('Always show tasks with this item')}</MenuItem>
                      </Select>
                    </FormControl>

                  </AccordionDetails>
                </ExpansionPanel>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}