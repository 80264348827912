import { last } from 'lodash';
import XLSX from 'xlsx';
import i18n from './i18n';

export function deleteXLSXWorksheetHiddenRows(worksheet) {
  let deletedRowsCount = 0;
  worksheet['!rows'].forEach((r, index) => {
    if (r.hidden) {
      delete_row(worksheet, index - deletedRowsCount);
      deletedRowsCount++;
      delete r.hidden;
    }
  });
}

export function applyXLSXTrialLimits(worksheet) {
  if (worksheet['!rows'].length <= 10) {
    return;
  }

  let range = XLSX.utils.decode_range(worksheet["!ref"]);
  const numRows = range.e.r;

  let deletedRowsCount = 0;
  for (let i = 10; i < numRows; i++) {
    delete_row(worksheet, i - deletedRowsCount);
    deletedRowsCount++;
  }

  worksheet['A11'].v = '*** ' + i18n.t('Trial Version - Only the 10 first lines are displayed.') + ' ***';
  worksheet['A11'].s = { font: { bold: true } }; // not working

  const lastColCode = last((worksheet['!fullref'] as string).split(':')).charCodeAt(0);

  for (let colCode = 'B'.charCodeAt(0); colCode < lastColCode; colCode++) {
    worksheet[String.fromCharCode(colCode) + '11'] = { t: 's', v: '' };
  }
}

const delete_row = (ws, row_index) => {
  let range = XLSX.utils.decode_range(ws["!ref"])

  for (var R = row_index; R <= range.e.r; ++R) {
    for (var C = range.s.c; C <= range.e.c; ++C) {
      ws[ec(R, C)] = ws[ec(R + 1, C)]
    }
  }
  range.e.r--
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
const ec = (r, c) => {
  return XLSX.utils.encode_cell({ r: r, c: c })
}


// no idea what this does
export function stox(wb, raw = false) {
  var out = [];
  wb.SheetNames.forEach(function (name) {
    var o = { name: name, rows: {} };
    var ws = wb.Sheets[name];
    var range = XLSX.utils.decode_range(ws['!ref']);
    // sheet_to_json will lost empty row and col at begin as default
    range.s = { r: 0, c: 0 };
    var aoa = XLSX.utils.sheet_to_json(ws, {
      raw,
      header: 1,
      range: range
    });

    aoa.forEach(function (r, i) {
      var cells = {};
      r.forEach(function (c, j) {
        cells[j] = { text: c };

        var cellRef = XLSX.utils.encode_cell({ r: i, c: j });

        if (ws[cellRef] != null && ws[cellRef].f != null) {
          cells[j].text = "=" + ws[cellRef].f;
        }
      });
      o.rows[i] = { cells: cells };
    });

    o.merges = [];
    (ws["!merges"] || []).forEach(function (merge, i) {
      //Needed to support merged cells with empty content
      if (o.rows[merge.s.r] == null) {
        o.rows[merge.s.r] = { cells: {} };
      }
      if (o.rows[merge.s.r].cells[merge.s.c] == null) {
        o.rows[merge.s.r].cells[merge.s.c] = {};
      }

      o.rows[merge.s.r].cells[merge.s.c].merge = [
        merge.e.r - merge.s.r,
        merge.e.c - merge.s.c
      ];

      o.merges[i] = XLSX.utils.encode_range(merge);
    });

    out.push(o);
  });

  return out;
}
