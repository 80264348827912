
import { MuiThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import Globals from 'Globals';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import ReportHeader from 'components/common/ReportHeader/ReportHeader';
import { zipObject } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { ThroughProvider } from 'react-through';
import StoresContext from 'stores/StoresContext';
import { theme } from 'theme';
import i18n from 'utils/i18n';


const styles = require('./PageTemplate.module.css');

interface IPageTemplateProps {
  pageNum?: number,
  totalPages?: number,
};

// Don't make this an observer component because it is used directly by the reporting engine
// without stores context
export default class PageTemplate extends ObserverComponent<IPageTemplateProps> {
  static defaultProps = {
    pageNum: 1,
    totalPages: 1,
  };

  _render() {
    const { userInfoStore, settingsStore, reportsStore } = Globals.defaultStores;
    const { pageNum, totalPages } = this.props;
    const { report } = reportsStore;
    const { user } = userInfoStore;
    const shouldHideEvalumoReportFooter = user.shouldHideEvalumoReportFooter || settingsStore.settings?.shouldHideEvalumoReportFooter;

    const { shouldShowReportGenerationTime } = user;

    const date = moment().format(shouldShowReportGenerationTime ? 'lll' : 'LL');

    // duplicate
    const companyFieldsKeys = ['street', 'city', 'province', 'postalCode', 'phoneNumber', 'companyEmail', 'website', 'companyName', 'other'];
    const companyFields = zipObject(companyFieldsKeys, companyFieldsKeys.map(field => (
      report[field] === 'undefined'
        ? (user?.[field] && user[field] !== 'undefined' ? user[field] : '')
        : report[field]
    )));

    return (
      <div className={styles.root}>
        {/*
        annoying but PageTemplate gets added by Kendo PDF without being in the app DOM,
        so we have to setup context like in index.tsx
        */}
        <StoresContext.Provider value={Globals.defaultStores}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <ThroughProvider>
                <ReportHeader />
              </ThroughProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </StoresContext.Provider>
        <div className={classnames(styles.footer, { isLastPage: pageNum === totalPages })} >
          <div>
            <span className={styles.reportNumber}>{report.number && user.shouldShowReportNumberInFooter && (<>{i18n.t(report.subtype)} #{report.number} -&nbsp;</>)}</span>
            <span className={styles.pageNumber}>{i18n.t('Page {{pageNum}} of {{totalPages}}', { pageNum, totalPages })}</span>
          </div>
          <div className={classnames(styles.footerCopyright, { [styles.shouldShowReportInitials]: user.shouldShowReportInitials })}>
            <span>
              {companyFields['companyName']} - {shouldHideEvalumoReportFooter
                ? i18n.t('Generated on {{date}}', { date })
                : i18n.t('Generated on {{date}} with Evalumo - evalumo.com', { date })
              }
            </span>
          </div>
          {user.shouldShowReportInitials && pageNum !== totalPages && (
            <div className={styles.initials}>
              <div>
                _____________________
              </div>
              <div>
                {i18n.t("Client's initials")}
              </div>
            </div>
          )}
        </div>

      </div>
    );
  }
}