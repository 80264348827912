import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { DbLocationType } from 'constants/DbLocationType';
import { first, isEmpty } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import Dialog from 'models/Dialog';
import PriceUpdate from 'models/PriceUpdate';
import ProvidingItem from 'models/ProvidingItem';
import moment from 'moment';
import React from 'react';
import FirebaseStore from 'stores/FirebaseStore';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { hashCode } from 'utils/Utils';
import i18n from 'utils/i18n';
const colors = require('../Colors.scss');

// extra delay other than waiting for the page to load
const UPDATE_DELAY = 4000;

const PRICE_VALIDITY_DAYS = 5;
const PRICE_VALIDITY_DAYS_RANDOMNESS = 5;

// retry every 4 days if unable to load
const UNABLE_TO_LOAD_RETRY_DAYS = 4;
// give a warning after 3 months
const UNABLE_TO_LOAD_WARNING_DAYS = 2 * 30;

export default class PriceUpdateStore extends FirebaseStore<PriceUpdate> {
  DEBUG = false;

  storeKey = 'priceUpdates';

  dbLocationsTypes = new Set([
    DbLocationType.User,
  ]);

  // could make separate project settings

  hasLocalization = false;
  hasCategories = false;

  @computed get priceUpdate() {
    return first(this.items)
  }

  @computed get numberOfOutdatedPrices() {
    return this.priceUpdate?.numberOfOutdatedPrices;
  }

  // we only know item is unsyncable if a full update was completed, otherwise
  // could be that the person always pauses the update
  @computed get unsyncableItems(): ProvidingItem[] {
    const { providingItemsStore, treeNodesStore, tasksStore } = this.stores;

    if (
      !this.priceUpdate ||
      !isEmpty(this.itemsToUpdate) ||
      !treeNodesStore?.rootNode
    ) {
      return [];
    }

    return providingItemsStore.items
      .filter(i => tasksStore.providingItemsIdsUsedInProject.has(i.id))
      .filter(i => (
        i.merchant &&
        !this.priceUpdate.priceMap.get(i.id) &&
        Date.now() - i.priceUpdatedMiliseconds > UNABLE_TO_LOAD_WARNING_DAYS  * 24 * 60 * 60 * 1000 &&
        this.priceUpdate._priceMapUnableToLoadMiliseconds.get(i.id)
      ))
      .sort((a, b) => (a.priceUpdatedMiliseconds || 0) - (b.priceUpdatedMiliseconds || 0));
  }

  getApplicableUpdatedMiliseconds(item: ProvidingItem) {
    const { providingItemsStore } = this.stores;

    return Math.max(
      // cant do postal code
      //providingItemsStore.masterPriceData.get(item.id)?.priceUpdatedMiliseconds ||
      this.priceUpdate?._priceMapUpdatedMiliseconds?.get?.(item.id) || 0,
      // cant sync from user collection yet
      //providingItemsStore.userCollectionItemsMap?.get?.(item.id)?.priceUpdatedMiliseconds ||  
      item.priceUpdatedMiliseconds || 0,
    );
  }

  // aka not yet CHECKED online
  // different from PriceMap outdatedItems which are items displayed in the price change notification window
  @computed get itemsToUpdate() {
    const { providingItemsStore } = this.stores;

    // not really non master, includes master that are used by user
    return providingItemsStore.nonMasterItemsWithMerchant
      .filter(item => {
        if (!item || !item.merchant) {
          return false;
        }

        const updatedMiliseconds = this.getApplicableUpdatedMiliseconds(item);
        const unableToLoadMiliseconds = this.priceUpdate?._priceMapUnableToLoadMiliseconds?.get(item.id) || 0;
        const numDaysSinceUpdate = moment.duration(moment(new Date()).diff(moment(updatedMiliseconds))).asDays();
        const numDaysSinceUnableToLoad = moment.duration(moment(new Date()).diff(moment(unableToLoadMiliseconds))).asDays();

        return (
          (
            !unableToLoadMiliseconds && updatedMiliseconds && numDaysSinceUpdate > (Math.abs(hashCode(item.name)) % PRICE_VALIDITY_DAYS_RANDOMNESS + PRICE_VALIDITY_DAYS)
          ) || (
            unableToLoadMiliseconds && numDaysSinceUnableToLoad > UNABLE_TO_LOAD_RETRY_DAYS
          )
        );
      })
  }

  @computed get updatePercent() {
    const numUpdated = this.priceUpdate?._priceMap?.size || 0;
    const numToUpdate = this.itemsToUpdate.length;
    return numUpdated / (numToUpdate + numUpdated) * 100;
  }

  @observable _isPaused = true;
  @computed get isPaused() {
    return this._isPaused;
  }
  set isPaused(value) {
    this._isPaused = value;

    if (value) {
      // immediately stop all requests
      [...document.getElementsByClassName("hiddenIframe")].forEach(elem => {
        elem.remove();
      })
    }
  }

  _updateTimeout;

  get updateTimeout() {
    return this._updateTimeout;
  }

  set updateTimeout(value) {
    if (this._updateTimeout) {
      clearTimeout(this._updateTimeout);
    }
    
    this._updateTimeout = value;
  }


  checkPrices = () => {
    // update timeout is how we know update is ongoing
    this.isPaused = false;
    this.updateTimeout = setTimeout(this.loadUpdatedPrices, 0);

    if (this.DEBUG) {
      console.log('setting timeout for' + new Date().toISOString())
    }
  }

  // always call from a timeout
  loadUpdatedPrices = async () => {
    const { providingItemsStore } = this.stores;
    if (!providingItemsStore.isReady) {
      this._isPaused = true;
      return;
    }

    const itemToCheck = first(this.itemsToUpdate);

    if (itemToCheck && !this.isPaused) {
      let newPrice = 0;
      try {
        console.log(`price check for ${itemToCheck.name}`);
        newPrice = await providingItemsStore.getUpdatedPrice(itemToCheck);
        console.log('price check end');
      } catch (e) {
        console.error('error checking price', itemToCheck?.id);
        if (this._isPaused) {
          // dont mark as unable to load if it's only an interruption due to pausing updates
          return;
        }
      }

      this.priceUpdate._priceMapUpdatedMiliseconds.set(itemToCheck.id, Date.now());

      if (!newPrice) {
        // fallback to master list price if unable to get it from merchant website
        const masterItem = providingItemsStore.masterPriceData.get(itemToCheck.id);
        const masterPrice = masterItem?.price;
        const masterPriceUpdatedMiliseconds = masterItem?.priceUpdatedMiliseconds || masterItem?.updatedMiliseconds || 0;

        if (masterItem) {
          newPrice = masterPrice;

          // should indicate that this price is not as up to date as one from merchant, but can't use master item priceupdated
          // because it will do an infinite loop trying to get a more up to date price
          this.priceUpdate._priceMapUpdatedMiliseconds.set(itemToCheck.id, masterPriceUpdatedMiliseconds);
        }

        this.priceUpdate._priceMapUnableToLoadMiliseconds.set(itemToCheck.id, Date.now());
      } else {
        // was able to load from merchant

        if (this.priceUpdate._priceMapUnableToLoadMiliseconds.has(itemToCheck.id)) {
          this.priceUpdate._priceMapUnableToLoadMiliseconds.delete(itemToCheck.id);
        }

        if (newPrice === itemToCheck.price) {
          // mark that price was checked and is still the same, no need for user interaction
          itemToCheck.priceUpdatedMiliseconds = Date.now();
          const batch = firestoreBatch(this.stores);
          batch.isUndoable = false;
          providingItemsStore.addEditItem(itemToCheck, true, ['priceUpdatedMiliseconds'], batch);
          batch.commit();
        }
      }

      this.priceUpdate._priceMap.set(itemToCheck.id, newPrice);


      // don't have to save to db for each single price change
      if (Math.floor((Math.random() * 3)) === 0 || this.priceUpdate._priceMap.size >= providingItemsStore.nonMasterItemsWithMerchant.length) {
        const batch = firestoreBatch(this.stores);
        batch.isUndoable = false;
        this.addEditItem(this.priceUpdate, true, undefined, batch);
        batch.commit();
      }
      this.updateTimeout = setTimeout(this.loadUpdatedPrices, UPDATE_DELAY);
      if (this.DEBUG) {
        console.log('setting timeout for' + new Date((Date.now() + UPDATE_DELAY)).toISOString())
      }

    } else {
      // nothing to update check again in 5 minutes
      this.updateTimeout = setTimeout(this.loadUpdatedPrices, 1 * 60 * 1000);
      if (this.DEBUG) {
        console.log('setting timeout for' + new Date((Date.now() + 1 * 60 * 1000)).toISOString())
      }
    }
  }

  @action launchNewUpdate = () => {
    const { dialogsStore } = this.stores;

    const newDialog = new Dialog(this.stores);
    newDialog.dialogComponent = ({ open }) => (
      <ConfirmDialog
        open={open}
        dialogId={newDialog.id}
        onConfirm={() => {
          this.priceUpdate._priceMap.clear();
          this.priceUpdate._priceMapUpdatedMiliseconds.clear();

          this.addEditItem(this.priceUpdate);

          this.checkPrices();

          dialogsStore.hideDialog(newDialog.id);
        }}
        title={i18n.t('Do you really want to launch a new price update?')}
        actionButtonColor={colors.red}
        yesLabel={i18n.t('Launch new update')}
      />
    );
    dialogsStore.showDialog(newDialog);
  }

  public onLoadCompleted() {
    super.onLoadCompleted();

    if (isEmpty(this.items)) {
      // create the price Update node if it doesn't exist
      const priceUpdate = new PriceUpdate(this.stores);
      const batch = firestoreBatch(this.stores);
      batch.isUndoable = false;
      this.addEditItem(priceUpdate, true, undefined, batch);
      batch.commit();
    }

    if (/*true || */!window.location.href.includes('local.evalumo')) {
      setTimeout(this.checkPrices, 60000);
      if (this.DEBUG) {
        console.log('setting timeout for' + new Date((Date.now() + 60000)).toISOString())
      }
    }
  }

  attemptLoadItems() {
    if (
      !this.stores.tasksStore.isReady ||
      !this.stores.treeNodesStore.isReady ||
      !this.stores.shapesStore.isReady ||
      !this.stores.projectsStore.isReady
    ) {
      return;
    }

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.tasksStore?.isReady &&
    this.stores?.treeNodesStore?.isReady &&
    this.stores?.shapesStore?.isReady &&
    this.stores?.projectsStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
