import { AdjustmentType } from 'constants/AdjustmentType';
import { DRAWING_SCALE } from 'constants/Constants';
import { FormulaType } from 'constants/FormulaType';
import { ShapeTags } from 'constants/ShapeTags';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { Parser as FormulaParser } from 'hot-formula-parser';
import { compact, first, isEmpty, last, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { serializable } from 'serializr';
import Stores from 'stores/Stores';
import MeasurementConverter from 'utils/MeasurementConverter';
import { AVERAGE_HEIGHT_FUNCTION, BBOX_HEIGHT_FUNCTION, BBOX_MAX_LENGTH_FUNCTION, BBOX_MIN_LENGTH_FUNCTION, BBOX_WIDTH_FUNCTION, CORNERS_QUANTITY_FUNCTION, COUNT_FUNCTION, EXTREMITIES_QUANTITY_FUNCTION, FROM_DRAWING_LEGACY_SUFFIX, GUESS_WINDOW_OR_DOOR_HEIGHT_FUNCTION, GUESS_WINDOW_OR_DOOR_WIDTH_FUNCTION, LENGTH_FUNCTION, OFFSET_LENGTH_FUNCTION, OFFSET_SURFACE_FUNCTION, ON_DRAWING_LEGACY_SUFFIX, OPENINGS_QUANTITY_FUNCTION, OPENINGS_SURFACE_FUNCTION, OPENINGS_TOTAL_PERIMETER_FUNCTION, OPENINGS_TOTAL_SIDES_FUNCTION, OPENINGS_TOTAL_TOP_BOTTOM_FUNCTION, OPENINGS_TOTAL_WIDTH_FUNCTION, PERIMETER_FUNCTION, RECTANGLES_BOTTOMS_FUNCTION, RECTANGLES_SIDES_FUNCTION, RECTANGLES_TOPS_FUNCTION, REPEATED_LINES_QUANTITY_FUNCTION, REPEAT_LINE_FUNCTION, SAME_AS_CHILDREN_FUNCTION, SHAPES_BOTTOMS_FUNCTION, SHAPES_SIDES_FUNCTION, SHAPES_TOPS_FUNCTION, SUM_CHILDREN_FUNCTION, SURFACES_PERIMETER_FUNCTION, SURFACES_QUANTITY_FUNCTION, SURFACE_FROM_TASKS_FUNCTION, SURFACE_FUNCTION, WEIGHT_FROM_TASKS_FUNCTION, escapeVar, formulaHasVariables, isEveryNodeFormulaSameValue, preparestring } from 'utils/MeasurementFormatter';
import { MEASUREMENT_TREENODE_SEPARATOR, getFormulaType, getMeasurementFromVariable } from 'utils/MeasurementUtil';
import { getBoundingBox } from 'utils/ShapeUtil';
import { getSafe, round } from 'utils/Utils';
import i18n from 'utils/i18n';
import { localized } from 'utils/localized';
import Adjustment from './Adjustment';
import Measurement from './Measurement';
import Point from './Point';
import { Rectangle } from './Rectangle';
import SerializableBase from './SerializableBase';
import TreeNode from './TreeNode';

// Not really a default formula, more a flag for advanced measurement (when bubbling)
// to indicate in the parent if we are temporary editing the parent measurement.
// As soon as editing is done, flag is put back.
export const DEFAULT_FORMULA = '0';

// TODO: Auto Activate all measurements on which a formula depends for a node

// this object gets shallow cloned, so doesn't support array and other complex
// structures as properties
export default class MeasurementValue extends SerializableBase {
  type = 'MeasurementValue';

  @computed get subtype(): FormulaType {
    return this.index;
  }

  // careful because this isn't serializable, so won't be duplicated when cloning
  // even if we need this mostly when working with editable copy
  // for now needed because of measurement value adjustments
  // don't want to store in measurement value directly because those are only set in leaf nodes
  @observable tempTreeNode: TreeNode;

  // needs to be serializable to allow cloning
  @observable @serializable treeNodeId: ModelId = '';

  @computed get treeNode(): TreeNode {
    return getSafe(() => this.tempTreeNode || this.stores.treeNodesStore.getItem(this.treeNodeId)) || null;
  }

  set treeNode(value: TreeNode) {
    this.treeNodeId = getSafe(() => value.id) || '';
  }

  @observable @serializable measurementId: ModelId = ''

  // careful because this isn't serializable, so won't be duplicated when cloning
  // even if we need this mostly when working with editable copy
  @observable _tempMeasurement: Measurement;

  @computed get tempMeasurement(): Measurement {
    return this._tempMeasurement;
  }

  set tempMeasurement(tempMeasurement: Measurement) {
    // for advanced measurement, temporary copy computed formula
    // so that we can edit it and apply it to children.
    // this local formula will be ignored unless a tempMeasurement is set

    if (
      // remplacer par la liste de node avec même mesure
      !isEmpty(this.bubbleDescendantsWithSameMeasurement) &&
      tempMeasurement
    ) {
      let initialFormulaToShow = this.formula;

      if (this.areDescendantsSameFormula) {
        if (!formulaHasVariables(this.firstDescendantFormula) && getSafe(() => this.measurement.isSummable)) {
          initialFormulaToShow = '' + parseFloat(this.firstDescendantFormula) * this.bubbleDescendantsWithSameMeasurement.length;
        } else {
          initialFormulaToShow = this.firstDescendantFormula;
        }
      }

      this._formula = initialFormulaToShow;
    }

    this._tempMeasurement = tempMeasurement;
  }

  @computed get measurement(): Measurement {
    return getSafe(() => (
      this.tempMeasurement ||
      this.stores.measurementsStore.getItem(this.measurementId))
    ) || null;
  }

  set measurement(value: Measurement) {
    this.measurementId = getSafe(() => value.id) || '';
  }

  // not sure if this works correctly
  @localized _formula: string;

  @computed get formula() {
    // except in edit mode (tempMeasuremet) with advanced measurements
    // we ignore _formula, and use predefined sum or same value
    if (
      !this.tempMeasurement &&
      this.measurementId && // value won't be stored in leaf when creating new measurement
      // has descendants with same measurement
      !isEmpty(this.bubbleDescendantsWithSameMeasurement)
    ) {
      return this.measurement.isSummable
        ? `${i18n.t(SUM_CHILDREN_FUNCTION)}()`
        : `${i18n.t(SAME_AS_CHILDREN_FUNCTION)}()`;
    }

    return this._formula;
  }

  set formula(value: string) {
    // only variables are localized, not numbers
    if (formulaHasVariables(value)) {
      this._formula = value;
    } else {
      this.__formula = { 'en': value };
    }
  }

  @computed get adjustment(): Adjustment {
    if (!this.treeNode) {
      return new Adjustment(this.stores);
    }

    return this.treeNode.ownMeasurementAdjustments.get(this.measurementId);
  };

  set adjustment(value: Adjustment) {
    if (value) {
      value.measurementId = this.measurementId; // annoying that this isn't done automatically by serializr
      this.treeNode.ownMeasurementAdjustments.set(this.measurementId, value);
    } else {
      this.treeNode.ownMeasurementAdjustments.delete(this.measurementId);
    }
  }

  @computed get adjustmentValue(): number {
    return this.adjustment?.value || 0;
  };

  set adjustmentValue(value: number) {
    const adjustment = this.adjustment || new Adjustment(this.stores);
    adjustment.value = value;
    this.adjustment = adjustment;
  }

  @computed get adjustmentType(): AdjustmentType {
    return getSafe(() => this.adjustment.subtype) || AdjustmentType.Add;
  };

  set adjustmentType(type: AdjustmentType) {
    const adjustment = this.adjustment || new Adjustment(this.stores);
    adjustment.subtype = type;
    this.adjustment = adjustment;
  }

  @computed get firstDescendantWithSameMeasurement() {
    return first(this.bubbleDescendantsWithSameMeasurement) || this.treeNode;
  }

  // todo improve memoize
  @computed get firstDescendantFormula() {
    return this.firstDescendantWithSameMeasurement?.ownMeasurementValues?.get(this.measurementId)?.formula || '';
  }

  @computed get areDescendantsDefaultFormula() {
    if (isEmpty(this.bubbleDescendantsWithSameMeasurement)) {
      return getSafe(() => this.formula === this.measurement.defaultFormula);
    }

    return this.bubbleDescendantsWithSameMeasurement.every(descendant => getSafe(() =>
      descendant.ownMeasurementValues.get(this.measurement.id).formula === this.measurement.defaultFormula)
    );
  }

  @computed get areDescendantsSameFormula() {
    if (isEmpty(this.bubbleDescendantsWithSameMeasurement)) {
      return true;
    }

    const firstDescendantSameMeasurementValue = this.firstDescendantWithSameMeasurement.measurementValues.get(this.measurement.id);

    return this.bubbleDescendantsWithSameMeasurement
      .map(descendant => descendant.ownMeasurementValues.get(this.measurement.id))
      .every(measurementValue => getSafe(() =>
        measurementValue.formula === this.firstDescendantFormula) &&
        measurementValue.adjustmentValue === firstDescendantSameMeasurementValue.adjustmentValue &&
        measurementValue.adjustmentType === firstDescendantSameMeasurementValue.adjustmentType
      );
  }

  @computed get childrenWithSameMeasurement() {
    //console.log('not memoized childrensum', this.measurement.name);
    const childNodes = this.treeNode.bubbleChildren;
    if (isEmpty(childNodes)) {
      return [];
    }

    return childNodes.filter((childNode: TreeNode) => (
      !!childNode.measurements.find(
        activeMeasurement => activeMeasurement.id === this.measurement.id
      )));
  }

  @computed get childrenSum() {
    if (!this.treeNode) {
      return 0;
    }

    return sumBy(
      this.childrenWithSameMeasurement,
      childNode => childNode.measurementValues.get(this.measurement.id)?.metricValue || 0
    );
  }

  @computed get isInputParameter() {
    return false; // todo
  }

  @computed get bubbleDescendantsWithSameMeasurement() {
    if (!this.treeNode || !this.measurement) {
      return [];
    }
    return this.treeNode.getBubbleDescendantsWithOwnMeasurement(this.measurement);
  }

  @computed get childrenValue() {
    if (!this.treeNode) {
      return 0;
    }
    const measurement = this.measurement;

    // Bubble up "same as children" measurements whenever possible,
    // to avoid having to go deep in tree to apply list
    if (isEveryNodeFormulaSameValue(measurement, this.bubbleDescendantsWithSameMeasurement)) {
      const firstChildFormula = this.bubbleDescendantsWithSameMeasurement[0].measurementValues.get(measurement.id);
      return firstChildFormula.metricValue;
    } else {
      return NaN;
    }
  }

  @computed get metricValue(): number {
    let retval = 0;
    try {
      retval = this.metricValueAndRelatedShapesIds[0];
    } catch (error) {
      console.error('Impossible de calculer la mesure suivante: ' + this.treeNode?.path + ' ' + this.treeNode?.name + ' ' + this.measurement?.name);
    }

    return retval;
  }

  @computed get relatedShapesIds(): string[] {
    return this.metricValueAndRelatedShapesIds[1];
  }

  @computed get metricValueAndRelatedShapesIds(): [number, string[]] {
    if (!this.measurement) {
      // debugger;
      return [0, []];
    }

    let relatedShapesIds = new Set<string>();

    //console.log('not memoized metricValue', this.measurement.name);
    let node = this.treeNode;
    const parser = new FormulaParser();
    let lines = [];

    parser.on('callVariable', (variableName, done) => {
      if (!node) {
        done(0);
        return;
      }

      // get measurement values from other treeNodes using formula like '[Ancestor(optional)]Group!Measurement name : Quantité'
      // can quickly cause perf issues, should have a map
      if (variableName.includes(MEASUREMENT_TREENODE_SEPARATOR)) {
        const variableNameTokens = variableName.split(MEASUREMENT_TREENODE_SEPARATOR);
        const treeNodeAncestorsNames = [...variableNameTokens[0].matchAll(/\[([^\]]+)\]/g) || []].map(match => match[1]);
        const treeNodeName = last(variableNameTokens[0].split(']'));
        variableName = variableNameTokens[1];

        function checkAncestors(n: TreeNode) {
          if (isEmpty(treeNodeAncestorsNames)) {
            return true;
          }
          return n.ancestors
            .map(a => escapeVar(a.name))
            .slice(0, treeNodeAncestorsNames.length)
            .reverse()
            .join('#') ===  treeNodeAncestorsNames.join('#');
        }

        node = (
          node.childrenWithMeasurements.find(n => escapeVar(n.name) === treeNodeName && checkAncestors(n)) ||
          node.parent?.childrenWithMeasurements?.find?.(n => escapeVar(n.name) === treeNodeName && checkAncestors(n)) ||
          this.stores.treeNodesStore.allVisibleNodesWithMeasurements.find(n => escapeVar(n.name) === treeNodeName && checkAncestors(n)) ||
          node
        );
      }

      const measurementVariable = getMeasurementFromVariable(variableName, node.measurements, this.measurement);

      if (
        measurementVariable &&
        !measurementVariable.measurementDependencies.map(m => m.id).includes(this.measurement.id)
      ) {
        const measurementValue = node.measurementValues.get(measurementVariable.id);

        // avoid applying a treenode quantities multiplier to both input variables and output result
        const unmultipliedMetricValue = measurementVariable?.shouldApplyTreeNodeQuantitiesMultiplier
          ? measurementValue.metricValue / (this.treeNode.quantitiesMultiplier || 1)
          : measurementValue.metricValue

        done(unmultipliedMetricValue || 0)
      }
      else {
        done(0);
      }
    });

    parser.on('callFunction', (name, params, done) => {
      if (!node) {
        done(0);
        return;
      }

      // normalize and remove legacy function names
      name = name
        .toUpperCase()
        .replace(FROM_DRAWING_LEGACY_SUFFIX, '')
        .replace(ON_DRAWING_LEGACY_SUFFIX, '');

      switch (name) {
        case i18n.t(SUM_CHILDREN_FUNCTION):
          compact(
            this.childrenWithSameMeasurement
              .map(node => node.measurementValues.get(this.measurementId)?.relatedShapesIds))
            .flat()
            .forEach(relatedShapeId => relatedShapesIds.add(relatedShapeId));

          done(this.childrenSum);
          break;
        case i18n.t(SAME_AS_CHILDREN_FUNCTION):
          done(this.childrenValue)
          break;
        case SURFACE_FUNCTION:
          node.surfaceShapes.forEach(shape => relatedShapesIds.add(shape.id));

          if (params.length === 2) {
            done(node.getShapeSurfaceWithSlope(...params));
          } else if (params.length === 3) {
            // old signature, ignore second param which is the direction but isnt needed
            done(node.getShapeSurfaceWithSlope(params[0], params[2]));
          } else {
            done(node.shapeSurface);
          }
          break;
        case OFFSET_SURFACE_FUNCTION:
          node.surfaceShapes.forEach(shape => relatedShapesIds.add(shape.id));

          done(node.getShapeSurfaceWithOffset(...params));
          break;

        case LENGTH_FUNCTION:
          node.lineShapes.forEach(shape => relatedShapesIds.add(shape.id));

          if (isEmpty(params)) {
            done(node.shapeLength);
          } else {
            done(node.getShapeLengthWithSlope(...params));
          }
          break;

        case COUNT_FUNCTION:
          node.countPointShapes.forEach(shape => relatedShapesIds.add(shape.id));

          done(node.countPointShapes.length);
          break;

        case OFFSET_LENGTH_FUNCTION:
          node.lineShapes.forEach(shape => relatedShapesIds.add(shape.id));

          done(node.getShapeLengthWithOffset(params[0], params.length > 1 ? params[1] : params[0]));
          break;

        case REPEAT_LINE_FUNCTION:
          node.surfaceShapes.forEach(shape => relatedShapesIds.add(shape.id));

          done(node.getShapeRepeatedLinesLength(...params));
          break;

        case REPEATED_LINES_QUANTITY_FUNCTION:
          node.surfaceShapes.forEach(shape => relatedShapesIds.add(shape.id));

          done(node.getShapeRepeatedLinesQuantity(...params));
          break;

        case PERIMETER_FUNCTION:
          if (!node.shapeSurface) {
            done(0);
          } else {
            node.lineShapes.forEach(shape => relatedShapesIds.add(shape.id));
            done(node.shapeLength);
          }
          break;

        case BBOX_WIDTH_FUNCTION:
        case BBOX_HEIGHT_FUNCTION:
        case BBOX_MIN_LENGTH_FUNCTION:
        case BBOX_MAX_LENGTH_FUNCTION:
        case GUESS_WINDOW_OR_DOOR_HEIGHT_FUNCTION:
        case GUESS_WINDOW_OR_DOOR_WIDTH_FUNCTION:
          node.lineShapes.forEach(shape => relatedShapesIds.add(shape.id));

          const pixelBbox = ([2, 4].includes(node.drawingRoot?.pointsExceptCountPoints?.length) || params.length > 0)
            ? getBoundingBox(node.drawingRoot?.pointsExceptCountPoints)
            : new Rectangle(this.stores, new Point(0, 0), new Point(0, 0));
          // used only for windows and doors and rectangular floors for now
          // allow either viewed from top (2 points) or from elevation (4 points)
          switch (name) {
            case BBOX_WIDTH_FUNCTION:
              done((pixelBbox.width) / DRAWING_SCALE);
              break;
            case BBOX_HEIGHT_FUNCTION:
              done((pixelBbox.height) / DRAWING_SCALE);
              break;
            case BBOX_MIN_LENGTH_FUNCTION:
              done(Math.min(pixelBbox.width, pixelBbox.height) / DRAWING_SCALE);
              break;
            case BBOX_MAX_LENGTH_FUNCTION:
              done(Math.max(pixelBbox.width, pixelBbox.height) / DRAWING_SCALE);
              break;
            case GUESS_WINDOW_OR_DOOR_HEIGHT_FUNCTION:
              // either height from rectangle or can't guess from line
              done((pixelBbox.width && pixelBbox.height) ? (pixelBbox.height) / DRAWING_SCALE : 0);
              break;
            case GUESS_WINDOW_OR_DOOR_WIDTH_FUNCTION:
              // either width from rectangle or length from line
              done(
                (pixelBbox.width && pixelBbox.height)
                  ? (pixelBbox.width) / DRAWING_SCALE
                  : Math.max(pixelBbox.width, pixelBbox.height) / DRAWING_SCALE
              );
              break;
          }
          break;
        case AVERAGE_HEIGHT_FUNCTION:
          if (node.drawingRoot?.pointsExceptCountPoints?.length !== 4) {
            done(0);
          } else {
            const pointsSortedByY = node.drawingRoot.pointsExceptCountPoints.slice(0).sort((a, b) => a.y - b.y);
            // y axis positive is down
            const bottomPointsSortedByX = [pointsSortedByY[0], pointsSortedByY[1]].sort((a, b) => a.x - b.x);
            const topPointsSortedByX = [pointsSortedByY[2], pointsSortedByY[3]].sort((a, b) => a.x - b.x);
            const averagePixelHeight = (
              topPointsSortedByX[0].y - bottomPointsSortedByX[0].y +
              topPointsSortedByX[1].y - bottomPointsSortedByX[1].y
            ) / 2;

            done(averagePixelHeight / DRAWING_SCALE);
          }
          break;
        case CORNERS_QUANTITY_FUNCTION:
          // related shapes todo!

          done(node.shapesCornersQuantity.cornersQuantity);
          break;

        // Not sure we will use OPENINGS after all, easier to prepare tree to subtract by formula
        case OPENINGS_SURFACE_FUNCTION:
          // related shapes todo!

          done(sumBy(node.surfaceHoles, hole => hole?.surface || 0));
          break;
        case OPENINGS_TOTAL_WIDTH_FUNCTION:
          // related shapes todo!

          done(sumBy(node.surfaceHoles, hole => getBoundingBox(hole?.points || []).width) / DRAWING_SCALE);
          break;
        case OPENINGS_TOTAL_PERIMETER_FUNCTION:
          // related shapes todo!

          done(sumBy(node.surfaceHoles, hole => hole?.perimeter));
          break;
        case OPENINGS_TOTAL_SIDES_FUNCTION:
          // related shapes todo!

          done(sumBy(node.surfaceHoles, hole => getBoundingBox(hole?.points || []).height) * 2 / DRAWING_SCALE);
          break;

        case OPENINGS_TOTAL_TOP_BOTTOM_FUNCTION:
          // related shapes todo!
          done(sumBy(node.surfaceHoles, hole => {
            if (hole.points.length < 2) {
              return 0;
            }

            return getBoundingBox(hole?.points || []).width * (
              // if last point repeats on first point, assume closed hole (ie. window), so count top+bottom
              // if not, door, calculate only top (1x width)
              hole.points[0].distance(last(hole.points), DRAWING_SCALE) <= 0.05
                ? 2
                : 1
            ) / DRAWING_SCALE;
          }));
          break;

        case OPENINGS_QUANTITY_FUNCTION:
          // related shapes todo!
          done(node.surfaceHoles.length);
          break;

        case SURFACES_QUANTITY_FUNCTION:
          // dont highlight any shapes, more of a utility function
          done(node.surfaceShapes.length);
          break;

        case SURFACES_PERIMETER_FUNCTION:
          // dont highlight shape, until we can highlight perimeter of surface
          done(sumBy(node.surfaceShapes, surface => surface.perimeter));
          break;

        case RECTANGLES_TOPS_FUNCTION:
        case RECTANGLES_SIDES_FUNCTION:
        case RECTANGLES_BOTTOMS_FUNCTION:
          lines = node.lineShapes.filter(line => line.tags.includes(
            name === RECTANGLES_TOPS_FUNCTION && ShapeTags.RectangleTop ||
            name === RECTANGLES_SIDES_FUNCTION && ShapeTags.RectangleSide ||
            name === RECTANGLES_BOTTOMS_FUNCTION && ShapeTags.RectangleBottom
          ));
          lines.forEach(shape => relatedShapesIds.add(shape.id));

          done(sumBy(lines, 'length'));
          break;

        case SHAPES_TOPS_FUNCTION:
        case SHAPES_SIDES_FUNCTION:
        case SHAPES_BOTTOMS_FUNCTION:

          lines = node.lineShapes.filter(line =>
            // not sure exaclty why that works, but seems to work the majority of the time
            name === SHAPES_TOPS_FUNCTION && Math.abs(round(line.angleDegrees, 0)) < 90 ||
            name === SHAPES_SIDES_FUNCTION && Math.abs(round(line.angleDegrees, 0)) === 90 ||
            name === SHAPES_BOTTOMS_FUNCTION && Math.abs(round(line.angleDegrees, 0)) > 90
          );
          lines.forEach(shape => relatedShapesIds.add(shape.id));

          done(sumBy(lines, 'length'));
          break;

        case EXTREMITIES_QUANTITY_FUNCTION:
          // todo related shapes
          done(node.shapesCornersQuantity.extremitiesQuantity);
          break;

        case WEIGHT_FROM_TASKS_FUNCTION:
          done(
            sumBy(
              this.treeNode.tasks.filter(task => task.measurementId !== this.measurementId),
              task => {
                const weightProvidedQuantity = task.providingItem?.providedQuantities.get(UnitType.Weight);

                if (!weightProvidedQuantity) {
                  return 0;
                }

                return task.roundedQuantity * MeasurementConverter.convert(
                  weightProvidedQuantity.quantity,
                  weightProvidedQuantity.unit,
                  Unit.DefaultMetric
                )
              }
            )
          );
          break;

        case SURFACE_FROM_TASKS_FUNCTION:
          done(
            sumBy(
              this.treeNode.tasks.filter(task => task.measurementId !== this.measurementId),
              task => {
                const surfaceProvidedQuantity = task.providingItem?.providedQuantities.get(UnitType.Surface);

                if (!surfaceProvidedQuantity) {
                  return 0;
                }

                return task.roundedQuantity * MeasurementConverter.convert(
                  surfaceProvidedQuantity.quantity,
                  surfaceProvidedQuantity.unit,
                  Unit.DefaultMetric
                )
              }
            )
          );
          break;

        case 'INCHES':
        case i18n.t('INCHES'): // need constants
          done(MeasurementConverter.convert(params[0], Unit.Inch, Unit.Meter));
          break;
        case 'FEET':
        case i18n.t('FEET'):
          done(MeasurementConverter.convert(params[0], Unit.Foot, Unit.Meter));
          break;
        case 'CUBIC_YARD':
        case i18n.t('CUBIC_YARD'):
          done(MeasurementConverter.convert(params[0], Unit.CubicYard, Unit.CubicMeter));
          break;
        case 'SQFT':
        case i18n.t('SQFT'):
          done(MeasurementConverter.convert(params[0], Unit.SquareFoot, Unit.SquareMeter));
          break;
        case 'DEGREES':
        case i18n.t('DEGREES'):
          done(MeasurementConverter.convert(params[0], Unit.Degree, Unit.Radian));
          break;
      }
    });

    if ((
      this.treeNode?.isParentExcludedFromReports ||
      this.treeNode?.shouldExcludeFromReports
    ) && !this.stores?.userInfoStore?.user?.disableExcludedNodesNewCalculations) {
      return [0, []];
    }

    const result = parser.parse(
      preparestring(this.formula)
    )['result'];

    const metricResult = formulaHasVariables(this.formula)
      ? result
      // if formula is 100 and unit is cm, assume result is 100 cm
      // in other cases (with variables), we assume all units to be default metric
      : MeasurementConverter.convert(result, this.measurement.displayUnit, Unit.DefaultMetric);

    const metricResultWithAdjustment = this.applyAdjustment(metricResult, this.adjustment);

    const metricResultWithAdjustmentAndMultiplier = this.measurement?.shouldApplyTreeNodeQuantitiesMultiplier
      ? metricResultWithAdjustment * this.treeNode?.quantitiesMultiplier
      : metricResultWithAdjustment;

    return [
      metricResultWithAdjustmentAndMultiplier,
      this.stores?.userInfoStore?.user?.canTrackRelatedShapes ? [...relatedShapesIds] : []
    ];
  }

  applyAdjustment(metricBaseValue: number, adjustment: Adjustment = null): number {
    if (!adjustment) {
      return metricBaseValue;
    }

    const metricAdjustmentValue = MeasurementConverter.convert(adjustment.value, this.measurement.displayUnit, Unit.DefaultMetric);

    // this return measurement value adjusted for treeNode, but we can still have task adjustments later on
    switch (adjustment.subtype) {
      case AdjustmentType.Add:
        return metricBaseValue + metricAdjustmentValue;
      case AdjustmentType.Subtract:
        return metricBaseValue - metricAdjustmentValue;
      case AdjustmentType.Multiply:
        return metricBaseValue * adjustment.value / 100;
    }
  }

  // order by formula type
  @computed get index(): FormulaType {
    // first ownMeasurement formula
    return getFormulaType(this.firstDescendantFormula, this.metricValue);
  }

  // helper for clarity
  @computed get formulaType(): FormulaType {
    return this.index;
  }

  constructor(stores: Stores, treeNode: TreeNode, measurement: Measurement, formula: string = '') {
    super(stores);

    this._formula = DEFAULT_FORMULA;

    if (treeNode) {
      this.treeNode = treeNode;
    }
    if (measurement) {
      this.measurement = measurement;
      // assign to all languages of formula (as a copy)
      // __formula is the i18n object and so is __defaultFormula
      this.__formula = Object.assign({}, measurement.__defaultFormula);
      if (formula) {
        this._formula = formula;
      }
    }
    if (!isEmpty(formula)) {
      this.formula = formula;
    }
  }
}
