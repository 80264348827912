import { UnitType } from 'constants/UnitType';
import { compact, first, flatten, isEmpty } from 'lodash';
import { computed, observable } from "mobx";
import ModelBase from "models/ModelBase";
import Point from "models/Point";
import { list, object, primitive, serializable } from "serializr";
import Stores from 'stores/Stores';
import { getBoundingBox, getPathString, getPathStringReversed } from 'utils/ShapeUtil';
// circular dep used only for type: import { Rectangle } from './Rectangle';
import TreeNode from "./TreeNode";

// the idea is that we do all aggregation of surface and lengths
// inside treeNodes which can hold 1 shape each. So this shape
// woudn't need to know about child/parent shapes
export default class Shape extends ModelBase {
  @serializable type = 'Shape';

  @observable @serializable(list(primitive())) tags = [];

  @observable @serializable(list(object(Point))) points: Point[] = [];

  @observable _treeNode: TreeNode;

  @computed get treeNode() {
    return this._treeNode || this.stores.treeNodesStore.nodesByShapeId[this.id];
  }

  set treeNode(value) {
    this._treeNode = value;
  }

  // should use css cascade instead of bubbleancestors
  @computed get legendColor(): string {
    const { userInfoStore } = this.stores;
    const { user } = userInfoStore;

    let color = null;

    if (!this.treeNode) {
      return null;
    }

    // find first ancestor with color item
    [this.treeNode, ...this.treeNode.bubbleAncestors].forEach(treeNode => {
      if (color) {
        return;
      }

      const measurementValues = treeNode?.ownMeasurementValuesArray?.filter(mv =>
        // this will be too slow when a lot of shapes / ancestors!!
        (!user.canTrackRelatedShapes || (isEmpty(mv.relatedShapesIds) || mv.relatedShapesIds.includes(this.id))) && (
          (
            mv.measurement?.unitType === UnitType.Surface && this.type === 'Surface' ||
            mv.measurement?.unitType === UnitType.Length && this.type === 'Wall' ||
            mv.measurement?.unitType === UnitType.Unit && this.type === 'CountPoint'
          )/* && mv.metricValue !== 0 */  // cant calculate every value of the project just to get a color, will be too slow
        )) || [];

      // unit measurement type used as last resort
      measurementValues.sort((a, b) => (a.measurement?.unitType === UnitType.Unit ? 0 : 1) - (b.measurement?.unitType === UnitType.Unit ? 0 : 1));

      color = first(compact(
        measurementValues.map(mv => mv.measurement?.legendColor) || []
      )) || first(compact(
        measurementValues.map(mv => mv.measurement?.subcategory?.legendColor) || []
      )) || first(compact(
        measurementValues.map(mv => mv.measurement?.category?.legendColor) || []
      ));
    });

    return color;
  }

  @computed get isVisible() {
    return !!this.treeNode?.isVisible;
  }

  @computed
  get pathString(): string {
    return getPathString(this.points);
  }

  @computed
  get pathStringReversed(): string {
    return getPathStringReversed(this.points);
  }

  constructor(stores: Stores, points: Point[] = []) {
    super(stores);
    this.points = points;
  }

  addPoint(pt: Point) {
    this.points.push(pt);
  }

  intersects(path: Shape): boolean {
    throw new Error('not implemented');
  }

  contains(path: Shape): boolean {
    throw new Error('not implemented');
  }

  static getPoints(shapes: Shape[]) {
    return flatten(shapes.map(shape => [...shape.points]));
  }

  @computed get length(): number {
    // implemented in child class
    return 0;
  }

  @computed get surface(): number {
    // implemented in child class
    return 0;
  }

  @computed get boundingBox()/*: Rectangle*/ {
    return getBoundingBox(this.points, false);
  }
}