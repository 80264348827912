import firebase from 'firebase/compat/app';
import { computed, observable } from "mobx";
import { object, serializable } from "serializr";
import ModelBaseWithCategory from "./ModelBaseWithCategories";
import { Rectangle } from './Rectangle';

// this will be created by backend
export default class BackgroundImage extends ModelBaseWithCategory {
  type = 'BackgroundImage';

  // imageUrl and thumbUrl // already exists in ModelBase
  @serializable @observable svgUrl = '';

  @computed get storageRef(): firebase.storage.Reference {
    return this.imageUrl
      ? firebase.storage().refFromURL(this.imageUrl)
      : null;
  }

  @serializable @observable parentDocumentName = '';

  @serializable @observable unscaledWidth = 640;
  @serializable @observable unscaledHeight = 640;

  @serializable @observable isPageScale = false;
  @computed get isScaleSet() {
    return !!(this.isPageScale || this.cropRectangle);
  }

  // for crop
  @serializable(object(Rectangle)) @observable cropRectangle: Rectangle = null;

  // for manual scales according to print sizes
  @serializable @observable initialViewportScale = 0;

  // pixels per inch
  @computed get DPI() {
    return 72 * this.initialViewportScale;
  }

  // architectural scale
  @serializable @observable scale = 1;

  // degrees
  @serializable @observable rotation = 0;

  @computed get width() {
    return this.unscaledWidth * this.scale;
  }

  @computed get height() {
    return this.unscaledHeight * this.scale;
  }

  @computed get isLandscape() {
    return (this.rotation === 90 || this.rotation === 270)
      ? this.height > this.width
      : this.width > this.height;
  }
}