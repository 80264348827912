import { Button } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import ProvidingItemComponent from 'components/common/ProvidingItemComponent/ProvidingItemComponent';
import RichText2 from 'components/common/RichText2/RichText2';
import ShapePreview from 'components/common/ShapePreview/ShapePreview';
import { ProvidingItemQuantityBehaviour, ProvidingItemSubtype } from 'constants/ProvidingItemConstants';
import { ReportGrouping, ReportHeaderMeasurementsVisibility, ReportTasksFilter, ReportTasksVisibility, ReportTotalsGrouping, ReportTotalsVisibility, SAMPLE_REPORT_NUM_TASKS } from 'constants/ReportOptionsConstants';
import { UnitType } from 'constants/UnitType';
import { compact, isEmpty, last, max, sumBy, uniqBy } from 'lodash';
import Report from 'models/Report';
import Task from 'models/Task';
import * as React from 'react';
import { SizeMe } from 'react-sizeme';
import sanitizeHtml from 'sanitize-html';
import MeasurementFormatter from 'utils/MeasurementFormatter';
import { measurementHasDefaultName } from 'utils/MeasurementUtil';
import { formatCurrency } from 'utils/NumberFormatter';
import { CURRENCY_EXCEL, chunkArray, getTaskDescriptionColspan, isFilterAffectingTotal } from 'utils/ReportUtil';
import { findCommonPath } from 'utils/TreeUtil';
import { formatUnit, getUnitTypeForUnit } from 'utils/UnitFormatter';
import { round } from 'utils/Utils';
import i18n from 'utils/i18n';
import ReportHeaderRow from '../ReportHeaderRow';
import { ReportBodyRow, ReportRowsGroup } from '../ReportInterfaces';


const styles = require('./ReportContentList.module.scss');

interface IReportContentListProps {
  isSampleList?: boolean,
  report: Report,
}

interface IReportContentTaskProps {
  report: Report,
  rows: ReportBodyRow[],
  firstLineNumber: number,
}

class ReportContentTasks extends ObserverComponent<IReportContentTaskProps> {


  goToEditTask = (task: Task) => {
    return;
    //wip
    const { routerStore, treeNodesStore, tasksStore, providingItemsStore } = this.context;

    treeNodesStore.selectedTreeNode = task.treeNode;
    tasksStore.taskToHighlight = task;

    routerStore.goToProjectView();
  }

  _render() {
    const { userInfoStore, reportsStore, tasksStore, projectsStore } = this.context;
    const { rows, firstLineNumber, report } = this.props;
    const { shouldShowQuantities, shouldShowTaskCost, shouldShowTaskUnitPrice } = report;
    const logoColor = report?.logoColors?.color1 || 'rgb(24,39,53)';
    const logoLighterColor = logoColor.replace(')', ',0.03)');

    const { user } = userInfoStore;

    if (!user) {
      return null;
    }

    const { shouldShowTimeInReports, reportThumbsSize } = user;

    let lineNumber = firstLineNumber;

    let { shouldShowProvidingItemsIcons, shouldHighlightPriceChanges } = reportsStore.userReport || report;

    if ([ReportTasksFilter.HideMaterial, ReportTasksFilter.LabourOnly].includes(report.tasksFilter)) {
      shouldShowProvidingItemsIcons = false;
    }

    const nodesWithNotes = uniqBy(rows.map(r => r.task?.treeNode), 'id').filter(treeNode => sanitizeHtml(treeNode.description2, { allowedTags: [] }));
    const shouldDisplayInlineNotes = (
      !user.shouldNeverInlineNotesInReport &&
      report.shouldShowNotes &&
      report.isGroupedByTreeNodeFirst && (
        report.grouping === ReportGrouping.TreeNode ||
        last(rows)?.task?.treeNode !== report.visibleReportBodyRows[report.visibleReportBodyRows.findIndex(row => row === last(rows)) + 1]?.task?.treeNode
      )
    );

    return !isEmpty(rows) && (
      <tbody style={{ backgroundColor: logoLighterColor }}>
        {compact(rows.map(row => {
          const { task, indentLevel } = row;
          const { providingItem } = task;

          const isFixedPrice = task.measurement.unitType === UnitType.FixedPrice;
          // eventually display budget and fixed rate differently and fix Fixed Price vs Budgetary price confusion 
          const isBudgetPrice = task.measurement.name === i18n.t('Fixed Price');

          let quantityString = (task.displayQuantity || 0).toLocaleString(i18n.locale, {
            maximumFractionDigits: 2 // should use same logic as in MeasurementFormatter
          });

          if (isFixedPrice) {
            quantityString += ' ' + formatUnit(task.measurement.displayUnit);
          }

          const isHiddenQuantity = (
            (isFixedPrice && shouldShowTaskCost) || (
              !shouldShowTimeInReports &&
              getUnitTypeForUnit(task.unit) === UnitType.Time
            ) || (
              task.providingItem?.reportQuantityBehaviour !== ProvidingItemQuantityBehaviour.AlwaysShow &&
              report.tasksVisibility !== ReportTasksVisibility.ShowQuantities
            ) || task.providingItem?.reportQuantityBehaviour === ProvidingItemQuantityBehaviour.AlwaysHide
          );

          const taskLocation = findCommonPath
            (compact(
              (task.isMerged ? Array.from(task.mergedOriginalTasksIds) : [task.id])
                .map(taskId => tasksStore.getItem(taskId)?.treeNode)
            ));

          return task.isSeparator
            ? (
              <tr className={classnames(
                styles.separator,
                styles[`indentLevel${indentLevel}`],
              )} key={task.id}>
                <td colSpan={4} style={{ textAlign: 'left' }}>
                  <div>
                    {task.description}
                  </div>
                </td>
              </tr>
            ) : (
              <tr
                className={classnames(
                  styles.task,
                  styles[`indentLevel${indentLevel}`],
                )}
                key={task.id}
                onClick={() => task && !task.isMerged && this.goToEditTask(task)}
              >
                {/*<td style={{ display: 'none' }} data-v={task?.providingItemId?.split(':')?.[1]}/>*/}
                <td data-v={(task?.providingItemDisplayName || '')/* + ' ' + task.secondaryTextWithMeasurementName*/} className={styles.providingItemCell} colSpan={getTaskDescriptionColspan(report)}>
                  <div className={styles.flexContainer}>
                    <div className={styles.lineNumber}>{lineNumber++}.</div>
                    <ProvidingItemComponent
                      className={classnames(styles.providingItem, { [styles.shouldShowIcons]: shouldShowProvidingItemsIcons })}
                      providingItem={providingItem}
                      parentTask={task}
                      isReportView
                      shouldHideMeasurementName={report.tasksVisibility === ReportTasksVisibility.HideQuantities && providingItem.reportQuantityBehaviour !== ProvidingItemQuantityBehaviour.AlwaysShow}
                      imageSize={{ w: reportThumbsSize, h: reportThumbsSize }}
                    />
                  </div>
                </td>
                {shouldShowQuantities && (
                  <td data-v={isHiddenQuantity ? 1 : task.displayQuantity} className={styles.measurement}>
                    {isHiddenQuantity ? '' : quantityString}
                  </td>
                )}
                {shouldShowQuantities && (
                  <>
                    <td data-v={task.unitPriceWithOrWithoutFees} data-z={CURRENCY_EXCEL} className={styles.unitPrice}>
                      {shouldShowTaskUnitPrice && !isHiddenQuantity && (<>
                        <span className={classnames({
                          [styles.updatedInLastPriceUpdate]: shouldHighlightPriceChanges && providingItem.priceUpdatedMiliseconds >= report.highlightPriceChangeDateMiliseconds.getTime()
                        })}>
                          {formatCurrency(task.unitPriceWithOrWithoutFees)}
                        </span>
                        <span>
                          &nbsp;/&nbsp;
                        </span>
                      </>)}
                      <span>
                        {
                          isFixedPrice
                            ? (measurementHasDefaultName(task.measurement) ? i18n.t('Fixed Price') : task.measurement.name)
                            : (isHiddenQuantity ? '' : formatUnit(task.unit))
                        }
                      </span>
                    </td>
                    <td style={{ display: 'none' }} data-v={isHiddenQuantity ? (isFixedPrice ? i18n.t('Fixed Price') : '') : formatUnit(task.unit)}></td>
                    <td style={{ display: 'none' }} data-v={round(task.hours, 2)}></td>
                  </>
                )}
                {shouldShowTaskCost && (
                  <td data-v={task.priceWithOrWithoutFees} data-z={CURRENCY_EXCEL} className={styles.taskPrice}>
                    <span style={{ visibility: task.priceWithOrWithoutFees ? 'visible' : 'hidden' }}>{formatCurrency(task.priceWithOrWithoutFees)}</span>
                  </td>
                )}

                {/* DEBUG 
                <td style={{ display: 'none' }} data-v={task.price}></td>  
                <td style={{ display: 'none' }} data-v={task.priceWithFees}></td>  
                {Array.from(task.feesValues.values()).map((val, index) => (
                  <td style={{ display: 'none' }} data-v={val} key={`fee${index}`}></td>  
                ))}
                */}

                <td style={{ display: 'none' }} data-v={task.providingItem?.merchant?.domain}></td>
                <td style={{ display: 'none' }} data-v={task.providingItem?.merchantSku}></td>

                <td style={{ display: 'none' }} data-v={task.category?.name}></td>
                <td style={{ display: 'none' }} data-v={taskLocation}></td>
                <td style={{ display: 'none' }} data-v={
                  i18n.t(task.providingItem?.subtype === ProvidingItemSubtype.Labour ? i18n.t('Labour & misc.') : (task.providingItem?.subtype || ''))
                }></td>
                <td style={{ display: 'none' }} data-v={task.providingItem?.category?.name}></td>
                <td style={{ display: 'none' }} data-v={task.providingItem?.subcategory?.name}></td>

                <td style={{ display: 'none' }} data-f={`=HYPERLINK("${task.providingItem?.url}")`}></td>
              </tr>
            );
        }))
        }
        <tr data-xlsx-hidden className={styles.spacingTasksRow}><td colSpan={4}>&nbsp;</td></tr>

        {shouldDisplayInlineNotes && nodesWithNotes.map(node => (
          <div key={'note' + node.id} style={{ display: 'table-row' }} className={styles.inlineNotes}>
            <td colSpan={4}>
              <div>
                <div>{i18n.t('Notes')}</div>
                <RichText2 key={node.id} className={styles.richText} model={node} property={'description2'} />
              </div>
            </td>
          </div>
        ))}
      </tbody>
    );
  }
}

interface IReportContentListState {
  shouldShowLongSampleList: boolean,
};

export default class ReportContentList extends ObserverComponent<IReportContentListProps, IReportContentListState> {
  static defaultProps = {
    isSampleList: false,
  };

  state = {
    shouldShowLongSampleList: false,
  }

  _render() {
    const { settingsStore, userInfoStore } = this.context;
    const { isSampleList, report } = this.props;
    const { shouldShowLongSampleList } = this.state;
    const { totalsVisibility, totalsGrouping, shouldShowTasks, shouldShowQuantities, shouldShowTaskCost, shouldShowTaskUnitPrice, tasksFilter, shouldShowReportTimeTotals } = report;

    if (!report) {
      return null;
    }

    const { headerMeasurementsVisibility, visibleReportRows } = report;
    const reportRowsGroups: ReportRowsGroup[] = visibleReportRows;


    let lineNumber = 1;
    const totalLinesCount = sumBy(reportRowsGroups, group => group.rows.length);

    const shouldShowSubtotalOnly = totalsGrouping === ReportTotalsGrouping.SubtotalOnly || isFilterAffectingTotal(tasksFilter);

    const nameThColspan = [ReportTotalsVisibility.Project, ReportTotalsVisibility.None].includes(totalsVisibility)
      ? 4
      : shouldShowSubtotalOnly
        ? 3
        : 1;

    return (
      <>
        <style>
          {`.ReportContentList tr.continued > th:first-child::after {
              content: ' (${i18n.t('continued')})';
            }
          `}

          {totalsVisibility === ReportTotalsVisibility.GroupNotSubgroup && `
            tr:not(.isEmphasized) .headerSubtotal {
              color: transparent !important;
            }
          `}
        </style>

        <table className={classnames(
          'ReportContentList',
          styles.mainReportContentList,
          styles.root,
          {
            [styles.shouldShowTaskUnitPrice]: shouldShowTaskUnitPrice,
            [styles.hasTasks]: shouldShowTasks,
          }
        )}>
          {
            reportRowsGroups
              .map((group, groupIndex) => {
                const { type, rows } = group;
                const firstLineNumber = lineNumber;

                if (type === 'tbody') {
                  lineNumber += rows.length;
                }

                if (isSampleList && !shouldShowLongSampleList && firstLineNumber > SAMPLE_REPORT_NUM_TASKS) {
                  return null;
                }



                return type === 'thead'
                  ? (
                    <thead className="keep-together" key={`thead${groupIndex}`}>
                      {rows.map((row, index) => {
                        const { indentLevel, name, subtotalMaterial, subtotalLabour, subtotal, isEmphasized, isSemiEmphasized, measurementsToDisplay, subtotalHours } = row as ReportHeaderRow;

                        const shouldShowHeaderMeasurements = (
                          headerMeasurementsVisibility != ReportHeaderMeasurementsVisibility.Hidden &&
                          !isEmpty(measurementsToDisplay)
                        );

                        const measurementNameMaxLength = (max(measurementsToDisplay?.map?.(m => m.measurement?.name?.length || 0)) || 0) * (userInfoStore.user?.reportFontSizeScale || 1);
                        const measurementsToDisplayChunks = measurementNameMaxLength > 26
                          ? (
                            measurementNameMaxLength > 50
                              ? 1
                              : 2
                          ) : 3;

                        return (<React.Fragment key={'rf' + index}>
                          <tr
                            {...(shouldShowTasks ? { ['data-xlsx-hidden']: true } : {})}
                            className={classnames(
                              styles.groupRow,
                              styles[`indentLevel${indentLevel}`],
                              {
                                [styles.isSemiEmphasized]: isSemiEmphasized,
                                'isSemiEmphasized': isSemiEmphasized,
                                [styles.isEmphasized]: isEmphasized,
                                'isEmphasized': isEmphasized
                              })}
                            key={'rftr' + index}
                          >
                            <th colSpan={nameThColspan} className={styles.groupName} >
                              {name}
                            </th>
                            {shouldShowQuantities && <th style={{ display: 'none' }} />}

                            {(![ReportTotalsVisibility.Project, ReportTotalsVisibility.None].includes(totalsVisibility)) && (<>
                              {!shouldShowSubtotalOnly && (
                                <>
                                  <th className="headerSubtotal" data-v={subtotalMaterial} data-z={CURRENCY_EXCEL}>
                                    <span>{i18n.t('Material')}: </span>
                                    <span>{formatCurrency(subtotalMaterial)}</span>
                                  </th>
                                  <th className="headerSubtotal" data-v={subtotalLabour} data-z={CURRENCY_EXCEL}>
                                    <span>{i18n.t('Labour & misc.')} {shouldShowReportTimeTotals && ` (${round(subtotalHours, 1)} h.)`} : </span>
                                    <span>{formatCurrency(subtotalLabour)}</span>
                                  </th>
                                </>
                              )}
                              <th className="headerSubtotal" data-v={subtotal} data-z={CURRENCY_EXCEL}>
                                <span>{(shouldShowSubtotalOnly && !shouldShowTasks && !shouldShowReportTimeTotals)
                                  ? ''
                                  : (i18n.t('Subtotal') + (shouldShowReportTimeTotals ? ` (${round(subtotalHours, 1)} h.)` : '') + ' : ')}
                                </span>
                                <span>{formatCurrency(subtotal)}</span>
                              </th>
                            </>)}
                          </tr>
                          {shouldShowHeaderMeasurements && (<>

                            {/*<tr
                            className={classnames(
                              styles.roomMeasurements,
                              styles[`indentLevel${indentLevel}`],
                            )}>
                            <th colSpan={4}>
                              Propriétés
                            </th>
                            </tr>*/}

                            {/* Using fake row because tr is set to be always keep together (see keepTogether() in kendo html/core.js) */}
                            <div
                              style={{ display: 'table-row' }}
                              className={classnames(
                                styles.roomMeasurements,
                                styles[`indentLevel${indentLevel}`],
                                'fake-spacing'
                              )}
                              key={'rfdiv' + index}
                            >
                              <th colSpan={4} data-v="">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {row.node && !isEmpty(row.node.shapes) && headerMeasurementsVisibility !== ReportHeaderMeasurementsVisibility.VisibleWithoutDrawing && (
                                    <>
                                      <SizeMe monitorHeight monitorWidth refreshMode="debounce" refreshRate={300} noPlaceholder>
                                        {({ size }) => (
                                          <div>
                                            <ShapePreview
                                              className={styles.shapePreview}
                                              width={150}
                                              // max height 150
                                              height={row.node.boundingBox.width > row.node.boundingBox.height ? undefined : 150}
                                              shouldShowDimensions
                                              shouldInlineStyles
                                              treeNode={row.node}
                                              shapes={row.node.shapes}
                                              containerWidth={size.width}
                                              containerHeight={size.height}
                                            />
                                          </div>
                                        )}
                                      </SizeMe>
                                      <div style={{ width: 15, height: 10 }} />
                                    </>
                                  )}

                                  <div style={{ display: 'table' }}>
                                    {chunkArray(measurementsToDisplay, measurementsToDisplayChunks).map((measurementsRow, index) => (
                                      <div style={{ display: 'table-row' }} key={`mr_${index}`}>
                                        {measurementsRow.filter(mv => mv.measurement).map((mv, index) => (
                                          <React.Fragment key={'mv' + index}>
                                            <td>
                                              {mv.measurement.name}
                                              {(row.node.quantitiesMultiplier !== 1 && mv.measurement?.isSummable)
                                                ? (<>&nbsp;(<span style={{ fontStyle: 'normal', fontSize: '1.3em', verticalAlign: 'text-top' }}>×</span>{row.node.quantitiesMultiplier}&nbsp;{i18n.t('un.')})</>)
                                                : ''}
                                            </td>
                                            <td>{MeasurementFormatter.format(mv)}</td>
                                          </React.Fragment>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </th>
                            </div>
                          </>)}
                          <tr key={'rftrhidden' + index} data-xlsx-hidden className={styles.spacingHeaderRow}><th>&nbsp;</th><th /><th /><th /> {/* not using colspan, because causing merged cells */}</tr>
                        </React.Fragment>
                        );
                      })}
                      {!isEmpty(rows) && shouldShowTasks && (
                        <tr {...(groupIndex !== 0 ? { ['data-xlsx-hidden']: true } : {})} className={classnames(styles.tasksHeader, styles[`indentLevel${last(rows).indentLevel}`])}>
                          <th colSpan={getTaskDescriptionColspan(report)} className={styles.providingItemCell}>{i18n.t('Description')}</th>
                          {shouldShowQuantities && <th>{i18n.t('Quantity')}</th>}
                          {shouldShowQuantities && <th className={styles.unitPrice}>{shouldShowTaskUnitPrice ? i18n.t('Rate') : i18n.t('Units')}</th>}
                          {shouldShowQuantities && <th style={{ display: 'none' }}>{i18n.t('Units')}</th>}
                          {shouldShowQuantities && <th style={{ display: 'none' }}>{i18n.t('Hours')}</th>}
                          {shouldShowTaskCost && <th>{i18n.t('Total')}</th>}
                          <th style={{ display: 'none' }} data-v={i18n.t('Supplier')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('SKU')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Task Category')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Task Location')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Item Subtype')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Item Category')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Item Subcategory')}></th>
                          <th style={{ display: 'none' }} data-v={i18n.t('Url')}></th>
                        </tr>
                      )}

                    </thead>
                  ) : (
                    <ReportContentTasks
                      key={`rct${groupIndex}`}
                      rows={(isSampleList && !shouldShowLongSampleList && SAMPLE_REPORT_NUM_TASKS < lineNumber)
                        ? rows.slice(0, SAMPLE_REPORT_NUM_TASKS - firstLineNumber)
                        : rows
                      }
                      firstLineNumber={firstLineNumber}
                      report={report}
                    />
                  );
              })}
        </table>
        {isSampleList && totalLinesCount > SAMPLE_REPORT_NUM_TASKS && (
          <Button key={'buttonexpand'} onClick={() => this.setState({ shouldShowLongSampleList: !shouldShowLongSampleList })}>
            {shouldShowLongSampleList
              ? (<>
                <ExpandLessIcon />
                {i18n.t('Show less')}
              </>) : (<>
                <ExpandMoreIcon />
                {i18n.t('Show more tasks')}
              </>)}
          </Button>
        )}
      </>
    );
  }

}