import { DbLocationType } from 'constants/DbLocationType';
import { defer, groupBy, maxBy } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import Category from 'models/Category';
import Measurement from 'models/Measurement';
import ProvidingItem from 'models/ProvidingItem';
import Task, { TaskSubtypes } from 'models/Task';
import { getMeasurementsWithDependencies } from 'utils/MeasurementUtil';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import SearchableFirebaseStore from './SearchableFirebaseStore';

// not really searchable for now, would need to search by measurement.name & item.name
// but useful to get items by categs
export default class TasksStore extends SearchableFirebaseStore<Task> {
  storeKey = 'tasks';

  dbLocationsTypes = new Set([
    DbLocationType.MasterProject,
    DbLocationType.Project
  ]);

  @observable taskBeingEdited: Task = null;
  @observable taskToHighlight: Task = null;
  @observable isSelectingWithCheckboxes = false;
  @observable isTaskMeasurementColumnVisible = true;

  tasksComponentRef = null;

  @computed get roundableTasksByProvidingItem() {
    return groupBy(this.items.filter(task => task.shouldRound && task.providingItem), 'providingItem.id');
  }

  @computed get providingItemsIdsUsedInProject(): Set<string> {
    return new Set(this.items.map(task => task.providingItem?.id));
  }

  // problem is tasks are sorted globally instead of by node/category
  sortField = 'index';

  resetScrollOnNodeChange = reaction(() => (
    this.stores?.treeNodesStore?.selectedTreeNodeId
  ), () => {
    if (this.tasksComponentRef) {
      this.tasksComponentRef.scrollTo(0);
    }
  });

  addTask = (category: Category, subtype = TaskSubtypes.Default) => action(() => {
    const { treeNodesStore, dragAndDropStore, tasksStore, categoriesStore, providingItemsStore, measurementsStore, userInfoStore } = this.stores;
    const { selectedTreeNode } = treeNodesStore;
    const { dragObject } = dragAndDropStore;
    const newTask = new Task(this.stores);
    newTask.subtype = subtype;
    newTask.category = category;
    newTask.index = (getSafe(() => maxBy(treeNodesStore.selectedTreeNode.tasks, task => task.index).index) || 0) + 1;

    if (subtype === TaskSubtypes.Separator) {
      newTask.description = i18n.t('Nouveau séparateur');
    }

    if (dragObject instanceof Measurement) {
      newTask.measurement = dragObject as Measurement;
      measurementsStore.ensureSavedInProjectCollection(getMeasurementsWithDependencies([newTask.measurement]));

      if (
        userInfoStore.user.canUseProvidingItemsAsMeasurements && 
        newTask.measurement.providingItemMeasurementId
      ) {
        newTask.providingItemId = newTask.measurement.providingItemMeasurementId;
      }
    }

    if (dragObject instanceof ProvidingItem) {
      const providingItem = dragObject as ProvidingItem;
      newTask.providingItem = providingItem;
      providingItemsStore.ensureSavedInProjectCollection([providingItem]);
    }

    tasksStore.addEditItem(newTask);
    selectedTreeNode.ownTasksIds.push(newTask.id);
    treeNodesStore.addEditItem(selectedTreeNode);

    categoriesStore.collapsedTaskCategories.delete(category);

    const newTaskIndex = treeNodesStore.selectedNodeOwnExpandedTasksByCategFlattened.findIndex(row => row.item?.id === newTask.id);

    dragAndDropStore.dragObject = null;

    defer(() => { 
      tasksStore.tasksComponentRef.scrollToItem(newTaskIndex);
    });
  })

  /* override */ attemptLoadItems() {
    if (this.isLoading) {
      return;
    }

    if (
      !this.stores.treeNodesStore.isReady ||
      //  !this.stores.measurementsStore.isReady ||
      !this.stores.commonStore.selectedProjectId
    ) {
      return;
    }

    super.attemptLoadItems();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.treeNodesStore?.isReady &&
    //this.stores?.measurementsStore?.isReady &&
    this.stores?.commonStore.selectedProjectId
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}

