import { Button, Portal, Slide, Snackbar } from '@material-ui/core';
import classnames from 'classnames';
import ObserverComponent from 'components/common/ObserverComponent';
import { AddDrawingTool } from 'components/drawing/AddDrawingTool';
import DrawTools from 'components/drawing/DrawTools';
import ShapesComponent from 'components/drawing/ShapesComponent/ShapesComponent';
import SnapLine from 'components/drawing/SnapLine/SnapLine';
import SnapPoint from 'components/drawing/SnapPoint/SnapPoint';
import { DrawToolType } from 'constants/DrawToolType';
import { isEmpty } from 'lodash';
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import { drawToolTypesByShortcutKey, showAddBackgroundImageDialog } from 'utils/DrawToolsUtils';
import i18n from 'utils/i18n';
import { SZoom } from 'utils/seriouszoom';
import AddBackgroundImageScaleTool from '../AddBackgroundImageScaleTool/AddBackgroundImageScaleTool';
import AddCountPointTool from '../AddCountPointTool/AddCountPointTool';
import AddHoleTool from '../AddHoleTool/AddHoleTool';
import AddRectangleTool from '../AddRectangleTool/AddRectangleTool';
import BackgroundImageComponent from '../BackgroundImageComponent/BackgroundImageComponent';
import BackgroundImageScaleComponent from '../BackgroundImageScaleComponent/BackgroundImageScaleComponent';
import CropBackgroundImageTool from '../CropBackgroundImageTool/CropBackgroundImageTool';
import CropRectangleComponent from '../CropRectangleComponent/CropRectangleComponent';
import CursorPoint from '../CursorPoint/CursorPoint';
import DrawToolsOptionsComponent from '../DrawToolsOptionsComponent/DrawToolsOptionsComponent';
import { getDrawingInstructions } from '../DrawingInstructions/DrawingInstructions';
import { GroupShapesTool } from '../GroupShapesTool';
import { SelectShapeTool } from '../SelectShapeTool';
import SnapToggledPoints from '../SnapToggledPoints/SnapToggledPoints';

const colors = require('Colors.scss');
const styles = require('./DrawLayer.module.scss');

class DrawLayer extends ObserverComponent {
  // zoom and scroll is done outside React which is not ideal when trying to programmatically
  // change scroll position or zoom. We cannot use state
  scrollContainer: HTMLElement;
  svgTag: SVGSVGElement;
  safariForeignObjects: HTMLDivElement;
  previousSelectedNode: TreeNode;
  previousNodesCount = 0;
  previousShapesCount = 0;
  previousSelectedProjectId = '';
  previousBackgroundImage = null;
  previousSatelliteImage = null;
  hasZoomedOut = false;


  componentDidMount() {
    const { shapesStore } = this.context;
    shapesStore.zoomController = new SZoom(
      this.scrollContainer,
      this.svgTag,
      this.safariForeignObjects,
      this.context,
    );

    document.addEventListener('keypress', this.onKeyPress);
  }

  componentWillUnmount(): void {
    super.componentWillUnmount();

    const { shapesStore } = this.context;
    shapesStore.zoomController?.dispose?.();

    document.removeEventListener('keypress', this.onKeyPress);
  }


  onKeyPress = (e: KeyboardEvent) => {
    const { drawToolsStore, dialogsStore } = this.context;
    if (
      ['textarea', 'input'].includes((e.target as HTMLElement).tagName.toLowerCase()) ||
      e.target.className?.includes?.('ql-editor') ||
      e.metaKey ||
      e.ctrlKey ||
      // number of dialogs in drawing context, doesn't include drawing dialog
      dialogsStore.visibleDialogs.length > 0
    ) {
      return;
    }

    const toolType = drawToolTypesByShortcutKey(i18n.language).get(e.key);

    if (toolType) {
      e.preventDefault();

      if (toolType === DrawToolType.BackgroundImage) {
        showAddBackgroundImageDialog(this.context)(e);
      } else {
        drawToolsStore.selectedTool = toolType;
      }
    }
  }

  private onClick = () => {
    const { drawToolsStore, shapesStore } = this.context;
    const { selectedTool } = drawToolsStore;
    if (selectedTool === DrawToolType.Select && !shapesStore.isDragPanningOnDrawing) {
      // would be nicer in a SelectTool.tsx but problem with order of
      // events
      //treeNodesStore.selectedTreeNodes = [];
    }
  }

  centerOnSelectedShape() {
    const { treeNodesStore, shapesStore } = this.context;
    const selectedShapes = treeNodesStore.selectedShapes;

    shapesStore.zoomController.centerOnShapes(selectedShapes);
  }

  public _render() {
    const { treeNodesStore, shapesStore, drawToolsStore, snapStore, commonStore, settingsStore, userInfoStore } = this.context;
    const { viewBoxRectangle } = shapesStore;
    if (!treeNodesStore.rootNode || !treeNodesStore.selectedTreeNode) {
      return null;
    }

    const selectedTreeNode = treeNodesStore.selectedTreeNode;
    const { selectedTool } = drawToolsStore;
    const { satelliteImageUrl, satelliteImageRotation, backgroundImage } = treeNodesStore.rootNode;

    const { isTouchDevice } = settingsStore;

    if (
      selectedTreeNode &&
      selectedTreeNode !== this.previousSelectedNode &&
      shapesStore.zoomController &&
      !isEmpty(selectedTreeNode.shapes) &&
      !drawToolsStore.lineBeingAdded
    ) {
      this.previousSelectedNode = selectedTreeNode;
      //this.centerOnSelectedShape();
    } else if (shapesStore.zoomController && this.previousNodesCount !== treeNodesStore.items.length) {
      this.previousNodesCount = treeNodesStore.items.length;
    }

    if (this.previousSelectedProjectId !== commonStore.selectedProjectId) {
      this.previousShapesCount = 0;
    }
    this.previousSelectedProjectId = commonStore.selectedProjectId;

    // initial zoom out after loading a drawing
    // (DrawLayer component is already loaded, so cannot use componentDidMount)
    const displayedShapesCount = treeNodesStore.rootNode.shapes.length;
    if (
      !this.previousBackgroundImage && backgroundImage ||
      !this.previousSatelliteImage && satelliteImageUrl
    ) {
      setTimeout(() => shapesStore.zoomController.zoomCompletelyOut(), 0);
    } else if (
      selectedTool === DrawToolType.Select &&
      !backgroundImage &&
      !satelliteImageUrl &&
      this.previousShapesCount === 0 &&
      !drawToolsStore.lineBeingAdded &&
      !drawToolsStore.segmentsGroupBeingAdded &&
      shapesStore.zoomController
    ) {
      // this causes annoying glitches, make sure to call only when needed
      shapesStore.zoomController.zoomOutOnShapes(1.15);
      this.previousShapesCount = displayedShapesCount;
    }

    this.previousSatelliteImage = satelliteImageUrl;
    this.previousBackgroundImage = backgroundImage;

    const isScaleToolSelected = selectedTool === DrawToolType.BackgroundImageSetScale;
    const isCropSelected = selectedTool === DrawToolType.BackgroundImageCrop;
    const isCountSelected = selectedTool === DrawToolType.GeneralCount;
    const isDrawingScale = (isScaleToolSelected && !shapesStore.shouldShowBackgroundImageScaleInput);
    const isZooming = shapesStore?.zoomController?.isZooming;

    const instructions = getDrawingInstructions(selectedTool, this.context);

    const isSelectOrGroup = drawToolsStore.selectedTool === DrawToolType.Select || drawToolsStore.selectedTool === DrawToolType.GroupShapes;
    const viewBox = this.svgTag?.viewBox?.baseVal || new DOMRect();

    return (
      <div id="DrawLayer" className={styles.root}>
        {userInfoStore.user?.shouldShowDrawingHints && (
          <Portal>
            <Snackbar
              TransitionComponent={Slide}
              TransitionProps={{
                appear: true
              }}
              className={classnames(styles.helpSnackBar, {
                [styles.isTouchDevice]: isTouchDevice,
                [styles.isCalibration]: isScaleToolSelected || isCropSelected
              })}
              anchorOrigin={{
                vertical: settingsStore.isVerticalOrientation ? 'bottom' : 'top',
                horizontal: settingsStore.isTouchDevice && !settingsStore.isVerticalOrientation ? 'left' : 'center'
              }}
              open={!!instructions}
              message={
                <div className={styles.helpMessage}>
                  {instructions}
                </div>
              }
              action={settingsStore.isVerticalOrientation && (
                <Button className={styles.cancelButton} onClick={() => drawToolsStore.selectedTool = DrawToolType.Select}>{i18n.t('Done')}</Button>
              )}
            />
          </Portal>
        )}
        <DrawTools />
        <div className={styles.flexColumn}>
          {isCountSelected && <DrawToolsOptionsComponent disabled={!!drawToolsStore.lineBeingAdded} />}
          <div
            id="scrollContainer"
            className={styles.scrollContainer}
            ref={ref => this.scrollContainer = ref}
            tabIndex={0}
            style={{ overflow: isTouchDevice ? 'hidden' : 'scroll' }}
          >
            <svg
              id="svgTag"
              ref={ref => this.svgTag = ref}
              className={classnames(
                styles.svgTag,
                {
                  [styles.isDrawing]: (
                    isDrawingScale ||
                    selectedTool === DrawToolType.GeneralDraw ||
                    selectedTool === DrawToolType.GeneralCount ||
                    selectedTool === DrawToolType.Hole ||
                    selectedTool === DrawToolType.PredefinedShapeRectangle
                  ),
                  shouldHover: isSelectOrGroup,
                  [styles.isZooming]: isZooming,
                  [styles.hasALotOfElements]: displayedShapesCount > 120
                },
              )}
              onClick={this.onClick}
            >
              <style>
                {``}
              </style>
              <defs>
                <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="12" height="4">
                  <path d="
                    M-1,1 l12,-12
                    M0,4 l12,-4
                    "
                    style={{ stroke: 'rgba(0,0,0,0.5)', strokeWidth: 1 }} />
                </pattern>
                <filter x="0" y="0" width="1" height="1" id="textBackground">
                  <feFlood floodColor="white" />
                  <feComposite in="SourceGraphic" />
                </filter>
                <marker id='arrowheadStart' orient='auto' markerWidth='5' markerHeight='10'
                  refX='0' refY='5'>
                  <path d='M5,0 V10 L0,5 Z' className={styles.arrowHead} />
                </marker>
                <marker id='arrowheadEnd' orient='auto' markerWidth='5' markerHeight='10'
                  refX='5' refY='5'>
                  <path d='M0,0 V10 L5,5 Z' className={styles.arrowHead} />
                </marker>
                <g id="crosshair" strokeWidth="1" stroke="black" fill="none" transform="translate(-24, -24)">
                  <path d="M 24,0 L24, 48" />
                  <path d="M 0,24 L48, 24" />
                  <path d="M 21, 21 L27,21 L27,27 L21,27 L21,21" />
                </g>
                {/* duplicate and bad because chrome might use svg defs from a different component */}
                <g id="countPoint" strokeWidth="1">
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
                </g>
              </defs>

              {/* used to test if foreign elements layer is aligned to svg
              <rect x="-950" y="-585" width="50" height="50" fill="rgba(0,0,255,0.6)" transform="scale(1.5)"></rect>
              */}

              <g id="zoomPanGroup">

                {satelliteImageUrl && (
                  <image
                    style={{ pointerEvents: 'none', transform: `rotate(${satelliteImageRotation}deg)`, opacity: 0.9 }}
                    href={satelliteImageUrl}
                    width="6144"
                    height="6144"
                  />
                )}

                {backgroundImage && (
                  <BackgroundImageComponent backgroundImage={backgroundImage} isSvg />
                )}

                {viewBoxRectangle && selectedTool === DrawToolType.BackgroundImageCrop && backgroundImage && (
                  <CropRectangleComponent viewBoxRectangle={viewBoxRectangle} />
                )}

                {!isScaleToolSelected && !isCropSelected && <ShapesComponent forceObserve shouldShowDimensions node={treeNodesStore.rootNode} />}

                {isScaleToolSelected && shapesStore.backgroundImageScaleShape && (
                  <BackgroundImageScaleComponent />
                )}

                <SnapPoint forceObserve point={snapStore.snapPoint} />
                <SnapLine forceObserve line={snapStore.snapLineHorizontal} />
                <SnapLine forceObserve line={snapStore.snapLineVertical} />
                <SnapToggledPoints forceObserve points={snapStore.snapPoints} />

                {isTouchDevice && (
                  <CursorPoint
                    style={{
                      visibility: (snapStore.isTouchActive || shapesStore.isCalibrationSecondStep) ? 'visible' : 'hidden',
                    }}
                    forceObserve
                  />
                )}

                {(selectedTool === DrawToolType.Select) && <SelectShapeTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.GroupShapes) && <GroupShapesTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.GeneralDraw) && <AddDrawingTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.GeneralCount) && <AddCountPointTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.PredefinedShapeRectangle) && <AddRectangleTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.BackgroundImageSetScale) && <AddBackgroundImageScaleTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.BackgroundImageCrop) && <CropBackgroundImageTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
                {(selectedTool === DrawToolType.Hole) && <AddHoleTool ref={ref => { if (ref) { drawToolsStore.selectedDrawToolRef = ref } }} forceObserve />}
              </g>
            </svg>

            <div id="safariForeignElements" ref={ref => this.safariForeignObjects = ref} className={styles.safariForeignElements}>
              <div id="fakeViewbox">
                {/* Here html components will go on top of the SVG, instead of using foreignElements that are badly supported by safari */}
                {/* used to test if foreign elements layer is aligned to svg
                <div style={{ position: 'absolute', transformOrigin: 'top left', width: 50, height: 50, backgroundColor: 'rgba(255,0,0,0.6)', transform: 'scale(1.5) translate(-950px, -585px)' }}></div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DrawLayer;