import { createTheme } from '@material-ui/core';

const colors = require('./Colors.scss');

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.action,
    },
    secondary: {
      main: colors.primary, // todo cleanup names
    },
  }
});

theme.overrides = {
  MuiBottomNavigationAction: {
    root: {
      backgroundColor: 'transparent !important',
      '&.Mui-selected': {
        backgroundColor: `${colors.highlight} !important`,
        color: colors.primary,
      }
    },
    label: {
      fontSize: '11px',
      '&.Mui-selected': {
        fontSize: '11px'
      }
    }
  },
  MuiTypography: {
    root: {
      userSelect: 'none',
    },
    body1: {
      fontSize: '14px',
      color: colors.text,
    },
    body2: {
    },
    gutterBottom: {
      marginBottom: '0',
    }
  },
  MuiAvatar: {
    root: {
      width: '40px',
      height: '40px',
    },
    colorDefault: {
      backgroundColor: colors.gray11,
      color: colors.secondary,
    }
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  MuiListSubheader: {
    sticky: {
      backgroundColor: 'white'
    }
  },
  MuiListItem: {
    gutters: {
      paddingLeft: '15px',
      paddingRight: '15px',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      }
    },
    selected: {
      backgroundColor: `${colors.highlight} !important`,
    },
    button: {
      '&:hover': {
        backgroundColor: colors.gray11,
      }
    }
  },
  MuiListItemIcon: {
    root: {
      color: colors.gray3,
    }
  },
  MuiListItemAvatar: {
    root: {
      minWidth: 'auto'
    }
  },
  MuiListItemText: {
    root: {
      padding: '0 12px'
    }
  },
  MuiExpansionPanel: {
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding:  '0 0px',
      '&:before': {
        backgroundColor: 'transparent',
      }
    },
    expanded: {
      marginBottom: '25px;',
    }
  },
  MuiExpansionPanelSummary: {
    root: {
      padding: '0px',
      minHeight: '0px !important',
      '&:hover': {
        '& $expandIcon': {
          display: 'block'
        }
      }
    },
    expanded: {
      margin: '0 !important',
    },
    content: {
      margin: 0,
    },
    expandIcon: {
      //visibility: 'hidden',
      right: '4px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#182836',
      fontSize: '12px',
      maxWidth: '500px'
    }
  },
  MuiTabs: {
    root: {
      flex: 1,
      minHeight: 0,
      color: 'white',
      backgroundColor: 'transparent',
      flexShrink: 0,
      boxShadow: 'none',
    },
  },
  MuiTab: {
    root: {
      flex: 1,
      fontSize: '13px !important',
      minWidth: '0 !important',
      lineHeight: 1,
      color: colors.gray3,
      letterSpacing: '0.5px',
      fontWeight: 400,
      textTransform: 'none',
    },
    labelIcon: {
      padding: '0 15px',
      paddingTop: '0',
      //minHeight: '31px',
      minHeight: '40px',
    },
    textColorSecondary: {
      color: colors.gray3,
    },
    wrapper: {
      flexDirection: 'row',
    },

  },
  MuiIconButton: {
    root: {
      padding: 0,
      color: colors.gray3,
      width: '32px',
      height: '32px'
    },
    '& > svg': {
      pointerEvents: 'none',
    }
  },
  MuiPopover: {
    root: {
      // 1300 is dialog, and popoover sometimes inside dialog
      zIndex: '1450 !important',
    }
  },
  MuiButton: {
    root: {
      color: colors.primary,
      minHeight: 0,
      '&:hover': {
        backgroundColor: colors.gray2,
      },
      lineHeight: 1,
    },
    containedPrimary: {
      color: 'white',
      backgroundColor: colors.action,
    },
    textPrimary: {
      color: colors.primary,
    },
    label: {
      alignItems: 'center',
    }
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  MuiFormLabel: {
    focused: {
      color: `${colors.action}`,
    }
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: '5px',
      marginBottom: '5px',
    }
  },
  MuiInput: {
    underline: {
      width: '100%',
      '&:before': {
        borderBottom: `1px solid ${colors.darkBorder}`,
      }
    }
  },
  MuiInputBase: {
    input: {
      height: 'auto',
    }
  },
  MuiInputLabel: {
    root: {
      zIndex: 1,
      pointerEvents: 'none',
      whiteSpace: 'nowrap',
    },
    filled: {
      transform: 'translate(12px, 22px) scale(0.85)'
    },
    formControl: {
      transform: 'translate(0, 26px) scale(0.9)',
    },
    shrink: {
      transform: 'translate(12px, 7px) scale(0.75) !important',
    }
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.2)',
      transition: 'opacity 0ms !important',
    }
  },
  MuiDialog: {
    paperWidthSm: {
      maxWidth: 'auto',
      overflowX: 'hidden',
    },
    paper: {
      margin: 0,
    },
    paperScrollPaper: {
      maxHeight: '97%'
    }
  },
  MuiDialogContentText: {
    root: {
      minHeight: 0,
      flexDirection: 'column',
      display: 'flex'
    }
  },
  MuiDialogActions: {
    root: {
      margin: '10px 18px 10px 10px',
      padding: 0,
      zIndex: 2,
    }
  },
  MuiDialogTitle: {
    root: {
      padding: '16px 16px',
      color: 'white',
      backgroundColor: colors.primary,
      flex: 'none !important'
    }
  },
  MuiPaper: {
    root: {
      //overflow: 'visible !important',
    }
  },
  MuiFilledInput: {
    root: {
      backgroundColor: colors.gray16,
      '&:hover': {
        backgroundColor: colors.secondary,
      },
      '&$focused': {
        backgroundColor: '#133e6a1f',
      }
    },
    inputMultiline: {
      resize: 'vertical',
      '&.Mui-disabled': {
        pointerEvents: 'all', // ensure disabled textarea can still be scrolled
      }
    },
    underline: {
      '&:before': {
        borderBottom: `1px solid ${colors.darkBorder}`,
      },
      '&:after': {
        borderBottom: `2px solid ${colors.action}`,
      }
    },
    disabled: {
      color: 'black',
      backgroundColor: colors.gray16,
      opacity: 0.5,
      '&:before': {
        borderStyle: `none !important`,
      }
    },
    input: {
      padding: '24px 12px 10px',
    }
  },
  MuiTableRow: {
    root: {
      height: '34px',
    },
    head: {
      height: '22px',
    }
  },
  MuiTableCell: {
    root: {
      verticalAlign: 'bottom',
      padding: '0px 16px',
      borderBottomStyle: 'none',
    },
    head: {
      fontWeight: 400,
    }
  },
  MuiNativeSelect: {
    select: {
      paddingLeft: '16px',
      paddingRight: '16px',
    }
  },
  MuiCheckbox: {
    root: {
      color: colors.gray3,
    }
  },
  MuiDivider: {
    inset: {
      marginLeft: '56px',
      backgroundColor: colors.border,
    }
  },
  MuiMenuItem: {
    gutters: {
      paddingLeft: '8px',
      paddingRight: '8px',
    }
  },
  MuiSelect: {
    select: {
      fontSize: '15px'
    }
  },
  MuiSwitch: {
    switchBase: {
      top: '2px'
    }
  },
  PrivateSwitchBase: {
    root: {
      padding: '6px'
    }
  },
  MuiToggleButton: {
    root: {
      padding: '3px',
      textTransform: 'none',
      fontSize: '13px',
      borderColor: colors.border,
      '&.Mui-selected': {
        backgroundColor: colors.secondary + ' !important',
        color: colors.primary
      }
    }
  }
};
