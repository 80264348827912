import { Button, ClickAwayListener, IconButton, Tooltip, Typography } from '@material-ui/core';
import AcceptIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import classnames from 'classnames';
import AvatarImage from 'components/common/AvatarImage/AvatarImage';
import { FilterDateOptions } from 'constants/FilterConstants';
import { Unit } from 'constants/Unit';
import { UnitType } from 'constants/UnitType';
import { first } from 'lodash';
import { computed } from 'mobx';
import { ProvidedQuantity } from 'models/ProvidedQuantity';
import ProvidingItem from 'models/ProvidingItem';
import Task from 'models/Task';
import * as React from 'react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';
import timeagoFr from 'timeago.js/lib/lang/fr';
import { roundPrice } from 'utils/CurrencyUtil';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { formulaHasVariables } from 'utils/MeasurementFormatter';
import { InputNumberFormatCurrency, InputNumberFormatDecimal, formatCurrency, parseCurrency } from 'utils/NumberFormatter';
import { handleProvidedQuantityChange } from 'utils/ProvidedQuantityUtil';
import { onProvidingItemDrop, showProvidingItemDialog } from 'utils/ProvidingItemUtil';
import { TrackedInteractions, trackInteraction } from 'utils/TrackingUtil';
import i18n from 'utils/i18n';
import uuidv4 from 'uuid/v4';
import { Avatar } from '../Avatar/Avatar';
import AvatarImageWithFallback from '../AvatarImageWithFallback/AvatarImageWithFallback';
import DroppableDiv from '../DroppableDiv/DroppableDiv';
import { HelmetIcon, WoodStackIcon } from '../Icons';
import { ListItemAvatar } from '../ListItemAvatar/ListItemAvatar';
import { ListItemText } from '../ListItemText/ListItemText';
import ObserverComponent, { ObserverComponentProps } from '../ObserverComponent';
import TextField from '../TextField/TextField';
import UnitComboBox from '../UnitComboBox/UnitComboBox';
timeago.register('fr', timeagoFr);

const styles = require('./ProvidingItemComponent.module.scss');
const colors = require('../../../Colors.scss');

// Notice extends HTML props for class name
interface IProvidingItemComponentProps {
  providingItem: ProvidingItem,
  parentTask?: Task,
  imageSize?: { w: number, h: number },
  isEditable?: boolean,
  isReportView?: boolean,
  shouldHideMeasurementName?: boolean,
  className?: string,
  secondaryText?: string,
}

interface IProvidingItemComponentState {
  providingItemCopy: ProvidingItem,
  shouldDuplicateItem: boolean,
}

export default class ProvidingItemComponent extends ObserverComponent<IProvidingItemComponentProps, IProvidingItemComponentState> {
  static defaultProps = {
    imageSize: {
      w: 40,
      h: 40,
    }
  };

  nameInputRef;
  rootEditRef;

  state: IProvidingItemComponentState = {
    providingItemCopy: null,
    shouldDuplicateItem: false
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    this.exitEditMode();
  }

  enterEditMode = (event) => {
    const { isEditable, providingItem } = this.props;
    const { providingItemsStore, tasksStore } = this.context;
    if (
      !isEditable ||
      event.target.getAttribute('aria-label') === 'Zoom image' ||
      event.target.getAttribute('aria-label') === 'Unzoom image'
    ) {
      return;
    }

    if (formulaHasVariables(providingItem.priceFormula)) {
      this.showMaterialDialog();
      return;
    }

    providingItemsStore.providingItemBeingEdited = this.props.providingItem;
    tasksStore.taskBeingEdited = this.props.parentTask;

    this.setState({
      providingItemCopy: providingItem.clone(),
    });
  }

  exitEditMode = () => {
    const { providingItemsStore, tasksStore } = this.context;
    const { shouldDuplicateItem } = this.state;

    if (this.props.providingItem?.id === providingItemsStore.providingItemBeingEdited?.id) {
      providingItemsStore.providingItemBeingEdited = null;
      tasksStore.taskBeingEdited = null; // could conflict with other task starting to be edited
    }

    if (shouldDuplicateItem) {
      this.setState({ shouldDuplicateItem: false });
    }
  }

  saveChanges = () => {
    if (document.activeElement?.role === 'combobox') {
      //cobrowse messes up with the focus
      return;
    }

    const { providingItemsStore, tasksStore, userInfoStore } = this.context;
    const { providingItemCopy, shouldDuplicateItem } = this.state;
    const { parentTask, providingItem } = this.props;
    const { nonImpersonatedUser } = userInfoStore;

    const batch = firestoreBatch(this.context);

    if (
      providingItemCopy.isLabour &&
      providingItemCopy.labourDuration &&
      providingItemCopy.labourRate &&
      providingItem.price !== providingItemCopy.price
    ) {
      providingItemCopy.labourRate = roundPrice(providingItemCopy.price / providingItemCopy.labourDuration);
    }

    if (shouldDuplicateItem) {
      providingItemCopy.id = uuidv4();
    }

    if (!nonImpersonatedUser.shouldAutoSyncProvidingItems) {
      providingItemsStore.saveToDb(
        [providingItemCopy],
        providingItemsStore.collections.filter(collection => parentTask
          ? collection === providingItemsStore.projectCollection
          : collection === providingItemsStore.userCollection
        ),
        undefined,
        batch
      );
    } else {
      providingItemsStore.batchAddEditItem(providingItemCopy, batch);
    }

    if (parentTask) {
      parentTask.providingItem = providingItemCopy;
      tasksStore.batchAddEditItem(parentTask, batch);
    }

    batch.commit();

    this.exitEditMode();
  }

  showMaterialDialog = () => {
    const { providingItemCopy, shouldDuplicateItem } = this.state;
    const { parentTask } = this.props;

    // check now because saveChanges affects this flag;
    const isEditMode = this.isEditMode;

    if (shouldDuplicateItem) {
      this.saveChanges();
    }

    const providingItem = isEditMode ? providingItemCopy : this.props.providingItem;

    showProvidingItemDialog(providingItem, parentTask);

    this.exitEditMode();
  }

  handleChange = (propertyName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { providingItemCopy } = this.state; // todo: separate edit mode/dialog better
    let { value } = event.target as any;

    switch (propertyName) {
      // duplicate
      case 'price':
        value = parseCurrency(value);
        providingItemCopy.priceUpdatedMiliseconds = (new Date()).getTime();
        if (!value) {
          value = 0;
        }
        break;

    }

    providingItemCopy[propertyName] = value;
  }

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.saveChanges();
    } else if (e.key === 'Escape') {
      this.exitEditMode();
    }
  }

  removeFromTask = () => {
    const { tasksStore } = this.context;
    const { parentTask } = this.props;

    parentTask.providingItem = null;
    tasksStore.addEditItem(parentTask);

    trackInteraction(TrackedInteractions.ReplaceATaskItem);
  }

  @computed get isEditMode() {
    const { providingItemsStore, tasksStore, } = this.context;
    return (
      this.props.isEditable &&
      providingItemsStore.providingItemBeingEdited?.id === this.props.providingItem?.id &&
      tasksStore.taskBeingEdited === this.props.parentTask
    );
  }

  // complicated
  componentDidUpdate(prevProps: Readonly<IProvidingItemComponentProps & ObserverComponentProps>, prevState: Readonly<IProvidingItemComponentState>, snapshot?: any): void {
    if (this.isEditMode && !this.state.providingItemCopy) {
      this.setState({ providingItemCopy: this.props.providingItem.clone() });
    }
  }

  componentDidMount(): void {
    this.componentDidUpdate(this.props, this.state);
  }

  _render() {
    const { categoriesStore, providingItemsStore, dragAndDropStore, userInfoStore } = this.context;
    const { user } = userInfoStore;
    const { shouldDuplicateItem } = this.state;
    const { parentTask, imageSize, isReportView, shouldHideMeasurementName, className } = this.props;
    const shouldShowPrice = !isReportView;

    let providingItem: ProvidingItem = this.isEditMode ? this.state.providingItemCopy : this.props.providingItem;
    if (!providingItem || !user) {
      return null;
    }

    const shouldShowPriceUpdated = false && !parentTask && providingItemsStore.priceUpdatedFilter !== FilterDateOptions.All;

    const { computedUnitString } = providingItem;

    const unitType = parentTask?.measurement?.unitType;
    const providedQuantity = (
      // here should get parentTask.providedQuantity, but couldn't make clone parent Task properly
      providingItem.providedQuantities.get(unitType || UnitType.Unit) ||
      first(Array.from(providingItem.providedQuantities.values())) ||
      // duplicate... remove when getting from parentTask instead
      new ProvidedQuantity(UnitType.Unit, 1, Unit.Unit, 0)
    );

    const secondaryText = this.props.secondaryText || (
      parentTask && (
        isReportView
          ? (shouldHideMeasurementName ? '' : parentTask.secondaryTextWithMeasurementName)
          : parentTask.secondaryText
      ));

    return this.isEditMode
      ? (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.saveChanges}>
          <div ref={ref => this.rootEditRef = ref} className={styles.rootEdit} onKeyDown={this.onKeyPress}>
            <TextField
              inputRef={ref => this.nameInputRef = ref}
              shouldFocusOnMount
              className={styles.textField + ' ' + styles.nameField + ' ' + styles.textFieldNoLabel}
              value={providingItem.name}
              onChange={this.handleChange('name')}
            />
            <div style={{ width: '100%' }} />
            <TextField
              className={styles.textField + ' ' + styles.textFieldNoLabel + ' ' + styles.priceTextField}
              value={providingItem.price}
              onChange={this.handleChange('price')}
              inputProps={{
                size: 10,
              }}
              InputProps={{
                inputComponent: InputNumberFormatCurrency,
              }}
            />
            <div className={styles.providing}>{i18n.t('for')}</div>
            <TextField
              className={styles.textField + ' ' + styles.textFieldNoLabel}
              inputProps={{
                size: 10,
              }}
              value={providedQuantity.quantityString}
              InputProps={{
                inputComponent: InputNumberFormatDecimal,
              }}
              onChange={handleProvidedQuantityChange(providingItem, providedQuantity, 'quantity')}
            />

            <UnitComboBox
              className={styles.textField + ' ' + styles.textFieldNoLabel + '  ' + styles.unitComboBox}
              value={providedQuantity.unit}
              onChange={handleProvidedQuantityChange(providingItem, providedQuantity, 'unit')}
            />

            <div style={{ width: '100%' }} />

            <div className={styles.editTools} >
              {parentTask && (
                <>
                  <Tooltip title={i18n.t('Remove from current task')}>
                    <IconButton className={styles.removeButton} onClick={this.removeFromTask}><RemoveIcon /></IconButton>
                  </Tooltip>
                  {shouldDuplicateItem ? (
                    <Typography variant="body2" color="textSecondary" className={styles.duplicatedMessage} >{i18n.t('Item duplicated')}</Typography>
                  ) : (
                    <Tooltip title={i18n.t('Duplicate')}>
                      <IconButton className={styles.removeButton} onClick={() => this.setState({ shouldDuplicateItem: true })}>
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              <div className={styles.flexSeparator} />
              <Button className={styles.detailsButton} onClick={this.showMaterialDialog}>{i18n.t('Details')}</Button>
              <div className={styles.detailsSeparator} />
              <IconButton className={styles.cancelButtonIcon} onClick={this.exitEditMode}><CancelIcon /></IconButton>
              <IconButton className={styles.acceptButtonIcon} onClick={this.saveChanges}><AcceptIcon /></IconButton>
            </div>
          </div>
        </ClickAwayListener >
      ) : (
        <DroppableDiv
          className={classnames(styles.root, className, { [styles.isReportView]: isReportView, [styles.isItemHidden]: providingItem.isHidden && !parentTask })}
          onDrop={onProvidingItemDrop(parentTask)}
          shouldEnable={parentTask && dragAndDropStore.dragObject instanceof ProvidingItem}
          onClick={this.enterEditMode}
        >
          <ListItemAvatar>
            <Avatar style={{ width: imageSize.w, height: imageSize.h }}>
              {
                providingItem.thumbUrl
                  ? <AvatarImage withImageDelay={false && !parentTask} style={{ height: imageSize.h, width: imageSize.w }} model={providingItem} />
                  : (
                    (providingItem.category === categoriesStore.generalCategory && !providingItem.isLabour)
                      ? <WoodStackIcon style={{ width: imageSize.w, height: imageSize.h }} className={styles.avatarIcon} component="img" />
                      : <AvatarImageWithFallback
                        className={classnames(styles.avatarIcon, {
                          [styles.hasParentTask]: !!parentTask,
                          [styles.isLabour]: providingItem.isLabour,
                          [styles.hasCategThumb]: (providingItem.category?.thumbUrl || providingItem.subcategory?.thumbUrl)
                        })}
                        model={
                          (
                            providingItem.subcategory?.id !== 'General' &&
                            (providingItem.subcategory?.thumbUrl || !providingItem.category?.thumbUrl)
                          ) ? providingItem.subcategory
                            : providingItem.category
                        } />
                  )
              }
              {providingItem.isLabour && !providingItem.thumbUrl && (
                <div
                  className={classnames(styles.labourIcon, {
                    [styles.hasCategThumb]: (providingItem.category?.thumbUrl || providingItem.subcategory.thumbUrl),
                    [styles.hasParentTask]: !!parentTask
                  })}
                >
                  <HelmetIcon
                    style={{ width: 0.5 * imageSize.w, height: 0.5 * imageSize.h }}
                    component={isReportView ? "img" : 'undefined'} />
                </div>
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            className={classnames(styles.materialText, { [styles.hasParentTask]: !!parentTask })}
            style={secondaryText
              ? {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }
              : {}
            }
            primary={parentTask
              ? parentTask.providingItemDisplayName + ((isReportView && user.shouldAlwaysShowTimeInReports)
                ? ' ' + parentTask.timeString
                : ''
              ) : providingItem.name?.replaceAll?.('{}', '')?.trim?.()}
            secondary={secondaryText || ''}
          />

          {providingItemsStore.shouldMarkMasterItems && !parentTask && providingItem.cascadeOrders.has(0) && <VerifiedIcon className={styles.verifiedIcon} />}

          {shouldShowPrice && (
            <div className={styles.priceContainer}>
              <h6 style={{ fontWeight: 500, whiteSpace: 'nowrap', color: parentTask && !parentTask.price ? colors.red : colors.text }} className={classnames(styles.price, "MuiTypography-subtitle2")}>
                {parentTask
                  ? formatCurrency(parentTask.priceWithOrWithoutFees)
                  : (providingItem.price
                    ? formatCurrency(providingItem.priceWithOrWithoutFees)
                    : '-'
                  )}
              </h6>

              {!parentTask && computedUnitString && !!providingItem.priceWithOrWithoutFees && (
                <div className={styles.unit + ' providingItem-unit'}>
                  / {computedUnitString}
                </div>
              )}
            </div>
          )}

          {/* we have too many outdated prices to allow users to easily see it for now */}
          {shouldShowPriceUpdated && (
            <div className={styles.priceUpdated}>
              <TimeAgo
                datetime={Math.max(providingItem.priceUpdatedMiliseconds, new Date('2021-08-01').getTime())}
                locale={i18n.language === 'fr' ? 'fr' : 'en_US'}
              />
            </div>
          )}
        </DroppableDiv>
      );
  }
}