import ObserverComponent from 'components/common/ObserverComponent';
import { Unit } from 'constants/Unit';
import Point from 'models/Point';
import Surface from 'models/Surface';
import * as React from 'react';
import { findCentroid } from 'utils/GeometryUtils';
import MeasurementFormatter from 'utils/MeasurementFormatter';

require('./DimensionSurface.scss');

interface IDimensionSurfaceProps {
  surfaceToMeasure: Surface,
  hidden: boolean;
  containerWidth?: number,
  containerHeight?: number,
  readonly?: boolean;
  // could add other stuff like clockwise offset distance etc
}

interface IDimensionSurfaceState {
  surfaceCenterPoint: Point,
}

export default class DimensionSurface extends ObserverComponent<IDimensionSurfaceProps, IDimensionSurfaceState> {
  state = {
    surfaceCenterPoint: null,
    surfacePath: '',
  };

  static getDerivedStateFromProps(props: IDimensionSurfaceProps) {
    const points = props.surfaceToMeasure.points;
    const surfaceCenterPoint = points.length >= 3
      ? findCentroid(points.slice(0))
      : null;

    const surfacePath =
      // line from every point to center point
      //('M' + points.join('L' + surfaceCenterPoint + 'M')) + 'L' + surfaceCenterPoint +
      // contour line joining every points
      props.surfaceToMeasure.pathString;

    return { surfaceCenterPoint, surfacePath };
  }

  // hack and called too often
  get formattedMeasurement() {
    const { surfaceToMeasure } = this.props;

    let metricValue = surfaceToMeasure.roofSurface;

    if (
      surfaceToMeasure.offsetSurfaces.length == 1 &&
      surfaceToMeasure.offsetSurfaces[0].surface < surfaceToMeasure.surface
    ) {
      metricValue = surfaceToMeasure.offsetSurfaces[0].surface;
    }

    return MeasurementFormatter.format(
      //todo fix hack
      // @ts-ignore
      {
        metricValue,
        measurement: {
          displayUnit: this.context.settingsStore.isImperial
            ? Unit.SquareFoot
            : Unit.SquareMeter,
          stores: this.context
        },
        applyAdjustment: (a => a),
      },
    );
  }

  _render() {
    const { settingsStore, shapesStore, treeNodesStore, routerStore } = this.context;
    const { surfaceToMeasure, hidden, readonly, containerWidth, containerHeight } = this.props;
    const { surfaceCenterPoint, surfacePath } = this.state;

    if (!surfaceCenterPoint || !settingsStore) {
      return null;
    }

    if (isNaN(surfaceCenterPoint.x) || isNaN(surfaceCenterPoint.y)) {
      const test = 0;
      return null;
    }

    const textWidth = 90;
    const textHeight = 15;

    const drawingNode = surfaceToMeasure.treeNode?.parentDrawingNode?.parent;
    const viewBoxWidth = drawingNode?.viewBoxRectWithoutDimensions?.width;
    const viewBoxHeight = drawingNode?.viewBoxRectWithoutDimensions?.height;

    let scaleFromContainer = Math.min(
      999999,
      Math.min(
        (containerWidth || 0) / ((viewBoxWidth || 800)),
        (containerHeight || 0) / ((viewBoxHeight || 600))
      )
    );

    // duplicate
    let shapeScale = readonly
      ? (scaleFromContainer * (routerStore.isReportPage ? 1 : 0.8) || 0.3)
      : shapesStore.zoomController?.observableScale || 1;

    const textFontSize = 16 / shapeScale;
    const slopeArrowSize = 20 / shapeScale;


    // don't display if text is bigger than the line
    if (
      surfaceToMeasure.bboxWidthPixels * shapeScale < this.formattedMeasurement.length * textFontSize * 0.5 /* arbitrary*/ * shapeScale
    ) {
      return null;
    }

    return (<g className={'DimensionSurface ' + (hidden ? 'isDimensionHidden' : '')}>
      <path d={surfacePath} className={'surfacePath'} />
      {
        // slope arrow
        surfaceToMeasure.roofSurface !== surfaceToMeasure.surface &&
        surfaceToMeasure.roofSlopeDirection !== -1 && (
          <g
            className={'slopeDirection'}
            transform={`translate(${surfaceCenterPoint.x - 75 / shapeScale}, ${surfaceCenterPoint.y - 7 / shapeScale})`}
          >
            {/* arrow points left originally */}
            <svg width={slopeArrowSize} height={slopeArrowSize} viewBox="0 0 24 24">
              <g style={{ transform: `rotate(${(-surfaceToMeasure.roofSlopeDirection + Math.PI)}rad)`, transformOrigin: 'center' }}>
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
              </g>
            </svg>
          </g>
        )
      }

      <text filter={routerStore.isReportPage ? null : 'url(#textBackground)'} className={'root'}>
        <tspan textAnchor="middle"
          x={surfaceCenterPoint.x}
          y={surfaceCenterPoint.y}
          dy={textFontSize / 2}
          className={'text'}
          style={{ fontSize: `${textFontSize}px` }}
        >
          {this.formattedMeasurement}
        </tspan>
      </text>

      {/*
  // would be nice to have text on top of shape, but which one to use from the tree hierarchy?
  <text>
    <tspan textAnchor="middle"
      x={surfaceCenterPoint.x}
      y={surfaceCenterPoint.y + textHeight * 2}
      dy={textHeight}
      className={'smallText'}>
      Vide Sanitaire
    </tspan>
  </text>*/}
    </g >
    );
  }
}