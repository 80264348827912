import { BottomNavigation } from '@material-ui/core';
import DrawToolButton from 'components/common/DrawToolButton/DrawToolButton';
import DrawToolButtonWithSubButtons from 'components/common/DrawToolsButtonWithSubButtons/DrawToolsButtonWithSubButtons';
import ObserverComponent from 'components/common/ObserverComponent';
import { DrawToolType } from 'constants/DrawToolType';
import * as React from 'react';
import { showAddBackgroundImageDialog } from 'utils/DrawToolsUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';

const styles = require('./DrawTools.module.scss');

export default class DrawTools extends ObserverComponent {
  handleChange = (event, newSelectedTool) => {
    const { drawToolsStore, shapesStore, commonStore } = this.context;

    if (drawToolsStore.selectedTool !== newSelectedTool) {
      drawToolsStore.selectedTool = newSelectedTool;
    }
  };

  removeDuplicates = (event) => {
    const { drawToolsStore, shapesStore, treeNodesStore } = this.context;

    const nodesToDelete = drawToolsStore.duplicateLines.map(line => (
      treeNodesStore.getNodeForShape(line)
    ));

    const batch = firestoreBatch(this.context);
    shapesStore.deleteItems(drawToolsStore.duplicateLines.map(shape => shape.id), true, batch)
    treeNodesStore.deleteItems(nodesToDelete.map(node => node.id), true, batch);
    batch.commit();

    event.preventDefault();
    event.stopPropagation();
  }

  openStreetView = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  _render() {
    const { treeNodesStore, drawToolsStore } = this.context;

    if (!treeNodesStore.rootNode) {
      return null;
    }

    const { backgroundImage, satelliteImageAddress } = treeNodesStore.rootNode;

    const isScaleSet = !backgroundImage || backgroundImage.isScaleSet;

    const disabledTitle = i18n.t('Drawing is not possible until the scale has been calibrated');

    return (
      <div id="DrawTools" className={styles.root}>
        <BottomNavigation className={styles.nav} onChange={this.handleChange} showLabels>
          <DrawToolButton toolType={DrawToolType.Select} />
          {/*
          <DrawToolButtonWithSubButtons
            subButtons={[DrawToolType.Select, DrawToolType.Move]}
          />
          <DrawToolButtonWithSubButtons
            subButtons={[DrawToolType.Excavation, DrawToolType.Foundation]}
          />
          <DrawToolButton toolType={DrawToolType.Wall} />
          <DrawToolButtonWithSubButtons
            subButtons={[DrawToolType.Doors, DrawToolType.Windows]}
          />
          <DrawToolButton toolType={DrawToolType.Roof} />
          */}

          <DrawToolButton toolType={DrawToolType.GeneralDraw} disabled={!isScaleSet} disabledTitle={disabledTitle}/>
          <DrawToolButton toolType={DrawToolType.PredefinedShapeRectangle} disabled={!isScaleSet} disabledTitle={disabledTitle}/>
          <DrawToolButton toolType={DrawToolType.GeneralCount} disabled={!isScaleSet} disabledTitle={disabledTitle}/>
          
          {/*<DrawToolButtonWithSubButtons
            subButtons={[DrawToolType.PredefinedShapeRectangle, DrawToolType.PredefinedShapeTriangle]}
        />*/}

          {/*(treeNodesStore.selectedTreeNode.shape instanceof Surface) && <DrawToolButton toolType={DrawToolType.Hole} />*/}

          {/*!isEmpty(drawToolsStore.duplicateLines) && <DrawToolButton toolType={DrawToolType.RemoveDuplicates} onMouseDown={this.removeDuplicates} />*/}

          <DrawToolButton toolType={DrawToolType.GroupShapes} />
          
          <DrawToolButton toolType={DrawToolType.BackgroundImage} onMouseDown={showAddBackgroundImageDialog(this.context)} />

          {backgroundImage && (
            <DrawToolButtonWithSubButtons
              subButtons={[DrawToolType.BackgroundImageSetScale, DrawToolType.BackgroundImageCrop]}
            />
          )}

          {satelliteImageAddress && (
            <a href={window.location.origin + `/roof-slope/index.html?address=${encodeURI(satelliteImageAddress)}`} target="_blank">
              <DrawToolButton toolType={DrawToolType.SlopeStreetView} onMouseDown={this.openStreetView} />
            </a>
          )}
        </BottomNavigation>
      </div>
    )
  }
}