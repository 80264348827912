import { action } from 'mobx';
import BaseStore from './BaseStore';

export default class DbCacheStore extends BaseStore {
  // db path -> object
  cache = new Map<string, any>();
  
  @action clearProjectSpecificCache() {
    // never clear cache of Project objects, because it will delete projects on Undo when in the projects list
    this.cache.keys().forEach(key => {
      if (this.cache.get(key)?.type !== 'Project') {
        this.cache.delete(key);
      }
    })
    
    // no gain to make by not clearing everything because, store still needs to subscribe to user collection
    // to listen for changes
    /*
    this.cache.keys().forEach(key => {
      if (
        key.includes(`/users/${this.stores.userInfoStore.userEmail}/projects/`) ||
        key.includes(`/users/master/projects/`)
      ) {
        this.cache.delete(key);
      }
    });
    */
  }
}
