import moment from "moment";

// changing this won't work when calling backend functions that use firebase-admin

// why we put a function :(
export const isProduction = () => process.env.REACT_APP_IS_PRODUCTION === 'true';

export const buildMiliseconds = moment(process.env.PUBLISH_DATE).toDate().getTime();

export const environment = {
  APP_NAME: 'Evalumo',
  proxySuffix: '.merchants.' + (globalThis?.location?.host?.includes?.('evalumodev') ? 'evalumodev.ca' : 'evalumo.com'),
  recaptchaSiteKey: isProduction() 
    ? '6LfrcNMqAAAAAK4o6wF-ItnC2O-iFd0uEtk_YJGC' 
    : '6LdRFdMqAAAAAFEOV2ZH-Z9fAwDbXN_3tMEiCzmu',
  firebase: isProduction()
    ? {
      appId: "1:663211318889:web:0dd00e2463bd2e8f898cf8",
      apiKey: 'AIzaSyBOK0SjoPPxFKicRgNLodSalFQtHOaorSI',
      // the real api key linked to the app, but forces a relogin and the old key seems to still work
      //apiKey: "AIzaSyAox16XB2p4-xkg0cz30BnjpuI_3aiV0KI",
      authDomain: 'auth.evalumo.com',
      databaseURL: 'https://evalumo-prod.firebaseio.com',
      projectId: 'evalumo-prod',
      storageBucket: 'evalumo-prod.appspot.com',
      messagingSenderId: '663211318889',
      measurementId: "G-HX706CM14F"
    } : {
      appId: "1:261994850344:web:e661f7c5139a47e12dad1d",
      // the real api key linked to the app, but forces a relogin and the old key seems to still work
      //apiKey: 'AIzaSyA1OyWHgHhndLTFb4nGw87P3kggOD_hh5M', 
      apiKey: 'AIzaSyA4NbNWdAh1ucAZgMDAk74dNBsNSiLTpCw',
      //authDomain: 'auth-test.evalumo.com',
      authDomain: 'estimo-cb0b2.firebaseapp.com',
      databaseURL: 'https://estimo-cb0b2.firebaseio.com',
      projectId: 'estimo-cb0b2',
      storageBucket: 'estimo-cb0b2.appspot.com',
      messagingSenderId: '261994850344',
      measurementId: "G-4ZCC4PZ6KW",
    },
};
