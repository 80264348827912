import { buildMiliseconds } from 'environment';
import { first } from 'lodash';
import { computed, observable } from "mobx";
import moment from 'moment';
import { custom, serializable } from "serializr";
import { localized, localizedList } from "utils/localized";
import * as uuidv4 from 'uuid/v4';
import SerializableBase from './SerializableBase';

export const DEFAULT_SUBTYPE = 'Default';

export default abstract class ModelBase extends SerializableBase {
  @serializable @observable subtype: string | number = DEFAULT_SUBTYPE;

  @observable @serializable id = uuidv4();

  @serializable @observable owner = '';
  
  // will only be set if creator is different from owner
  @serializable @observable creator = '';

  @serializable @observable _isReadonly = false;

  @serializable @observable deletedMiliseconds: number = 0;

  // use to toggle new functionalities for new users only (or old users that opt in)
  // override in child class when needed
  shouldCheckMinMaxUserVersion = false;

  @serializable @observable minUserVersion = 0;
  @serializable @observable maxUserVersion = 0;

  /* Example code to use in browser to update old and new objects 
  old
  ['bb1e1fe4-01a1-4287-8ea5-a4d458ec2d2f'].forEach(id => {
    globals._defaultStores.tasksListsStore.db.doc(`users/master/projects/${id}`).update({maxBuildDateMiliseconds: 1743116591002});
  });
  new
  ['be279d32-fd4f-4453-aaab-2c38f24f0278'].forEach(id => {
    globals._defaultStores.tasksListsStore.db.doc(`users/master/projects/${id}`).update({minBuildDateMiliseconds: 1743116591002, isDeleted: true});
  });
  */
  @serializable @observable minBuildDateMiliseconds = 0;
  @serializable @observable maxBuildDateMiliseconds = 0;

  @observable _isDeleted = false;
  @serializable @computed get isDeleted(): boolean {
    if (!this.shouldCheckMinMaxUserVersion) {
      return this._isDeleted;
    }

    if (!this.stores.userInfoStore?.user) {
      // will cause major refresh, but dont know how to do else
      return false;
    }

    const userVersion = this.stores.userInfoStore.user.versionOnCreation;

    if ((!this.minUserVersion && !this.maxUserVersion && !this.minBuildDateMiliseconds && !this.maxBuildDateMiliseconds)) {
      return this._isDeleted;
    }

    return (
      !!this.minUserVersion && userVersion < this.minUserVersion || 
      !!this.maxUserVersion && userVersion > this.maxUserVersion ||
      !!this.minBuildDateMiliseconds && buildMiliseconds < this.minBuildDateMiliseconds || 
      !!this.maxBuildDateMiliseconds && buildMiliseconds > this.maxBuildDateMiliseconds
    );
  };

  set isDeleted(value: boolean) {
    if (this._isDeleted !== !!value)
      this._isDeleted = value;
  }

  // override when needed
  // also not 100% readonly right now, each object can
  // decide which parts to make readonly if flag is true
  @computed get isReadonly() {
    return this._isReadonly;
  }

  set isReadonly(value) {
    this._isReadonly = !!value;
  }

  @computed get canChangeReadonly() {
    return true;
  }

  // not used yet
  // could easily revert to original data, but maybe bad for memory usage
  @serializable serializedData = '';

  // used when getting the same item from different places in DB
  // higher cascade order will override the lower one
  // would be nice to have properties override instead of object override
  @observable @serializable cascadeOrder: number = -1;

  // Typo miliseconds instead of milliseconds (throughout the app and can't really change anymore)
  @serializable @observable updatedMiliseconds = 0; // how to use better typing
  @serializable @observable createdMiliseconds = moment().valueOf(); // how to use better typing


  @computed get updated(): moment.Moment {
    return moment(this.updatedMiliseconds || this.createdMiliseconds || moment().valueOf());
  }

  @serializable @observable isHidden: boolean = false;

  @localized name: string = '';

  metaCached = null;
  @computed get meta() {
    return null;
  }

  // ugly backward compatiblity fix
  @localized _imageUrl = '';
  @computed get imageUrl() {
    return this._imageUrl || first(this.imageUrls);
  };
  set imageUrl(value) {
    // should also modify imageUrls but not allowed by mobx, so need to remove backward compatibility soon
    this._imageUrl = value;
  }

  @localized _thumbUrl = '';

  @computed get thumbUrl() {
    return this._thumbUrl || first(this.thumbUrls) || (this.imageUrl?.includes?.('.svg') && this.imageUrl);
  };
  set thumbUrl(value) {
    this._thumbUrl = value;
  }

  // will need to make sure the 2 arrays are in sync
  @localizedList imageUrls: string[] = [];
  @localizedList thumbUrls: string[] = [];

  @serializable @observable index: number = 0; // to enable ordering, set sortField = 'index' in Store

  // which collections contain same item in lower cascade order
  // but sometimes doesn't include top collection too (inconsistent)
  @serializable(custom(
    m => Array.from(m),
    jsonArray => new Set(jsonArray))
  ) @observable cascadeOrders = new Set<number>();


  clone(stores = this.stores, newId = this.id): this {
    const copy = super.clone(stores);
    copy.id = newId;
    return copy;
  }

  cloneWithNewId(stores = this.stores): this {
    return this.clone(stores, uuidv4());
  }

  // Could be a base cloneDeep definition, but for now parameters are too different
  // depending which object we want to clone deep
  // cloneDeep() {}
}