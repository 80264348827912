
import ObserverComponent from 'components/common/ObserverComponent';
import { DRAWING_SCALE } from 'constants/Constants';
import { DrawToolType } from 'constants/DrawToolType';
import { ShapeTags } from 'constants/ShapeTags';
import { throttle } from 'lodash';
import { action } from 'mobx';
import Line from 'models/Line';
import Point from 'models/Point';
import Surface from 'models/Surface';
import TreeNode from 'models/TreeNode';
import Wall from 'models/Wall';
import * as React from 'react';
import { cursorToLocalPoint } from 'utils/Coords';
import { askToSubtractSurfaceIfNeeded, drawToolMouseMoveThrottledBase, MOUSE_MOVE_THROTTLE_TIME, roundToNearestStep, segmentsGroupNodeNameByType, segmentsNodeNameByType, surfaceNodeNameByType } from 'utils/DrawToolsUtils';
import { WriteBatch } from 'utils/FirebaseInitializedApp';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import { isClockwise } from 'utils/GeometryUtils';
import i18n from 'utils/i18n';
import { INCHES_PER_METER } from 'utils/MeasurementConverter';
import { normalizeMouseOrTouchEvent } from 'utils/TouchUtil';
import { getSafe } from 'utils/Utils';

export default class AddRectangleTool extends ObserverComponent {
  svgTag: SVGSVGElement;

  createSegmentsGroup = (parentNode: TreeNode, batch: WriteBatch, cursorPt: Point) => {
    const { treeNodesStore, drawToolsStore, shapesStore } = this.context;

    const { selectedTool } = drawToolsStore;

    const rectangleNode = new TreeNode(this.context);
    rectangleNode._name = i18n.tAll('Rectangle');
    rectangleNode.isExpanded = false;
    treeNodesStore.batchAddEditItem(rectangleNode, batch);
    treeNodesStore.appendNode(
      rectangleNode,
      parentNode,
      undefined,
      batch,
    );

    drawToolsStore.segmentsGroupBeingAdded = new TreeNode(this.context);
    drawToolsStore.segmentsGroupBeingAdded._name = i18n.tAll(segmentsGroupNodeNameByType[DrawToolType.GeneralDraw]);
    drawToolsStore.segmentsGroupBeingAdded.isExpanded = false;
    treeNodesStore.batchAddEditItem(drawToolsStore.segmentsGroupBeingAdded, batch);
    treeNodesStore.appendNode(
      drawToolsStore.segmentsGroupBeingAdded,
      // TODO find closest node that's not a shape
      rectangleNode,
      undefined,
      batch,
    );

    for (let i = 1; i <= 4; i++) {
      const segment = new TreeNode(this.context);
      segment._name = i18n.tAll(segmentsNodeNameByType[DrawToolType.GeneralDraw], { number: i });

      const line = new Wall(this.context, cursorPt.clone(), cursorPt.clone());
      line.startPt.isSnappable = false;
      line.endPt.isSnappable = false;

      shapesStore.addEditItem(line, false);

      segment.shape = line;
      treeNodesStore.batchAddEditItem(segment, batch);
      treeNodesStore.appendNode(
        segment,
        drawToolsStore.segmentsGroupBeingAdded,
        undefined,
        batch,
      );
    }

    // surface
    const newSurface = new Surface(this.context);
    newSurface.points = drawToolsStore.segmentsGroupBeingAdded.shapes.map(line => (line as Wall).startPt.clone());
    shapesStore.addEditItem(newSurface, false);

    drawToolsStore.surfaceNodeBeingAdded = new TreeNode(this.context);
    drawToolsStore.surfaceNodeBeingAdded._name = i18n.tAll(surfaceNodeNameByType[DrawToolType.GeneralDraw]);
    drawToolsStore.surfaceNodeBeingAdded.shape = newSurface;

    treeNodesStore.batchAddEditItem(drawToolsStore.surfaceNodeBeingAdded, batch);
    treeNodesStore.appendNode(
      drawToolsStore.surfaceNodeBeingAdded,
      rectangleNode,
      rectangleNode.children.findIndex(childNode => childNode == drawToolsStore.segmentsGroupBeingAdded) - 1,
      batch,
    );

    if (!rectangleNode.ancestors.some(a => a.isSelected)) {
      treeNodesStore.selectedTreeNode = rectangleNode;
    }

    // Apply tool options
    /*const toolOptions = drawToolsStore.drawToolsOptions.get(selectedTool) as DrawToolsOption[];
    toolOptions.forEach(option => {
      if (surfaceMeasurements.map(measurement => measurement.id).includes(option.measurementType)) {
        const surfaceFormula = drawToolsStore.surfaceNodeBeingAdded.measurementValues.get(option.measurementType);
        surfaceFormula.formula = option.formula.formula;
      }
    });*/
  }

  componentDidMount() {
    const { settingsStore } = this.context;
    this.svgTag.addEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    window.addEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove);
    window.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    const { settingsStore } = this.context;

    this.svgTag.removeEventListener(settingsStore.isTouchDevice ? 'touchend' : 'click', this.mouseDown);
    window.removeEventListener(settingsStore.isTouchDevice ? 'touchmove' : 'mousemove', this.mouseMove);
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.finalizeClosedShape();
    }
  }

  // should use DrawToolsStore.finalizeClosedShape!
  async finalizeClosedShape(batchParam?: WriteBatch) {
    const { drawToolsStore, commonStore, shapesStore, treeNodesStore } = this.context;

    const batch = batchParam || firestoreBatch(this.context);

    const surfaceToSubtract = drawToolsStore.surfaceNodeBeingAdded.shape as Surface;

    const segmentsNodes = drawToolsStore.segmentsGroupBeingAdded.children;
    const segmentsShapes = segmentsNodes.map(node => node.shape as Line);

    // duplicate!
    if (isClockwise(drawToolsStore.surfaceNodeBeingAdded.shape.points)) {
      segmentsNodes.forEach(wallNode => {
        const wallToChange = wallNode.shape as Wall;
        const tmpPoint = wallToChange.points[0];
        wallToChange.points[0] = wallToChange.points[1];
        wallToChange.points[1] = tmpPoint;
      });

      drawToolsStore.surfaceNodeBeingAdded.shape.points = drawToolsStore.surfaceNodeBeingAdded.shape.points.slice(0).reverse();
      shapesStore.batchAddEditItem(drawToolsStore.surfaceNodeBeingAdded.shape, batch);
    }

    const isDrawnTopFirst = segmentsShapes[0].startPt.y < segmentsShapes[2].startPt.y;

    segmentsNodes[0].shape.tags.push(isDrawnTopFirst ? ShapeTags.RectangleTop : ShapeTags.RectangleBottom);
    segmentsNodes[1].shape.tags.push(ShapeTags.RectangleSide);
    segmentsNodes[2].shape.tags.push(isDrawnTopFirst ? ShapeTags.RectangleBottom : ShapeTags.RectangleTop);
    segmentsNodes[3].shape.tags.push(ShapeTags.RectangleSide);

    shapesStore.batchAddEditItems(segmentsShapes, batch);

    if (!batchParam) {
      batch.commit();
    }

    commonStore.isBigUpdateOngoing = false;

    drawToolsStore.segmentsGroupBeingAdded = null;
    drawToolsStore.surfaceNodeBeingAdded = null;

    const { satelliteImageUrl, backgroundImage } = treeNodesStore.rootNode

    // recenter first drawn surface
    if (treeNodesStore.rootNode.surfaceShapes.length === 1 && !satelliteImageUrl && !backgroundImage) {
      // NOPE bad idea
     // shapesStore.zoomController?.zoomOutOnShapes?.(1.15);
    }

    await askToSubtractSurfaceIfNeeded([surfaceToSubtract]);

    // avoid selecting wrong place
    // setTimeout(() => drawToolsStore.selectedTool = DrawToolType.Select, 500);
  }

  @action.bound
  mouseDown(ev: MouseEvent) {
    ev = normalizeMouseOrTouchEvent(ev, this.context);

    const { drawToolsStore, commonStore, treeNodesStore, shapesStore, settingsStore } = this.context;
    if (
      ev.ctrlKey ||
      ev.button || // button is either 0 for left click or undefined for touch event
      shapesStore.isDragPanningOnDrawing ||
      getSafe(() => (ev.target as HTMLElement).tagName === 'INPUT')
    ) {
      return;
    }

    const batch = firestoreBatch(this.context);

    drawToolsStore.shouldLocklineBeingAddedLength = false;

    commonStore.isBigUpdateOngoing = true;

    const { selectedTool } = drawToolsStore;
    const { rootNode, selectedTreeNode } = treeNodesStore;

    const currentPt = cursorToLocalPoint(ev, this.svgTag);

    const cursorPt = (
      !drawToolsStore.lastMousePt ||
      drawToolsStore.lastMousePt.distance(currentPt, shapesStore.zoomController.scale) > 10
    )
      ? cursorToLocalPoint(ev, this.svgTag)
      : drawToolsStore.lastMousePt;

    if (selectedTreeNode !== rootNode && !selectedTreeNode.hasChildren) {
      selectedTreeNode.isExpanded = false;
    }

    let parentNode = selectedTreeNode || rootNode;

    while (!parentNode.areChildrenAllowed || parentNode.name == i18n.t('Rectangle')) {
      parentNode = parentNode.parent;
    }

    parentNode = parentNode || rootNode;

    if (!drawToolsStore.segmentsGroupBeingAdded) {
      this.createSegmentsGroup(parentNode, batch, cursorPt);
    } else {
      // save shapes and nodes
      const lines = drawToolsStore.segmentsGroupBeingAdded.shapes as Wall[];
      lines.forEach(line => {
        line.startPt.isSnappable = true;
        line.endPt.isSnappable = true;
      });

      shapesStore.batchAddEditItems(lines, batch);
      shapesStore.batchAddEditItem(drawToolsStore.surfaceNodeBeingAdded.shape, batch);

      this.finalizeClosedShape(batch);
    }

    batch.commit();
  }


  @action.bound
  mouseMove(ev: MouseEvent) {
    this.mouseMoveThrottled(ev);
  }

  @action
  mouseMoveThrottled = throttle((ev: MouseEvent) => {
    ev = normalizeMouseOrTouchEvent(ev, this.context);

    const { snapStore, drawToolsStore, commonStore, settingsStore, userInfoStore } = this.context;

    let cursorPt = cursorToLocalPoint(ev, this.svgTag);
    snapStore.cursorPosition = new Point(cursorPt.x, cursorPt.y);

    drawToolMouseMoveThrottledBase(ev, this.svgTag, this.context);

    const { snapPoint, snapLineHorizontal, snapLineVertical } = snapStore;
    // duplicate
    if (snapLineHorizontal) {
      cursorPt = new Point(
        cursorPt.x,
        snapLineHorizontal.points[1].y,
      );
    }

    if (snapLineVertical) {
      cursorPt = new Point(
        snapLineVertical.points[1].x,
        cursorPt.y,
      );
    }

    cursorPt = snapPoint?.clone?.() || cursorPt;
    cursorPt.isSnappable = false;

    drawToolsStore.lastMousePt = cursorPt;

    if (!drawToolsStore.segmentsGroupBeingAdded) {
      return;
    }

    if (!commonStore.isBigUpdateOngoing) {
      commonStore.isBigUpdateOngoing = true;
    }

    // distance snap, move outside?
    if (userInfoStore.user?.isDrawingSnapPointEnabled) {
      const startPt = (drawToolsStore.segmentsGroupBeingAdded.shapes[0] as Wall).startPt;

      const isImperial = settingsStore.isImperial;
      const scale = DRAWING_SCALE;
      const deltaX = (cursorPt.x - startPt.x);
      const deltaY = (cursorPt.y - startPt.y);

      const snapDistanceStep = isImperial
        ? 1 / INCHES_PER_METER * scale // snap to inch
        : 0.05 * scale // 5 cm

      const snappedDeltaX = roundToNearestStep(deltaX, snapDistanceStep);
      const snappedDeltaY = roundToNearestStep(deltaY, snapDistanceStep);

      if (!snapLineVertical) {
        cursorPt.x = startPt.x + snappedDeltaX;
      }

      if (!snapLineHorizontal) {
        cursorPt.y = startPt.y + snappedDeltaY;
      }
      drawToolsStore.lastMousePt = cursorPt;
    }

    //end snap distance

    const lines = drawToolsStore.segmentsGroupBeingAdded.shapes as Wall[];

    // line 0 top line, line 1 right side, continue clockwise
    lines[0].endPt.x = cursorPt.x;
    lines[1].startPt.x = cursorPt.x;
    lines[1].endPt.x = cursorPt.x;
    lines[1].endPt.y = cursorPt.y;

    lines[2].startPt = lines[1].endPt.clone();
    lines[2].endPt.y = cursorPt.y;

    lines[3].startPt.y = cursorPt.y;

    drawToolsStore.surfaceNodeBeingAdded.shape.points = lines.map(line => (line as Wall).startPt.clone());
  }, MOUSE_MOVE_THROTTLE_TIME)

  _render() {
    return (
      <g id="AddRectangleTool" ref={ref => this.svgTag = getSafe(() => ref.ownerSVGElement) || this.svgTag} />
    );
  }
}