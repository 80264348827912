import { copyTasksFromListToProject, copyTasksFromProjectToList } from 'components/common/TasksListsList/TasksListUtil';
import { DbLocationType } from 'constants/DbLocationType';
import Globals from 'Globals';
import { compact, isEmpty } from 'lodash';
import { observable, reaction } from 'mobx';
import TasksList from 'models/TasksList';
import TreeNode from 'models/TreeNode';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import i18n from 'utils/i18n';
import { sleep } from 'utils/Utils';
import SearchableFirebaseStore from './SearchableFirebaseStore';

export default class TasksListsStore extends SearchableFirebaseStore<TasksList> {
  storeKey = 'projects';

  dbLocationsTypes = new Set(compact([
    DbLocationType.Master,
    DbLocationType.User,
    this.accountParam && DbLocationType.ExternalUser,
  ]));

  shouldTrackCreationDate = true;
  shouldTrackModifDate = true;

  shouldSoftDelete = true;

  queryFilters = new Map([['type', 'TasksList']])

  @observable tasksListBeingEdited: TasksList = null;

  listComponentRef = null;

  @observable isCopyingList = false;

  async DEBUG__getAllItemsUsedInTasksLists() {
    const itemsIds = new Set();

    await this.items.reduce(async (previousTask, tasksList) => {
      await previousTask;
      const tasks = await this.db.collection(`${this.dbLocation}/${tasksList.id}/tasks`).get();

      tasks.docs.forEach(task => {
        itemsIds.add(task.data().providingItemId);
      })

      sleep(100);
      return '';
    }, Promise.resolve(''))

    debugger;
    return Array.from(itemsIds);
  }

  async DEBUG___CopyAllProjectTasksToLists() {
    const { treeNodesStore } = this.stores;

    await [treeNodesStore.rootNode, ...treeNodesStore.rootNode.nonDrawingDescendants].reduce(async (previousTask, node) => {
      await previousTask;

      // using same id for projet node and taskslist, just easier to update tasklist this way from a project
      let targetTasksList = this.getItem(node.id);

      if (!targetTasksList) {
        targetTasksList = new TasksList(this.stores);
        targetTasksList.id = node.id;
        targetTasksList.name = node.name;
      }

      if (!isEmpty(node.ownTasks)) {
        await copyTasksFromProjectToList(this.stores, node, node.ownTasks, targetTasksList);
      }

      return '';
    }, Promise.resolve(''));
  }

  async duplicateItem(item: TasksList) {
    const tempNode = new TreeNode(this.stores);

    const targetTasksList = new TasksList(this.stores);
    targetTasksList.categoryId = item.categoryId;
    targetTasksList.subcategoryId = item.subcategoryId;
    targetTasksList.index = item.index;
    targetTasksList._name = { ...item._name };
    targetTasksList.name = item.name + ' ' + i18n.t('(Copy in progress...)');

    const batch = firestoreBatch(this.stores);
    this.batchAddEditItem(targetTasksList, batch);
    
    await copyTasksFromListToProject(item, tempNode, undefined, undefined, batch);
    this.isCopyingList = true;
    await copyTasksFromProjectToList(Globals._listStores, tempNode, tempNode.ownTasks, targetTasksList, batch);
    if (this.tasksListBeingEdited) {
      this.tasksListBeingEdited = null;
    }

    // cleanup temporary tasks
    const {tasksStore} = this.stores;
    tasksStore.deleteItems(tempNode.ownTasksIds, true, batch);
    
    targetTasksList.name = targetTasksList.name.replace(i18n.t('(Copy in progress...)'), i18n.t('(Copy)'));
    this.addEditItem(targetTasksList, true, ['_name'], batch);

    await batch.commit();
    
    this.isCopyingList = false;
  }


  // this ensures this store loads last (long to load)
  attemptLoadItems(shouldForce = false) {
    const { providingItemsStore, userInfoStore } = this.stores;
    const { user } = userInfoStore;

    if (!providingItemsStore.isReady) {
      return;
    }

    if (user?.shouldUseBareMinimumMasterItems && this.dbLocationsTypes.has(DbLocationType.Master)) {
      this.dbLocationsTypes.delete(DbLocationType.Master);
    }

    super.attemptLoadItems(shouldForce);
  }

  // could be good but prevent  to load on projects list ;page
  priorityLoadCheck = reaction(() => (
    this.stores?.providingItemsStore?.isReady &&
    this.stores?.routerStore?.queryParamsSettings?.get?.('mode') !== 'rescue'
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
