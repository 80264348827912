import { BuiltinMeasurementSubcategories } from 'constants/BuiltinCategories';
import { FormulaType } from 'constants/FormulaType';
import { MeasurementType } from "constants/MeasurementType";
import { Unit } from "constants/Unit";
import { UnitType } from "constants/UnitType";
import { compact, flatten, uniq, uniqBy } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from 'mobx-utils';
import { serializable } from "serializr";
import Stores from 'stores/Stores';
import { escapeVar, formulaHasVariables, getFormulaVariables } from 'utils/MeasurementFormatter';
import { getFormulaType, getMeasurementFromVariable } from 'utils/MeasurementUtil';
import { InputNumberFormatBase } from 'utils/NumberFormatter';
import { formatUnit } from 'utils/UnitFormatter';
import { getSafe } from 'utils/Utils';
import i18n from 'utils/i18n';
import { localized } from 'utils/localized';
import Category from './Category';
import ModelBaseWithCategory from './ModelBaseWithCategories';

// What can be confusing is this object represents what is stored in DB,
// so it is one object per project. But it can have different values (formula) depending
// in which TreeNode we call it from
export default class Measurement extends ModelBaseWithCategory {
  type = 'Measurement';

  // hopefully temporary way to draw providingitems directly on drawing
  @observable @serializable providingItemMeasurementId = '';

  shouldCheckMinMaxUserVersion = true;

  @observable @serializable legendColor: string = '';

  constructor(stores: Stores) {
    super(stores);
    this.name = i18n.t('Quantity');
    this.defaultFormula = '0';
  }

  @computed get isReadonly() {
    const { userInfoStore, measurementsStore } = this.stores;

    return (
      this._isReadonly ||
      !this.isOneTimeUse && (
        this.owner !== userInfoStore.nonImpersonatedEmail && !userInfoStore.isAdmin || (
          !userInfoStore.canChangeEvalumoItems && (
            this.owner === 'evalumo' ||
            Array.from(this.cascadeOrders)
              .some(cascadeOrder => measurementsStore.nonOverridableDbLocationsTypes.has(measurementsStore.sortedDbLocationsTypes[cascadeOrder]))
          ))
      )
    );
  }

  set isReadonly(value) {
    this._isReadonly = !!value;
  }

  @computed get isDeletedInUserAccount() {
    const {userInfoStore, measurementsStore} = this.stores;
    return (
      this.owner !== userInfoStore.nonImpersonatedEmail &&
      this.cascadeOrders.size == 1 &&
      this.cascadeOrders.has(measurementsStore.collections.indexOf(measurementsStore.projectCollection))
    );
  }

  @computed get canChangeReadonly() {
    const { userInfoStore, measurementsStore } = this.stores;

    // keep in mind owner is not always set in previous versions so need fallback rules
    return (
      (
        this.owner === userInfoStore.nonImpersonatedEmail ||
        userInfoStore.isAdmin
      ) && (
        userInfoStore.canChangeEvalumoItems || !(this.owner === 'evalumo' || this.cascadeOrders.has(0))
      ) && (
        userInfoStore.canChangeEvalumoItems || !Array.from(this.cascadeOrders)
          .some(cascadeOrder => measurementsStore.nonOverridableDbLocationsTypes.has(measurementsStore.sortedDbLocationsTypes[cascadeOrder]))
      ));
  }

  @computed get subsubcategory(): Category {
    const { categoriesStore, treeNodesStore } = this.stores;

    const formulaType = getSafe(() => treeNodesStore.selectedTreeNode.measurementValues.get(this.id).index) || this.defaultFormulaType;

    return formulaType === FormulaType.ComputedParameter
      ? categoriesStore.getItem(BuiltinMeasurementSubcategories.OutputMeasurements)
      : categoriesStore.getItem(BuiltinMeasurementSubcategories.InputMeasurements);
  }

  @observable @serializable measurementType: MeasurementType = MeasurementType.General;

  @localized _defaultFormula: string;

  @computed get defaultFormula(): string {
    return this._defaultFormula;
  }
  set defaultFormula(value: string) {
    if (!formulaHasVariables(value)) {
      this.__defaultFormula = { 'en': value };
    }

    this._defaultFormula = value;
  }

  @observable @serializable shouldBubbleDownByDefault = false;

  @observable @serializable unitType: UnitType = UnitType.Surface;

  @localized description: string = '';

  // example: all wall lengths/surface should add up to give parent total
  // but some measurements like spacing of studs cannot be added, it's the same value
  // no matter how many there are
  @observable @serializable isSummable: boolean = true;

  @observable @serializable _shouldApplyWaste: boolean = true;

  @serializable @observable shouldAutoToggleDependencies: boolean = true;

  @serializable @observable shouldSetToZeroWhenImportingTasksList: boolean = false;

  @serializable @observable shouldShowInReportHeaders: boolean = false;

  @serializable @observable canBeToggledFromToolbar: boolean = true;

  @computed get shouldApplyWaste(): boolean {
    return this.unitType !== UnitType.FixedPrice && this._shouldApplyWaste;
  }

  set shouldApplyWaste(value: boolean) {
    this._shouldApplyWaste = value;
  }

  @computed get shouldApplyTreeNodeQuantitiesMultiplier() {
    return this.isSummable;
  }

  // for those measurements that don't have a specific name to be reused outside of
  // a particular task. (ie. Name is "Quantity")
  // but don't want to only check if name is Quantity because it gets complicated with translations
  // might be quantity in one language only
  @observable @serializable isOneTimeUse: boolean = false;

  @observable @serializable _displayUnit: Unit = Unit.DefaultMetric;
  //@observable @serializable isVisible: boolean = true;

  @computed get defaultFormulaType(): FormulaType {
    return getFormulaType(this.defaultFormula);
  }

  get measurementDependencies(): Measurement[] {
    if (!this.shouldAutoToggleDependencies) {
      return [];
    }
    return this.getMeasurementDependencies();
  }

  get measurementDependenciesIncludingOptional(): Measurement[] {
    return this.getMeasurementDependencies();
  }

  getMeasurementDependencies = computedFn((): Measurement[] => {
    const { measurementsStore } = this.stores;
    const directDependencies = uniq(compact(flatten(
      getFormulaVariables(this.defaultFormula)
        .map(variable => getMeasurementFromVariable(escapeVar(variable), measurementsStore.itemsAndHiddenItems, this))
    )));

    const indirectDependencies = flatten(directDependencies
      .filter(directDependency => directDependency !== this)
      .map(measurementDependency => {
        let retval = [];
        try {
          retval = measurementDependency.measurementDependencies;
        } catch (e) {
          console.error('Measurement circular dependencies between ', this.category.name + '/' + this.name, ' and ', measurementDependency.category.name + '/' + measurementDependency.name);
        }
        return retval;
      })
    );

    return uniqBy([
      ...directDependencies,
      ...indirectDependencies,
    ], 'id');
  })

  // display unit, because everthing is stored in meters (squared, etc) but not
  // when displayed
  @computed get displayUnit(): Unit {
    const { settingsStore } = this.stores;
    if (this._displayUnit !== Unit.DefaultMetric) {
      return this._displayUnit;
    }

    // default units

    // independent from imperial or metric
    switch (this.unitType) {
      case UnitType.Unit:
        return Unit.Unit;
      case UnitType.Pack:
        return Unit.Pack;
      case UnitType.Angle:
        return Unit.Degree;
      case UnitType.RoofSlope:
        return Unit.Ratio;
      case UnitType.Projection:
        return Unit.Unitless;
      case UnitType.FixedPrice:
        return Unit.CurrencySign;
      case UnitType.Time:
        return Unit.Hour;
    }

    // Imperial
    if (settingsStore.isImperial) {
      switch (this.unitType) {
        case UnitType.Surface:
          return Unit.SquareFoot;
        case UnitType.Volume:
          return Unit.CubicFoot;
        default:
          return Unit.Foot;
      }
    }

    // Metric
    switch (this.unitType) {
      case UnitType.Surface:
        return Unit.SquareMeter;
      case UnitType.Volume:
        return Unit.CubicMeter;
      default:
        return Unit.Meter;
    }
  }

  set displayUnit(value: Unit) {
    this._displayUnit = value;
  }

  // convenience memoized formatter, because if not memoized, the textfield will not
  // remember cursor position
  @computed get inputFormatter() {
    return InputNumberFormatBase(formatUnit(this.displayUnit));
  }
}
