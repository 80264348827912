import { Unit } from "constants/Unit";

export const FEET_PER_METER = (3937 / 1200);
export const CM_PER_METER = 100;
export const MM_PER_METER = 1000;
export const KM_PER_METER = 0.001;
export const INCHES_PER_FOOT = 12;
export const YARDS_PER_FOOT = 1 / 3;
export const INCHES_PER_METER = FEET_PER_METER * INCHES_PER_FOOT;
export const YARDS_PER_METER = FEET_PER_METER * YARDS_PER_FOOT;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_MINUTE = 60;
export const HOURS_PER_DAY = 8;
export const DAYS_PER_WEEK = 5;
export const DAYS_PER_MONTH = 21; // working days
export const MONTHS_PER_YEAR = 12;
export const BRIT_TON_PER_METRIC_TON = 1.016047;
export const US_TON_PER_METRIC_TON = 0.9071847;
export const KILOGRAM_PER_METRIC_TON = 1000;
export const KILOGRAM_PER_POUND = 0.45359237;
export const LITERS_PER_CUBIC_METER = 1000;
export const USGALLONS_PER_CUBIC_METER = 264.172;
export const US5GALLONS_PER_CUBIC_METER = USGALLONS_PER_CUBIC_METER / 5;

// no conversion for angle yet! degree is not really metric

export default class MeasurementConverter {
  // base unit is meter, square meter or cubic meter
  static convert(fromValue: number, fromUnit: Unit, toUnit: Unit): number {
    // can only convert between compatible units
    //if (fromUnit !== Unit.DefaultMetric && getUnitTypeForUnit(fromUnit) !== getUnitTypeForUnit(toUnit)) {
    // return 0;
    //}

    let metricValue = 0;

    switch (fromUnit) {
      case Unit.Unit:
      case Unit.Budget:
      case Unit.Allocation:
      case Unit.Truck:
      case Unit.Step:
      case Unit.Unitless:
      case Unit.Box:
      case Unit.Roll:
      case Unit.TimeBlock:
      case Unit.CurrencySign:
      case Unit.Pack: // maybe could convert from unit
        // don't try to convert Unit into other types of measures
        return fromValue;

      case Unit.DefaultMetric:
      case Unit.Meter:
      case Unit.SquareMeter:
      case Unit.CubicMeter:
      case Unit.Hour:
      case Unit.Kilogram:
      case Unit.Radian:
        metricValue = fromValue;
        break;

      case Unit.Percent:
        metricValue = fromValue / 100;
        break;

      case Unit.Foot:
        metricValue = fromValue / FEET_PER_METER;
        break;
      case Unit.Inch:
        metricValue = fromValue / INCHES_PER_FOOT / FEET_PER_METER;
        break;
      case Unit.Milimeter:
        metricValue = fromValue / MM_PER_METER;
        break;
      case Unit.Kilometer:
        metricValue = fromValue / KM_PER_METER;
        break;
      case Unit.Centimeter:
        metricValue = fromValue / CM_PER_METER;
        break;
      case Unit.SquareFoot:
        metricValue = fromValue / Math.pow(FEET_PER_METER, 2);
        break;
      case Unit.SquareInch:
        metricValue = fromValue / Math.pow(INCHES_PER_METER, 2);
        break;
      case Unit.SquareYard:
        metricValue = fromValue / Math.pow(YARDS_PER_METER, 2);
        break;
      case Unit.CubicFoot:
        metricValue = fromValue / Math.pow(FEET_PER_METER, 3);
        break;
      case Unit.CubicYard:
        metricValue = fromValue / Math.pow(FEET_PER_METER, 3) * 27;
        break;
      case Unit.Liter:
        metricValue = fromValue / LITERS_PER_CUBIC_METER;
        break;
      case Unit.USGallon:
        metricValue = fromValue / USGALLONS_PER_CUBIC_METER;
        break;
      case Unit.US5Gallon:
        metricValue = fromValue / US5GALLONS_PER_CUBIC_METER;
        break;
      case Unit.Minute:
        metricValue = fromValue / MINUTES_PER_HOUR;
        break;

      case Unit.BritTon:
        metricValue = fromValue / BRIT_TON_PER_METRIC_TON * KILOGRAM_PER_METRIC_TON;
        break;

      case Unit.USTon:
        metricValue = fromValue / US_TON_PER_METRIC_TON * KILOGRAM_PER_METRIC_TON;
        break;

      case Unit.MetricTon:
        metricValue = fromValue * KILOGRAM_PER_METRIC_TON;
        break;

      case Unit.Pound:
        metricValue = fromValue * KILOGRAM_PER_POUND;
        break;


      // Hours are multiplied, and others are divided
      case Unit.Day:
        metricValue = fromValue * HOURS_PER_DAY;
        break;

      case Unit.Week:
        metricValue = fromValue * HOURS_PER_DAY * DAYS_PER_WEEK;
        break;

      case Unit.Month:
        metricValue = fromValue * HOURS_PER_DAY * DAYS_PER_MONTH;
        break;

      case Unit.Year:
        metricValue = fromValue * HOURS_PER_DAY * DAYS_PER_MONTH * MONTHS_PER_YEAR;
        break;

      case Unit.Degree:
        metricValue = fromValue / 180 * Math.PI;
        break;

      case Unit.Ratio: // for plan projection in roofs
        metricValue = fromValue / 12;
        break;

      case Unit.SlopePercentage:
        metricValue = fromValue / 100;
        break;
    }

    switch (toUnit) {
      case Unit.DefaultMetric:
      case Unit.Unit:
      case Unit.Budget:
      case Unit.Allocation:
      case Unit.Truck:
      case Unit.Step:
      case Unit.Pack:
      case Unit.TimeBlock:
      case Unit.Box:
      case Unit.Roll:
      case Unit.Meter:
      case Unit.SquareMeter:
      case Unit.CubicMeter:
      case Unit.Hour:
      case Unit.Kilogram:
      case Unit.Radian:
      case Unit.Unitless:
      case Unit.CurrencySign:
        return metricValue;

      case Unit.Percent:
        return metricValue * 100;

      case Unit.Foot:
        return metricValue * FEET_PER_METER;
      case Unit.Inch:
        return metricValue * FEET_PER_METER * INCHES_PER_FOOT;
      case Unit.Centimeter:
        return metricValue * CM_PER_METER;
      case Unit.Kilometer:
        return metricValue * KM_PER_METER;
      case Unit.Milimeter:
        return metricValue * MM_PER_METER;
      case Unit.SquareFoot:
        return metricValue * Math.pow(FEET_PER_METER, 2);
      case Unit.SquareInch:
        return metricValue * Math.pow(INCHES_PER_METER, 2);
      case Unit.SquareYard:
        return metricValue * Math.pow(YARDS_PER_METER, 2);
      case Unit.CubicFoot:
        return metricValue * Math.pow(FEET_PER_METER, 3);
      case Unit.CubicYard:
        return metricValue * Math.pow(FEET_PER_METER, 3) / 27;
      case Unit.Liter:
        return metricValue * LITERS_PER_CUBIC_METER;
      case Unit.USGallon:
        return metricValue * USGALLONS_PER_CUBIC_METER;
      case Unit.US5Gallon:
        return metricValue * US5GALLONS_PER_CUBIC_METER;
      case Unit.Minute:
        return metricValue * MINUTES_PER_HOUR;
      case Unit.BritTon:
        return metricValue * BRIT_TON_PER_METRIC_TON / KILOGRAM_PER_METRIC_TON;
      case Unit.USTon:
        return metricValue * US_TON_PER_METRIC_TON / KILOGRAM_PER_METRIC_TON;
      case Unit.MetricTon:
        return metricValue / KILOGRAM_PER_METRIC_TON;
      case Unit.Pound:
        return metricValue / KILOGRAM_PER_POUND;

      case Unit.Day:
        return metricValue / HOURS_PER_DAY;
      case Unit.Week:
        return metricValue / HOURS_PER_DAY / DAYS_PER_WEEK;
      case Unit.Month:
        return metricValue / HOURS_PER_DAY / DAYS_PER_MONTH;
      case Unit.Year:
        return metricValue / HOURS_PER_DAY / DAYS_PER_MONTH / MONTHS_PER_YEAR;

      case Unit.Degree:
        return metricValue * 180 / Math.PI;

      case Unit.Ratio:
        return metricValue * 12;

      case Unit.SlopePercentage:
        return metricValue * 100;

      default:
        return NaN;
    }
  }
}