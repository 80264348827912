
import { FilledInput, FormControl, IconButton, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';
import { ReportGrouping, ReportHeaderMeasurementsVisibility, ReportSortType, ReportTasksVisibility, ReportTotalsGrouping, ReportTotalsVisibility } from 'constants/ReportOptionsConstants';
import { defer } from 'lodash';
import Report from 'models/Report';
import * as React from 'react';
import { handleChangeAndSave, handleCheckboxChangeAndSave } from 'utils/FormUtils';
import { reportHasOnlyOneGroup } from 'utils/ReportUtil';
import { applyXLSXTrialLimits, deleteXLSXWorksheetHiddenRows } from 'utils/XLSXUtils';
import i18n from 'utils/i18n';
import XLSX from 'xlsx';
import { ExcelIcon, TextDecreaseIcon, TextIncreaseIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';
import ReportDate from '../ReportDate/ReportDate';
import ReportTasksFilterComponent from '../ReportTasksFilterComponent/ReportTasksFilterComponent';

const styles = require('./ReportContentListOptions.module.scss');

interface ReportContentListOptionsProps {
  report: Report,
}

export default class ReportContentListOptions extends ObserverComponent<ReportContentListOptionsProps> {
  exportToExcel = () => {
    const { projectsStore, reportsStore, subscriptionsStore } = this.context;
    const { report } = this.props;

    reportsStore.isGeneratingExcel = true;

    defer(() => {
      const table = document.querySelector('.hiddenOutside .ReportContentList');

      const workbook = XLSX.utils.table_to_book(table);
      workbook.Sheets.Sheet1['!cols'] = [{ wch: 100 }];
      workbook.Sheets.Sheet1['!merges'] = [];

      deleteXLSXWorksheetHiddenRows(workbook.Sheets.Sheet1);

      if (subscriptionsStore.isTrial) {
        applyXLSXTrialLimits(workbook.Sheets.Sheet1);
      }

      XLSX.writeFile(workbook, `${projectsStore.selectedProject?.name}.xlsx`);

      reportsStore.isGeneratingExcel = false;
    })
  }

  _render() {
    const { reportsStore, userInfoStore } = this.context;
    const { report } = this.props;
    const { user } = userInfoStore;
    if (!report || !user) {
      return null;
    }

    return (
      <div className={styles.root}>
        {user.canChangeReportFontSize && (
          <>
            <div>
              <IconButton onClick={() => {
                user.reportFontSizeScale -= 0.05;
                userInfoStore.addEditItemDebounced(user, true, ['reportFontSizeScale']);
              }}>
                <TextDecreaseIcon />
              </IconButton>
              <IconButton onClick={() => {
                user.reportFontSizeScale += 0.05;
                userInfoStore.addEditItemDebounced(user, true, ['reportFontSizeScale']);
              }}>
                <TextIncreaseIcon />
              </IconButton>
            </div>
            <div style={{ width: '100%', flex: 'none' }}></div>
          </>
        )}


        <FormControl key="optionItem0" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Grouping Order')}</InputLabel>
          <Select
            value={report.grouping}
            onChange={handleChangeAndSave(report, 'grouping', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={ReportGrouping.TreeNodeThenCategory}>{i18n.t('Location > Category')}</MenuItem>
            <MenuItem value={ReportGrouping.CategoryThenTreeNode}>{i18n.t('Category > Location')}</MenuItem>
            <MenuItem value={ReportGrouping.TreeNode}>{i18n.t('Location')}</MenuItem>
            <MenuItem value={ReportGrouping.Category}>{i18n.t('Category')}</MenuItem>
            <MenuItem value={ReportGrouping.None}>{i18n.t('No Group')}</MenuItem>
          </Select>
        </FormControl>
        {/*[
        ReportGrouping.TreeNode,
        ReportGrouping.TreeNodeThenCategory,
        ReportGrouping.CategoryThenTreeNode,
      ].includes(report.grouping) && (
      [*/}
        <FormControl key="optionItem1" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Location Depth')}</InputLabel>
          <Select
            value={report.treeMaxDepth}
            onChange={handleChangeAndSave(report, 'treeMaxDepth', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={1}>{i18n.t('1 (e.g.: 2nd Floor)')}</MenuItem>
            <MenuItem value={2}>{i18n.t('2 (e.g.: 2nd Floor > Walls)')}</MenuItem>
            <MenuItem value={3}>{i18n.t('3 (e.g.: 2nd Floor > Apt. #2 > Walls)')}</MenuItem>
            <MenuItem value={999}>{i18n.t('Maximum depth')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl key="optionItem2" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Header Measurements')}</InputLabel>
          <Select
            value={report.headerMeasurementsVisibility}
            onChange={handleChangeAndSave(report, 'headerMeasurementsVisibility', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={ReportHeaderMeasurementsVisibility.Visible}>{i18n.t('Show with reference drawings')}</MenuItem>
            <MenuItem value={ReportHeaderMeasurementsVisibility.VisibleWithoutDrawing}>{i18n.t('Show measurement values only')}</MenuItem>
            <MenuItem value={ReportHeaderMeasurementsVisibility.Hidden}>{i18n.t('Hide')}</MenuItem>
          </Select>
        </FormControl>


        <FormControl key="optionItem3" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Tasks Visibility')}</InputLabel>
          <Select
            value={report.tasksVisibility}
            onChange={handleChangeAndSave(report, 'tasksVisibility', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={ReportTasksVisibility.ShowQuantities}>{i18n.t('Show tasks quantities')}</MenuItem>
            <MenuItem value={ReportTasksVisibility.HideQuantities}>{i18n.t('Hide tasks quantities')}</MenuItem>
            <MenuItem value={ReportTasksVisibility.Hidden}>{i18n.t('Hide all tasks')}</MenuItem>
          </Select>
        </FormControl>

        <div style={{ width: '100%', flex: 'none' }}></div>

        <ReportTasksFilterComponent key="optionitem4" isEditable report={report} />

        {/*report.tasksVisibility !== ReportTasksVisibility.Hidden && (*/}

        <FormControl key="optionItem5" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Totals Visibility')}</InputLabel>
          <Select
            value={report.totalsVisibility}
            onChange={handleChangeAndSave(report, 'totalsVisibility', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={ReportTotalsVisibility.Task}>{i18n.t('Show individual tasks cost')}</MenuItem>
            {!reportHasOnlyOneGroup(report) && <MenuItem value={ReportTotalsVisibility.GroupNotSubgroup}>{i18n.t('Show main group total only')}</MenuItem>}
            <MenuItem value={ReportTotalsVisibility.Group}>{reportHasOnlyOneGroup(report) ? i18n.t('Show group total only') : i18n.t('Show group and subgroup total only')}</MenuItem>
            <MenuItem value={ReportTotalsVisibility.Project}>{i18n.t('Show project total only')}</MenuItem>
            <MenuItem value={ReportTotalsVisibility.None}>{i18n.t('No totals')}</MenuItem>
          </Select>
        </FormControl>


        {/*report.totalsVisibility !== ReportTotalsVisibility.Project && !isFilterAffectingTotal(report.tasksFilter) && (*/}
        <FormControl key="optionItem6" className={styles.textField} margin="normal">
          <InputLabel>{i18n.t('Totals Details')}</InputLabel>
          <Select
            value={report.totalsGrouping}
            onChange={handleChangeAndSave(report, 'totalsGrouping', reportsStore)}
            input={<FilledInput />}
          >
            <MenuItem value={ReportTotalsGrouping.DetailedWithoutHours}>{i18n.t('Material, Labour and misc., Subtotal')}</MenuItem>
            {/*<MenuItem value={ReportTotalsGrouping.DetailedWithHours}>{i18n.t('Material, Labour and misc. ($ and h), Subtotal')}</MenuItem>*/}
            <MenuItem value={ReportTotalsGrouping.SubtotalOnly}>{i18n.t('Subtotal only')}</MenuItem>
          </Select>
        </FormControl>

        {/*  report.tasksVisibility !== ReportTasksVisibility.Hidden && (*/}
        <div className={styles.lastItem}>
          <FormControl key="optionItem7" className={styles.textField} margin="normal">
            <InputLabel>{i18n.t('Sorting')}</InputLabel>
            <Select
              value={report.sortBy}
              onChange={handleChangeAndSave(report, 'sortBy', reportsStore)}
              input={<FilledInput />}
            >
              <MenuItem value={ReportSortType.NoSort}>{i18n.t('Original sort order')}</MenuItem>
              <MenuItem value={ReportSortType.MaterialFirst}>{i18n.t('Material first')}</MenuItem>
              <MenuItem value={ReportSortType.MaterialLast}>{i18n.t('Material last')}</MenuItem>
              <MenuItem value={ReportSortType.PriceList}>{i18n.t('Price List order')}</MenuItem>
              <MenuItem value={ReportSortType.Alphabetical}>{i18n.t('Alphabetical')}</MenuItem>
            </Select>
          </FormControl>

          <IconButton className={styles.excelButton} onClick={this.exportToExcel} key="optionItem8">
            <ExcelIcon />
          </IconButton>
        </div>

        {user.canHighlightPriceChanges && (
          <div className={styles.switchContainer}>
            <Switch
              className={styles.switch}
              checked={report.shouldHighlightPriceChanges}
              onChange={handleCheckboxChangeAndSave(report, 'shouldHighlightPriceChanges', reportsStore)}
            />

            <span className={styles.label}>{i18n.t('Indicate price changes since this date')} :</span>

            <ReportDate className={styles.dateInput} dateField='highlightPriceChangeDateMiliseconds' report={report} isEditable disableFuture />
          </div>
        )}

        {user.canShowReportTimeTotals && (
          <div className={styles.switchContainer}>
            <Switch
              className={styles.switch}
              checked={report.shouldShowReportTimeTotals}
              onChange={handleCheckboxChangeAndSave(report, 'shouldShowReportTimeTotals', reportsStore)}
            />

            <span className={styles.label}>{i18n.t('Show time totals')}</span>
          </div>
        )}
      </div>
    )
  }
}