import ConfirmDialog from "components/common/ConfirmDialog/ConfirmDialog";
import { first, isEmpty } from "lodash";
import { flow } from "mobx";
import Dialog from "models/Dialog";
import ModelBase from "models/ModelBase";
import TreeNode from 'models/TreeNode';
import * as React from 'react';
import FirebaseStore from "stores/FirebaseStore";
import TreeNodesStore from 'stores/TreeNodesStore';
import { WriteBatch } from "./FirebaseInitializedApp";
import firestoreBatch from "./FirestoreBatchUtil";
import i18n from "./i18n";
const colors = require('../Colors.scss');


export function deleteItem<T extends ModelBase>(item: T, store: FirebaseStore<T>) {
  store.deleteItem(item.id);
}

export function deleteItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  store.deleteItems(items.map(item => item.id));
  store.selectedItems.clear();
}

export function askToDeleteItem<T extends ModelBase>(item: T, store: FirebaseStore<T>, onConfirmDelete = () => deleteItem(item, store)) {
  const { dialogsStore } = store.stores;

  const newDialog = new Dialog(store.stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => {
        onConfirmDelete();
        dialogsStore.hideDialog(newDialog.id);
      }}
      title={i18n.t('Do you really want to delete this item?', { itemType: i18n.t(item.type).toLowerCase() })}
      content={item.name}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
};

export function askToDeleteItems<T extends ModelBase>(
  items: T[],
  store: FirebaseStore<T>,
  onConfirmDelete = () => deleteItems(items, store)
) {
  const { dialogsStore } = store.stores;

  const newDialog = new Dialog(store.stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => {
        onConfirmDelete();
        dialogsStore.hideDialog(newDialog.id);
      }}
      title={i18n.t('Do you really want to delete these items?')}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
};

export function archiveItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  items.forEach(item => item.isHidden = true);
  store.addEditItems(items, true, ['isHidden']);
  store.selectedItems.clear();
}

export function unarchiveItems<T extends ModelBase>(items: T[], store: FirebaseStore<T>) {
  items.forEach(item => item.isHidden = false);
  store.addEditItems(items, true, ['isHidden']);
  store.selectedItems.clear();
}

export const deleteDrawing = flow(function* (drawingRootNode: TreeNode, treeNodesStore: TreeNodesStore, canDeepClean = true, batchParam: WriteBatch = null) {
  const batch = batchParam || firestoreBatch(drawingRootNode.stores);
  const { stores } = drawingRootNode;
  const { drawToolsStore, snapStore, shapesStore } = stores;

  const nodesWithoutMeasurements = drawingRootNode.descendants
    // sometimes store isnt ready and we don't want to wait (copy to tasks lists), 
    // so not need to verify if measurement exists
    .filter(d => stores.measurementsStore.isReady
      ? isEmpty(d.measurementValuesArray)
      : isEmpty(d.measurementValues)
    );

  const shouldDeepClean = (
    isEmpty(nodesWithoutMeasurements) &&
    isEmpty(drawingRootNode.shapes) &&
    !drawingRootNode.backgroundImage &&
    !drawingRootNode.satelliteImageUrl &&
    canDeepClean
  )

  drawingRootNode.satelliteImageUrl = '';
  drawingRootNode.satelliteImageAddress = '';
  drawingRootNode.backgroundImage = null;
  drawingRootNode.ownShapes = [];

  treeNodesStore.addEditItem(drawingRootNode, true, ['shapeId', 'ownShapesIds'], batch);

  if (shouldDeepClean) {
    // already cleaned previously, so now delete everything
    treeNodesStore.deleteDescendants(drawingRootNode, true, batch);
  } else {
    drawingRootNode.descendants
      .filter(descendant => !isEmpty(descendant.ownShapes))
      .forEach(descendant => {
        // only delete when in same context (ie. tasks list shouldn't delete project shapes)
        if (first(descendant.ownShapes).stores === descendant.stores) {
          shapesStore.deleteItems(descendant.ownShapes.map(shape => shape.id), true, batch);
        };
        descendant.ownShapes = [];
        treeNodesStore.addEditItem(descendant, true, ['shapeId', 'ownShapesIds'], batch);
      });

    // delete only nodes without measurements
    nodesWithoutMeasurements.forEach(node => treeNodesStore.deleteNodeAndDescendants(node, true, batch));
  }

  drawToolsStore.reset();

  // MOBX DOESNT REFRESH when doing clear, but this usually works and is used in a lot of places :(((
  //snapStore._snapPointsMap.clear();
  snapStore._snapPointsMap = new Map();

  if (!batchParam) {
    yield batch.commit();
  }
})

// drawingRoot node, not the rootNode of treeNodesStore, can be any selected node's child drawing root
export function askToDeleteDrawing(drawingRootNode: TreeNode, treeNodesStore: TreeNodesStore) {
  const { stores } = treeNodesStore;
  const { dialogsStore } = stores;
  const newDialog = new Dialog(stores);
  newDialog.dialogComponent = ({ open }) => (
    <ConfirmDialog
      open={open}
      dialogId={newDialog.id}
      onConfirm={() => deleteDrawing(drawingRootNode, treeNodesStore)}
      title={i18n.t('Do you really want to delete this {{- itemType}}?', { itemType: i18n.t('Reference Drawing') })}
      actionButtonColor={colors.red}
      yesLabel={i18n.t('Delete')}
    />
  );
  dialogsStore.showDialog(newDialog);
}
