
import { Button } from '@material-ui/core';
import RectangleIcon from '@material-ui/icons/Crop169';
import ObserverComponent from 'components/common/ObserverComponent';
import { DRAWING_SCALE } from 'constants/Constants';
import { DrawToolType } from 'constants/DrawToolType';
import { NodeType } from 'constants/NodeType';
import { ShapeTags } from 'constants/ShapeTags';
import { Unit } from 'constants/Unit';
import Point from 'models/Point';
import Surface from 'models/Surface';
import TreeNode from 'models/TreeNode';
import Wall from 'models/Wall';
import * as React from 'react';
import { getDefaultMeasurements } from 'utils/DrawToolsUtils';
import firestoreBatch from 'utils/FirestoreBatchUtil';
import MeasurementConverter from 'utils/MeasurementConverter';
import i18n from 'utils/i18n';
import FeetInchesField from '../FeetInchesField/FeetInchesField';

const styles = require('./RectangularDrawingOptions.module.scss');

interface RectangularDrawingOptionsState {
  length: number,
  width: number,
}


export default class RectangularDrawingOptions extends ObserverComponent<{}, RectangularDrawingOptionsState> {
  state = {
    // inch or meter depending on settings store
    length: 0,
    width: 0
  };

  onChange = (propName: keyof RectangularDrawingOptionsState) => (value) => {
    this.setState({ [propName]: value });
  }

  createDrawing = () => {
    const { treeNodesStore, shapesStore, settingsStore, drawToolsStore } = this.context;
    const { length, width } = this.state;

    const { selectedTreeNode } = treeNodesStore;

    const lengthMeters = settingsStore.isImperial
      ? MeasurementConverter.convert(length, Unit.Foot, Unit.Meter)
      : length;

    const widthMeters = settingsStore.isImperial
      ? MeasurementConverter.convert(width, Unit.Foot, Unit.Meter)
      : length;

    const lengthPixel = lengthMeters * DRAWING_SCALE;
    const widthPixel = widthMeters * DRAWING_SCALE;

    let rectangleDrawingNode = (
      // in case a drawing exists suppose we want to add to first child
      selectedTreeNode.childDrawingNode?.children?.[0] ||
      selectedTreeNode.childDrawingNode ||
      new TreeNode(this.context, selectedTreeNode.name + ' - ' + i18n.t('Reference Drawing'))
    );

    //rectangleDrawingNode.shouldBubbleMeasurements = true; // doesn't seem to be needed....
    const lengthGroupNode = new TreeNode(this.context, i18n.t('Perimeter'));
    const lengthNode = new TreeNode(this.context, i18n.t('Line') + ' 1');
    const widthNode = new TreeNode(this.context, i18n.t('Line') + ' 2');
    const lengthNode2 = new TreeNode(this.context, i18n.t('Line') + ' 3');
    const widthNode2 = new TreeNode(this.context, i18n.t('Line') + ' 4');
    const surfaceNode = new TreeNode(this.context, i18n.t('Surface'));

    lengthGroupNode.children = [lengthNode, widthNode, lengthNode2, widthNode2];
    rectangleDrawingNode.childrenIds.push(...(width ? [surfaceNode.id, lengthGroupNode.id] : [lengthNode.id]));
    rectangleDrawingNode.isDrawingNode = true;
    if (!selectedTreeNode.descendants.map(i => i.id).includes(rectangleDrawingNode.id)) {
      selectedTreeNode.childrenIds.push(rectangleDrawingNode.id);
    }

    const lengthShape = new Wall(this.context, new Point(0, 0), new Point(lengthPixel, 0));
    lengthShape.tags.push(ShapeTags.RectangleTop);
    const widthShape = new Wall(this.context, new Point(lengthPixel, 0), new Point(lengthPixel, widthPixel));
    widthShape.tags.push(ShapeTags.RectangleSide);
    const lengthShape2 = new Wall(this.context, new Point(lengthPixel, widthPixel), new Point(0, widthPixel));
    lengthShape2.tags.push(ShapeTags.RectangleBottom);
    const widthShape2 = new Wall(this.context, new Point(0, widthPixel), new Point(0, 0));
    widthShape2.tags.push(ShapeTags.RectangleSide);

    const surfaceShape = new Surface(this.context, [
      new Point(0, 0),
      new Point(lengthPixel, 0),
      new Point(lengthPixel, widthPixel),
      new Point(0, widthPixel),
    ]);

    lengthNode.shape = lengthShape;
    lengthNode2.shape = lengthShape2;
    widthNode.shape = widthShape;
    widthNode2.shape = widthShape2;
    surfaceNode.shape = surfaceShape;

    const batch = firestoreBatch(this.context);

    // support both rectangle and line
    const lineNodes = width ? [lengthNode, lengthNode2, widthNode, widthNode2] : [lengthNode];
    lineNodes.forEach(lineNode => {
      const lineMeasurements = getDefaultMeasurements(NodeType.Line, DrawToolType.GeneralDraw, this.context);
      treeNodesStore.toggleNodeMeasurements(
        lineMeasurements,
        true,
        lineNode,
        false,
        batch
      );
    });

    // measurements
    if (width) {
      treeNodesStore.toggleNodeMeasurements(
        getDefaultMeasurements(NodeType.Surface, DrawToolType.GeneralDraw, this.context),
        true,
        surfaceNode,
        false,
        batch
      );
    }

    shapesStore.batchAddEditItems(width
      ? [lengthShape, lengthShape2, widthShape, widthShape2, surfaceShape]
      : [lengthShape],
      batch
    );

    treeNodesStore.batchAddEditItems(width
      ? [rectangleDrawingNode, lengthGroupNode, lengthNode, lengthNode2, widthNode, widthNode2, surfaceNode, selectedTreeNode]
      : [rectangleDrawingNode, lengthNode, selectedTreeNode],
      batch
    );
    batch.commit();

    drawToolsStore.shouldShowDrawingButtonMeasurements = false;
  }

  _render() {
    const { drawToolsStore, settingsStore } = this.context;
    const { length, width } = this.state;

    return settingsStore.isImperial
      ? (
        <div className={styles.root}>
          <div className={styles.title}>
            <RectangleIcon />
            {i18n.t('Create Drawing from Simple Dimensions')}
          </div>
          <div className={styles.dimensions}>
            <div className={styles.length}>
              <div>
                {i18n.t('Length')}
              </div>
              <FeetInchesField value={length} shouldFocusOnMount onChange={this.onChange('length')} />
            </div>
            <div>
              <div>
                {i18n.t('Width') + ' (' + i18n.t('optional') + ')'}
              </div>
              <FeetInchesField value={width} onChange={this.onChange('width')} onConfirm={this.createDrawing} />
            </div>
            <div className={styles.by}>x</div>

          </div>
          <div className={styles.buttons}>
            <Button
              onClick={() => drawToolsStore.shouldShowDrawingButtonMeasurements = false}
              tabIndex={-1}
              color="primary"
            >
              {i18n.t('Cancel')}
            </Button>
            <Button
              disabled={!width && !length}
              onClick={this.createDrawing}
              color="primary"
              variant="contained">
              {i18n.t('Create')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.root}>
          todo metric
        </div>
      );
  }
}