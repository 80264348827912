import Clipper from '@doodle3d/clipper-js';
import { DRAWING_SCALE } from 'constants/Constants';
import { isProduction } from 'environment';
import { compact, isEmpty, round, sumBy } from 'lodash';
import { computed, observable } from "mobx";
import { computedFn } from "mobx-utils";
import Shape from "models/Shape";
import { list, object, serializable } from "serializr";
import { isClockwise } from 'utils/GeometryUtils';
import i18n from 'utils/i18n';
import { OFFSET_SURFACE_FUNCTION, REPEAT_LINE_FUNCTION, getFunctionParameter, getFunctionParameters } from "utils/MeasurementFormatter";
import { getPathString, getSurfaceSquarePixels, getSurfaceSquarePixelsWithSlope } from 'utils/ShapeUtil';
import { getSafe } from 'utils/Utils';
import Line from './Line';
import Point from "./Point";
import SimpleSurface from './SimpleSurface';

export default class Surface extends Shape {
  @serializable type = 'Surface';

  index = 1;

  @observable @serializable(list(object(SimpleSurface))) holes: SimpleSurface[] = [];

  @computed get surfaceSquarePixels(): number {
    return getSurfaceSquarePixels(this.points) - sumBy(this.holes, hole => getSurfaceSquarePixels(hole.points));
  }

  @computed
  get pathString(): string {
    return (
      getPathString(this.points) +
      this.holes
        .map(hole => isClockwise(hole.points) ? hole.pathString : hole.pathStringReversed)
        .join(' ')
    );
  }

  // sq. meters
  @computed get surface(): number {
    return this.surfaceSquarePixels / Math.pow(DRAWING_SCALE, 2)
  }

  @computed get perimeter(): number {
    return sumBy(
      this.points,
      point => {
        const index = this.points.indexOf(point);
        return point.distance(this.points[(index + 1) % this.points.length], DRAWING_SCALE)
      });
  }

  @computed get roofSurface(): number {
    if (!this.treeNode) {
      return 0;
    }

    const roofParams = [i18n.t('Slope : Value'), i18n.t('Drawing : Projection')];

    const roofMeasurementValues = this.treeNode.ancestorOrNodeOwnMeasurementValuesArray
      .filter(measurementValue => Object.values(roofParams).includes(measurementValue.measurement?.name) && !isNaN(measurementValue.metricValue));

    if (roofMeasurementValues.length !== 2) {
      return this.surface;
    }

    return this.getSurfaceWithSlope(
      roofMeasurementValues.find(mv => mv.measurement?.name === i18n.t('Slope : Value')).metricValue,
      roofMeasurementValues.find(mv => mv.measurement?.name === i18n.t('Drawing : Projection')).metricValue
    );
  }

  @computed get roofSlopeDirection(): number {
    return getSafe(() => this.treeNode.ancestorOrNodeOwnMeasurementValuesArray
      .find(mv => mv.measurement?.name === i18n.t('Slope : Direction'))
      .metricValue
    ) || -1;
  }

  getSurfaceWithSlope = computedFn((slopeAngle: number, drawingProjection: number) => {
    return (
      getSurfaceSquarePixelsWithSlope(this.points, slopeAngle, drawingProjection) -
      sumBy(this.holes, hole => getSurfaceSquarePixelsWithSlope(hole.points, slopeAngle, drawingProjection))
    ) / Math.pow(DRAWING_SCALE, 2);
  })

  @computed get bboxWidthPixels() {
    return Math.abs(Math.max(...this.points.map(pt => pt.x)) - Math.min(...this.points.map(pt => pt.x)));
  }

  @computed get offsetSurfaces(): Surface[] {
    if (!this.treeNode || this.treeNode.hasNonDrawingRootBubbleChildren) {
      return [];
    }

    const { measurementsStore } = this.stores;

    // DUPLICATE Line.ts
    // should simplify all the map to single function
    return getSafe(() => compact(this.treeNode.ancestorOrNodeOwnMeasurementValuesArray
      .filter(value => value.formula.includes(OFFSET_SURFACE_FUNCTION))
      .map(value => {
        const measurement = measurementsStore.getItemByNameAndPreferredCategory(
          getFunctionParameter(value.formula, OFFSET_SURFACE_FUNCTION)?.replace?.('-', ''),
          value.measurement.category,
          value.measurement.subcategory
        )

        if (!measurement) {
          return null;
        }

        const measurementValue = this.treeNode.bubbleRoot.measurementValues.get(measurement.id);
        const metricValue = measurementValue.metricValue;

        const offsetSurface = this.getOffsetSurface(
          // awful, needs to use real parser soon
          getFunctionParameter(
            value.formula,
            OFFSET_SURFACE_FUNCTION)?.startsWith?.('-')
            ? -metricValue
            : metricValue
        );

        return offsetSurface;
      }))) || [];
  }

  @computed get offsetPaths(): string[] {
    return this.offsetSurfaces.map(surface => surface.pathString) || ['']
  }

  @computed get repeatedLinePaths(): string[][] {
    if (!this.treeNode || this.treeNode.hasBubbleChildren) {
      return [];
    }

    const { measurementsStore } = this.stores;

    // DUPLICATE
    return compact(
      this.treeNode.ancestorOrNodeOwnMeasurementValuesArray
        .filter(measurementValue => (
          measurementValue.measurement &&
          measurementValue.formula &&
          measurementValue.formula.includes(REPEAT_LINE_FUNCTION) && measurementValue.measurement
        )).sort((a, b) => a.measurement.index - b.measurement.index)
        .map(measurementValue => {
          let retval;

          try {
            // should try to get this from hot formula parser
            const paramNames = getFunctionParameters(measurementValue.formula, REPEAT_LINE_FUNCTION);
            const spacingParamName = paramNames[0];
            const spacingParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(spacingParamName, measurementValue.measurement.category, measurementValue.measurement.subcategory);
            const spacingParamMeasurementValue = measurementValue.treeNode.measurementValues.get(spacingParamMeasurement.id);

            const spacingMetricValue = spacingParamMeasurementValue.metricValue;

            let angleMetricValue = 0;
            if (paramNames.length > 1) {
              const angleParamName = paramNames[1];
              const angleParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(angleParamName, measurementValue.measurement.category, measurementValue.measurement.subcategory);
              const angleParamMeasurementValue = measurementValue.treeNode.measurementValues.get(angleParamMeasurement.id);
              angleMetricValue = angleParamMeasurementValue.metricValue;
            }

            let shouldConsiderOpenings = true;
            if (paramNames.length > 2) {
              // expects 0 or 1
              shouldConsiderOpenings = !!parseInt(paramNames[2]);
            }

            let shouldAddExtraStartEndLines = false;
            if (paramNames.length > 3) {
              // expects 0 or 1, but should support variable names
              shouldAddExtraStartEndLines = !!parseInt(paramNames[3]);
            }

            let slopeRatio = 0;
            if (paramNames.length > 4) {
              const slopeRatioParamName = paramNames[4];
              const slopeRatioMeasurement = measurementsStore.getItemByNameAndPreferredCategory(slopeRatioParamName, measurementValue.measurement.category, measurementValue.measurement.subcategory);
              const slopeRatioParamMeasurementValue = measurementValue.treeNode.measurementValues.get(slopeRatioMeasurement.id);
              slopeRatio = slopeRatioParamMeasurementValue.metricValue;
            }

            let slopeDirection = 0;
            if (paramNames.length > 5) {
              const slopeDirectionParamName = paramNames[5];
              const slopeDirectionParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(slopeDirectionParamName, measurementValue.measurement.category, measurementValue.measurement.subcategory);
              const slopeDirectionParamMeasurementValue = measurementValue.treeNode.measurementValues.get(slopeDirectionParamMeasurement.id);
              slopeDirection = slopeDirectionParamMeasurementValue.metricValue;
            }

            let drawingProjection = 0;
            if (paramNames.length > 6) {
              const drawingProjectionParamName = paramNames[6];
              const drawingProjectionParamMeasurement = measurementsStore.getItemByNameAndPreferredCategory(drawingProjectionParamName, measurementValue.measurement.category, measurementValue.measurement.subcategory);
              const drawingProjectionParamMeasurementValue = measurementValue.treeNode.measurementValues.get(drawingProjectionParamMeasurement.id);
              drawingProjection = drawingProjectionParamMeasurementValue.metricValue;
            }

            retval = this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, !!shouldConsiderOpenings, !!shouldAddExtraStartEndLines, slopeRatio, slopeDirection, drawingProjection)
              .map(line => line.pathString);

          } catch (e) {
            if (!isProduction()) {
              console.log('Cant draw repeated lines for ' + this.treeNode.path + ' ' + measurementValue?.measurement?.name);
            }
          }

          return retval;
        })
    );
  }

  getSurfaceRepeatedLinesLength = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean, shouldAddExtraStartEndLines: boolean, slopeRatio: number, slopeDirection: number, drawingProjection: number): number => {
    // Angle metric value can only be 0 or not 0, other angles not supported
    // unfortunately, 0 means Vertical for repeated lines, but means horizontal (pointing right) for slope directions
    const areRepeatedLinesVertical = angleMetricValue === 0;
    const isSlopeDirectionVertical = Math.abs(slopeDirection - Math.PI / 2) < 0.001 || Math.abs(slopeDirection - 3 * Math.PI / 2) < 0.001;
    const isSlopeDirectionHorizontal = Math.abs(slopeDirection - Math.PI) < 0.001 || Math.abs(slopeDirection) < 0.001;
    if (slopeRatio && !isSlopeDirectionHorizontal && !isSlopeDirectionVertical) {
      // other slope directions not supported yet
      return 0;
    }

    const slopeAngle = Math.atan(slopeRatio);

    let retval = sumBy(this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, shouldConsiderOpenings, shouldAddExtraStartEndLines, slopeRatio, slopeDirection, drawingProjection), 'length') || 0;

    if (
      slopeRatio && (
        areRepeatedLinesVertical && isSlopeDirectionVertical ||
        !areRepeatedLinesVertical && isSlopeDirectionHorizontal
      )) {
      // adjust resulting length slope applied parallel to repeated lines
      retval = drawingProjection === 0
        ? retval / Math.cos(slopeAngle) // plan
        : retval / Math.sin(slopeAngle) // elevation
    }

    return retval;
  });

  getSurfaceRepeatedLinesQuantity = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean, shouldAddExtraStartEndLines: boolean, slopeRatio: number, slopeDirection: number, drawingProjection: number): number => {
    return this.getRepeatedLinesOnSurface(spacingMetricValue, angleMetricValue, shouldConsiderOpenings, shouldAddExtraStartEndLines, slopeRatio, slopeDirection, drawingProjection).length || 0;
  });

  getRepeatedLinesOnSurface = computedFn((spacingMetricValue: number, angleMetricValue: number, shouldConsiderOpenings: boolean, shouldAddExtraStartEndLines: boolean, slopeRatio: number, slopeDirection: number, drawingProjection: number): Line[] => {
    if (this.points.length < 3 || !spacingMetricValue) {
      return [];
    }

    const { selectedProject } = this.stores.projectsStore;
    const slopeAngle = Math.atan(slopeRatio);

    // Angle metric value can only be 0 or not 0, other angles not supported
    // Initially, 0 meant Vertical for repeated lines, but normally 0 degrees points right, like slope direction 0 points right, so changing for new projects
    const areRepeatedLinesVertical = selectedProject.versionOnCreation >= 8
      ? angleMetricValue !== 0
      : angleMetricValue === 0;
    const isSlopeDirectionVertical = Math.abs(slopeDirection - Math.PI / 2) < 0.001 || Math.abs(slopeDirection - 3 * Math.PI / 2) < 0.001;
    const isSlopeDirectionHorizontal = Math.abs(slopeDirection - Math.PI) < 0.001 || Math.abs(slopeDirection) < 0.001;
    if (slopeRatio && !isSlopeDirectionHorizontal && !isSlopeDirectionVertical) {
      return [];
    }

    if (
      slopeRatio && (
        areRepeatedLinesVertical && isSlopeDirectionHorizontal ||
        !areRepeatedLinesVertical && isSlopeDirectionVertical
      )) {
      // adjust spacing if for part of slope applied orthogonally to repeated lines
      spacingMetricValue = drawingProjection === 0
        ? spacingMetricValue * Math.cos(slopeAngle) //plan
        : spacingMetricValue * Math.sin(slopeAngle) //elevation
    }

    const spacingDistancePixels = spacingMetricValue * DRAWING_SCALE;
    const surfaceClipper = new Clipper([
      this.points.map(point => ({ X: point.x, Y: point.y })),
      ...(shouldConsiderOpenings ? this.holes : []).map(hole => (isClockwise(hole.points) ? hole.points : hole.points.slice(0).reverse()).map(point => ({ X: point.x, Y: point.y })))
    ]);

    const boundingBox = surfaceClipper.shapeBounds();

    const gridLines = [];

    // 0.001 is to go slightly outside of the bounding box, to avoid having a repeating line fall exactly on a vertex
    // spacingMetricValue * 0.05 is to avoid last line to fall too close to the last beam

    // VERTICAL LINES (right now suppose 0 degree)
    if (areRepeatedLinesVertical) {
      for (let i = boundingBox.left - 0.001; i < boundingBox.right - spacingMetricValue * 0.05; i += spacingDistancePixels) {
        gridLines.push([
          { X: i, Y: boundingBox.top - 0.001 },
          { X: i, Y: boundingBox.bottom + 0.001 },
        ]);
      }

      // HORIZONTAL LINES (suppose 90 degrees)
    } else {
      for (let i = boundingBox.top - 0.001; i < boundingBox.bottom - spacingMetricValue * 0.05; i += spacingDistancePixels) {
        gridLines.push([
          { X: boundingBox.left - 0.001, Y: i },
          { X: boundingBox.right + 0.001, Y: i },
        ]);
      }
    }

    const gridClipper = new Clipper(gridLines, false)

    const intersections = surfaceClipper.intersect(gridClipper);


    // use a furring 2x3 width as reference
    const lineWidthPixels = 0.0635 * DRAWING_SCALE;
    let extraStartEndLines = []
    if (shouldAddExtraStartEndLines) {
      extraStartEndLines = compact(
        this.points
          .map((point, index, points) => {
            const startPt = point.clone();
            const endPt = points[(index + 1) % points.length].clone();
            // cant use line.angleDegrees because will observe the value and mobx will throw an error when we modify the point
            const angleDegrees = -Math.atan2(endPt.y - startPt.y, endPt.x - startPt.x) * 180 / Math.PI;

            if (areRepeatedLinesVertical) {
              if (round(angleDegrees, 0) === -90) {
                startPt.x -= lineWidthPixels / 2;
                endPt.x -= lineWidthPixels / 2;
                return new Line(this.stores, startPt, endPt);
              }
              if (round(angleDegrees, 0) === 90) {
                startPt.x += lineWidthPixels / 2;
                endPt.x += lineWidthPixels / 2;
                return new Line(this.stores, startPt, endPt);
              }
            }

            if (!areRepeatedLinesVertical) {
              if (Math.abs(round(angleDegrees, 0)) === 180) {
                startPt.y -= lineWidthPixels / 2;
                endPt.y -= lineWidthPixels / 2;
                return new Line(this.stores, startPt, endPt);
              }
              if (Math.abs(round(angleDegrees, 0)) === 0) {
                startPt.y += lineWidthPixels / 2;
                endPt.y += lineWidthPixels / 2;
                return new Line(this.stores, startPt, endPt);
              }
            }

            return null;
          }));
    }

    return [
      ...extraStartEndLines,
      ...intersections.paths.map(path => new Line(
        this.stores,
        new Point(path[0].X, path[0].Y), new Point(path[1].X, path[1].Y))
      )
    ];
  });


  getAreaWithOffset = computedFn((offsetMetricValue: number): number => {
    return this.getOffsetSurface(offsetMetricValue).surface;
  });

  // returns surface object
  getOffsetSurface = computedFn((offsetMetricValue: number): Surface => {
    if (isEmpty(this.points)) {
      return new Surface(this.stores);
    }
    const offsetDistancePixels = offsetMetricValue * DRAWING_SCALE;

    const clipper = new Clipper([this.points.map(point => ({ X: point.x, Y: point.y }))]);
    const polyline = clipper.offset(offsetDistancePixels, { jointType: 'jtMiter' });

    const retval = new Surface(this.stores, polyline.paths[0].map(pointArray => new Point(pointArray.X, pointArray.Y)));

    retval.holes = this.holes.slice(0);

    return retval;
  });
}